import { DashboardCardResponse, MowerStatus, StatusCountResponse } from '@uptime-bff/api-contract';
import { TagTypes, uptimeApi } from 'core/api';

const dashboardApi = uptimeApi.injectEndpoints({
  endpoints: (build) => ({
    getAssetsCount: build.query<StatusCountResponse<MowerStatus>, void>({
      query: () => ({
        url: 'dashboard/status-count',
        method: 'GET',
      }),
    }),
    getCeoraCount: build.query<StatusCountResponse<MowerStatus>, void>({
      query: () => ({
        url: 'dashboard/ceora-status-count',
        method: 'GET',
      }),
    }),
    getCustomerWidgets: build.query<DashboardCardResponse, void>({
      query: () => ({
        url: 'dashboard/widgets/cards',
        method: 'GET',
      }),
      providesTags: [TagTypes.CustomerWidgets],
      keepUnusedDataFor: 0,
    }),
    deleteCustomerWidget: build.mutation<void, { companyId: string }>({
      query: ({ companyId }) => ({
        url: `users/dashboard/company-widget/${companyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, isError) => (!isError ? [TagTypes.CustomerWidgets] : []),
    }),
  }),
});

export const { useGetAssetsCountQuery, useGetCeoraCountQuery, useGetCustomerWidgetsQuery, useDeleteCustomerWidgetMutation } = dashboardApi;
