import React, { FC, ReactNode, useCallback } from 'react';
import * as Sentry from '@sentry/react';
import ErrorCard from '../ErrorCard';

type FallbackProps = {
  resetError: () => void;
};

const FallbackError: FC<FallbackProps> = ({ resetError }) => {
  // Reset
  const onReset = useCallback(() => resetError(), [resetError]);

  return <ErrorCard onRetryClick={onReset} />;
};

const fallBack = (props: FallbackProps) => <FallbackError resetError={props.resetError} />;

type Props = {
  children: ReactNode;
};

const ErrorBoundary: FC<Props> = ({ children }) => (
  <Sentry.ErrorBoundary fallback={fallBack} showDialog>
    {children}
  </Sentry.ErrorBoundary>
);

export default ErrorBoundary;
