import { paddings } from 'core/styles';
import styled from 'styled-components';

export const Heading = styled.h1`
  font-style: italic;
  font-size: 2rem;
`;
export const SubHeading = styled.h2`
  font-style: italic;
  font-size: 3rem;
  text-align: center;
  line-height: 3.5rem;
  padding: 0 ${() => paddings.defaultPadding};
`;

export const CenteredParagraph = styled.p`
  text-align: center;
  padding: 0 ${() => paddings.defaultPadding};
`;
