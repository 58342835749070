import { SearchBar } from 'hudskit-framework-react';
import Checkbox from 'core/components/Checkbox';
import { useDispatch } from 'react-redux';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { useSearchableCompanies } from 'feature/companies/hook/use-searchable-companies';
import { clearFuzzySearchOnActiveSearch, setFuzzySearchField, setFuzzySearchQuery } from 'feature/companies/store';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { CompaniesSearchProps } from '@uptime-bff/api-contract';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import CompanyFiltersButton from '../CompanyFiltersButton';
import CompanySortMenu from '../CompanySortMenu/CompanySortMenu';
import LatestCompanyListUpdate from '../LatestCompanyListUpdate';
import * as S from './styled';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const CompaniesSearch = ({ isOpen, setIsOpen }:Props) => {
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Companies, InternationalizationNameSpace.Assets]);
  const { dataUpdatedOn, isFetching } = useSearchableCompanies({
    take: 40
  });

  const dispatch = useDispatch();
  const searchQuery = useAppSelector((state) => state.companies.companySearch?.query);
  const searchFields = useAppSelector((state) => state.companies.companySearch?.fields);

  const onSearchClearHandler = useCallback(() => {
    dispatch(clearFuzzySearchOnActiveSearch());
  }, [dispatch]);

  const onSearchHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setFuzzySearchQuery(event.target.value.trim()));
  }, [dispatch]);

  const onSearchFieldSelectedHandler = useCallback(
    (field: CompaniesSearchProps) => (selected: boolean) => {
      dispatch(setFuzzySearchField({ field, selected }));
    },
    [dispatch]
  );

  const companyNameChecked = useMemo(() => searchFields?.includes(CompaniesSearchProps.Name), [searchFields]);
  const contactInfoChecked = useMemo(() => searchFields?.includes(CompaniesSearchProps.Contact), [searchFields]);
  const customerNumberChecked = useMemo(() => searchFields?.includes(CompaniesSearchProps.CustomerNumber), [searchFields]);
  return (
    <S.SearchDiv>
      <SearchBar
        data-testid="searchBar"
        onChange={onSearchHandler}
        onClear={onSearchClearHandler}
        defaultValue={searchQuery}
        placeholder={t('COMPANIES:companiesSearch.placeholder')}
      />
      <S.SearchListOptionsDiv>
        <S.SearchOptionSpan>{t('ASSETS:labels.searchBy')}</S.SearchOptionSpan>
        <S.SearchOptionsDiv>
          <Checkbox
            label={t('COMPANIES:companiesSearch.companyName')}
            onChange={onSearchFieldSelectedHandler(CompaniesSearchProps.Name)}
            checked={companyNameChecked}
            id="searchByCompanyName"
          />
          <Checkbox
            label={t('COMPANIES:companiesSearch.contact')}
            onChange={onSearchFieldSelectedHandler(CompaniesSearchProps.Contact)}
            checked={contactInfoChecked}
            id="searchByContactInfo"
          />
          <Checkbox
            label={t('COMPANIES:companiesSearch.customerNumber')}
            onChange={onSearchFieldSelectedHandler(CompaniesSearchProps.CustomerNumber)}
            checked={customerNumberChecked}
            id="searchByCustNo"
          />
        </S.SearchOptionsDiv>
      </S.SearchListOptionsDiv>
      <S.SortMenuDiv>
        {!isFetching && (
          <>
            <S.CompanyContainerDiv>
              <CompanyFiltersButton isOpen={isOpen} setIsOpen={setIsOpen} />
              <S.CompanySortingDiv>
                <CompanySortMenu />
              </S.CompanySortingDiv>
            </S.CompanyContainerDiv>
            {dataUpdatedOn && <LatestCompanyListUpdate dataUpdatedOn={dataUpdatedOn} />}
          </>
        )}
      </S.SortMenuDiv>
    </S.SearchDiv>
  );
};

export default CompaniesSearch;
