import { borderRadius, device, headerHeight, paddings, typographyDesktop, typographyMobile } from 'core/styles/variables';
import { zFloatingPanels } from 'core/styles/z-index';
import styled from 'styled-components';

type Props = {
  $isFetching?: boolean;
  $delete?: { [key: string]: boolean } | boolean;
};

export const LeftContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  > div > button {
    padding: 8px;
  }
  @media ${device.md}{
    flex-direction: row;
  }
`;

export const RightContentDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: fit-content;
  .toolTip {
    border-radius: ${borderRadius.default};
    padding: ${paddings.defaultPadding};
    gap: 10px;
  }
`;

export const HeaderTitle = styled.h4`
  margin: 0;
  padding-bottom: ${paddings.smallPadding};
  @media ${device.md} {
    margin: ${paddings.smallPadding};
  }
`;
export const HeaderRowDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const AddPillDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EditPillDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RefreshIconDiv = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  animation: ${({ $isFetching }) => ($isFetching ? 'loading' : 'none')} 1s linear infinite;

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
  &:hover {
    color: ${({ theme }) => theme.colors.onSurface};
  };
`;

export const LastUpdatedDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding-left: ${paddings.smallPadding};
  color: ${({ theme }) => theme.colors.gray400};
  font-size: ${typographyMobile.size.tiny};

  @media ${device.md} {
    font-size: ${typographyDesktop.size.tiny};
    padding-bottom: 2px;
  };
`;

export const CardsContainerDiv = styled.div`
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: ${paddings.defaultPadding};
    margin-top: 8px;
  }

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    cursor: grab;
    font-size: 20px;
    user-select: none;
  }

  @media (min-width: 600px) {
    .list {
      grid-gap: 24px;
    }
  }
  > div {
    margin-bottom: 0.5vw;
  }
  @media ${() => device.xs} {
    .grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media ${() => device.sm} {
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media ${() => device.lg} {
    .grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media ${() => device.xxl} {
    .grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
`;

export const StatusWidgetContainerDiv = styled.div`
  grid-column: 1 / 13;
  grid-row: 2;
  @media ${() => device.lg} {
    max-width: 50%;
  }
  @media ${() => device.xl} {
    max-width: 32%;
  }
  @media ${() => device.xxl} {
    max-width: 25%;
  }
`;

export const ErrorWrapperDiv = styled.div`
  position: sticky;
  z-index: ${zFloatingPanels};
  top: ${headerHeight};
`;

export const NoWidgetTitleDiv = styled.h5`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

export const NoWidgetSubTitleDiv = styled.span`
  display: flex;
  justify-content: center;
  font-weight: 400;
  line-height: normal;
  padding: 8px;
`;

export const CardTransitionDiv = styled.div<Props>`
  transition: ${({ $delete }) => ($delete ? 'none' : 'all 0.5s ease-in-out')};
  opacity: ${({ $delete }) => ($delete ? 1 : 0)};
  transform: ${({ $delete }) => ($delete ? 'scale(1)' : 'scale(0)')};
`;

export const CustomerWidgetCardWrapperDiv = styled.div`
  > div {
   margin-bottom: ${paddings.largePadding};
   }
`;
