/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import { ChangeEvent, ReactNode, useCallback, useMemo } from 'react';
import { CheckBoxContainer, CheckBoxInput, CheckedRect, Container, Label, TickPath, UncheckedRect } from './styled';
// Copy of hudskit component to extend capabilities, to be removed once value can be changed on the hudskit library
type Props = {
  /**
   * Default checked status
   */
  defaultChecked?: boolean;
  /**
   * Default checked status
   */
  checked?: boolean;
  /**
   * Function to be called on change
   */
  onChange?: (checked: boolean) => void;
  /**
   * ID to connect label with checkbox, must be provided if a label is set
   */
  id?: string;
  /**
   * Label for the checkbox
   */
  label?: ReactNode;
  /**
   * Disabled status
   */
  disabled?: boolean;
  /**
   * HTML input 'aria-label' attribute. If the checkbox does not have a label, this must be provided for accessibility
   */
  ariaLabel?: string;
  /**
   * useForm register function
   */
  register?: any;
};

const Checkbox = ({
  onChange,
  id,
  label,
  disabled = false,
  ariaLabel,
  checked,
  register
}:Props) => {
  const onCheckChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const checkedValue = event.target.checked;
      onChange?.(checkedValue);
    },
    [onChange]
  );

  // Render check mark
  const checkMark = useMemo(() => {
    if (checked) {
      return (
        <>
          <CheckedRect width="20" height="20" rx="7" disabled={disabled} />
          <TickPath d="M5 11L9 15L15 6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </>
      );
    }
    return <UncheckedRect x="1" y="1" width="18" height="18" rx="6" strokeWidth="2" disabled={disabled} />;
  }, [checked, disabled]);

  return (
    <Container>
      <CheckBoxContainer>
        <CheckBoxInput
          {...register}
          type="checkbox"
          checked={checked}
          onChange={onCheckChange}
          disabled={disabled}
          id={id}
          ariaLabel={ariaLabel}
        />
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" fillRule="evenodd">
          {checkMark}
        </svg>
      </CheckBoxContainer>
      {label && (
        <Label htmlFor={id} disabled={disabled}>
          {label}
        </Label>
      )}
    </Container>
  );
};

export default Checkbox;
