import { Link } from 'react-router-dom';
import { RoutePaths } from 'core/models';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import FullScreenPage from 'core/components/FullScreenPage';
import { IconLarge } from 'hudskit-framework-react';
import * as S from './styled';

const NotFound = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Core);
  return (
    <FullScreenPage>
      <IconLarge type="productCategory.robotics" size="extraLarge" />
      <S.Heading>
        {t('CORE:notFound.title')}
      </S.Heading>
      <S.SubHeading>{t('CORE:notFound.subtitle')}</S.SubHeading>
      <S.CenteredParagraph>
        <span>
          {t('CORE:notFound.message')}
        </span>
        {' '}
        <Link to={RoutePaths.Dashboard}>{t('CORE:notFound.returnLink')}</Link>
      </S.CenteredParagraph>
    </FullScreenPage>

  );
};
export default NotFound;
