import Modal from 'core/components/Modal/Modal';
import { Button, Icon } from 'hudskit-framework-react';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useEffect } from 'react';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { setIsOpenMessageModal } from 'feature/dashboard/store';
import { noAccessModalTimer } from 'core/config';
import * as S from './styled';

const NoAccessModal = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Dashboard);
  const isOpen = useAppSelector((state) => state.dashboard.isOpen);
  const dispatch = useAppDispatch();

  const handleModalClose = () => {
    dispatch(setIsOpenMessageModal(!isOpen));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(setIsOpenMessageModal(true));
    }, noAccessModalTimer);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, isOpen]);

  const accessTitle = (
    <S.AccessTitleDiv>
      <Icon type="notification.locked" size="medium" />
      <span>{t('DASHBOARD:dashboard.noAccess.title')}</span>
    </S.AccessTitleDiv>
  );

  return (
    <Modal title={accessTitle} isOpen={isOpen} close={handleModalClose}>
      <div>
        <S.NoAccessParagraph>{t('DASHBOARD:dashboard.noAccess.unableToDisplay')}</S.NoAccessParagraph>
        <br />
        <S.NoAccessParagraph>{t('DASHBOARD:dashboard.noAccess.contactYourDealer')}</S.NoAccessParagraph>
      </div>
      <S.NoAccessButtonDiv>
        <Button type="button" size="medium" variant="primary" onClick={handleModalClose}>
          {t('DASHBOARD:dashboard.noAccess.button')}
        </Button>
      </S.NoAccessButtonDiv>
    </Modal>
  );
};

export default NoAccessModal;
