import { borderRadius, paddings } from 'core/styles';
import { zFloatingPanels } from 'core/styles/z-index';
import styled from 'styled-components';

export const ActiveAssetInfoDiv = styled.div`
  position: fixed;
  z-index: ${() => zFloatingPanels};
  right: calc(50px + ${() => paddings.defaultPadding});
  bottom: ${() => paddings.defaultPadding};
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: ${() => borderRadius.default};
  padding: ${() => paddings.defaultPadding};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const TitleSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
export const TitleDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
`;

export const ContentDiv = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const SubContentDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
`;

export const SubContentButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
