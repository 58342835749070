import { initRemoteConfig } from 'core/config/firebase/remote-config';
import { useEffect, useState } from 'react';

const useRemoteConfig = () => {
  const [isReady, setIsReady] = useState<boolean>(false);
  useEffect(() => {
    const init = async () => {
      try {
        await initRemoteConfig();
      } catch {
        /* we continue with default values */
      } finally {
        setIsReady(true);
      }
    };
    void init();
  }, []);
  return isReady;
};
export { useRemoteConfig };
