import FullScreenPage from 'core/components/FullScreenPage';
import { Heading } from 'hudskit-framework-react';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import * as S from './styled';

const MaintenanceScreen = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Maintenance);

  return (
    <FullScreenPage>
      <Heading type="h1">{t('MAINTENANCE:maintenanceScreen.title')}</Heading>
      <S.CenteredParagraph>{t('MAINTENANCE:maintenanceScreen.message')}</S.CenteredParagraph>
      <S.CenteredParagraph>{t('MAINTENANCE:maintenanceScreen.comingBack')}</S.CenteredParagraph>
    </FullScreenPage>
  );
};

export default MaintenanceScreen;
