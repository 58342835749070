import { css } from 'styled-components';

const fullScreen = css`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

const centeredFullScreen = css`
  ${fullScreen}
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const centeredFlex = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export { centeredFullScreen, centeredFlex, fullScreen };
