import { configApp } from 'core/config';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import Logo from 'core/components/Logo/Logo';
import Modal from 'core/components/Modal/Modal';
import { Icon } from 'hudskit-framework-react';
import * as S from './styled';

interface Props {
  isOpen: boolean,
  close: () => void,
}

const FeedbackModal = ({ isOpen, close }: Props) => {
  const logo = <Logo size={140} />;
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Feedback);

  return (
    <Modal title={logo} isOpen={isOpen} close={close}>
      <S.Container>
        <S.SendFeedbackDiv>
          <S.FeedbackLabel>{t('FEEDBACK:feedbackLabel.label')}</S.FeedbackLabel>
          <S.SendEmailAnchor href={`mailto:${configApp.feedbackEmail}?subject=Husqvarna%20Uptime%20Center%20feedback`}>
            <Icon type="action.email_outlined" />
            <span>{t('FEEDBACK:feedbackLink.emailLink')}</span>
          </S.SendEmailAnchor>
        </S.SendFeedbackDiv>
        <S.Message>
          <span>{t('FEEDBACK:feedbackMessage.thankyouMessage')}</span>
          <span>{t('FEEDBACK:feedbackMessage.appreciateMessage')}</span>
          <span>{t('FEEDBACK:feedbackMessage.contectMessage')}</span>
        </S.Message>
        <div>
          <span>{t('FEEDBACK:feedbackMessage.greetingsMessage')}</span>
        </div>
      </S.Container>
    </Modal>
  );
};

export default FeedbackModal;
