import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { CompanyTableRow } from '@uptime-bff/api-contract';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import i18next from 'i18next';
import * as S from './styled';
import AddToDashboard from '../AddToDashboard';

type Props = {
  company: CompanyTableRow,
  onClick: (company: CompanyTableRow) => void;
  selectedCompanyId?: string;
  isFetchingCompanyList: boolean;
  setShowErrorBanner: (value: boolean) => void;
};

const CompanyListItem = ({ company, onClick, selectedCompanyId, isFetchingCompanyList, setShowErrorBanner }: Props) => {
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Companies]);

  const { name, countryCode, contactInfo, customerNumber, mowers, machines } = company;

  const convertCountryCodeToCountryName = (countryCodes: string): string | undefined => {
    if (!countryCodes) {
      return undefined;
    }
    const countryName = new Intl.DisplayNames(i18next.language ?? 'en', { type: 'region' });
    return countryName.of(countryCodes);
  };

  const contact = contactInfo ?? t('COMPANIES:companiesTableRow.contactInfoEmptyField');
  const countryName = convertCountryCodeToCountryName(countryCode);
  return (
    <S.ListItemsDiv>
      <S.CompanyListItemDiv
        data-testid="list"
        $active={!!(selectedCompanyId && selectedCompanyId === company.id)}
        key={company.id}
        onClick={() => onClick?.(company)}
      >
        <S.ListItemNameColumn>{name}</S.ListItemNameColumn>
        <S.ListItemCountryColumn>{countryName}</S.ListItemCountryColumn>
        <S.ListItemDiv>{contact}</S.ListItemDiv>
        <S.ListItemNumericColumn>{customerNumber}</S.ListItemNumericColumn>
        <S.ListItemNumericColumn>{machines}</S.ListItemNumericColumn>
        <S.ListItemNumericColumn>{mowers}</S.ListItemNumericColumn>
      </S.CompanyListItemDiv>
      <AddToDashboard
        company={company}
        isFetchingCompanyList={isFetchingCompanyList}
        setShowErrorBanner={setShowErrorBanner}
      />
    </S.ListItemsDiv>
  );
};

CompanyListItem.defaultProps = {
  selectedCompanyId: undefined,
};

export default CompanyListItem;
