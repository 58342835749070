import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

const useRestoreScroll = (): boolean => {
  const locationParam = useLocation();
  const [prevBasePath, setPrevBasePath] = useState('');

  // Helper function to get the base path
  const getBasePath = (pathname: string) => pathname.split('/')[1]; // e.g., 'assets' or 'companies'

  // Effect to update the previous base path on pathname change
  useEffect(() => {
    const currentBasePath = getBasePath(locationParam.pathname);
    setPrevBasePath(currentBasePath);
  }, [locationParam.pathname]);

  // Determine if scroll should be restored based on base path changes
  return getBasePath(locationParam.pathname) !== prevBasePath;
};

export default useRestoreScroll;
