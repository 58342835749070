import { device, paddings, typographyDesktop, typographyMobile } from 'core/styles/variables';
import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';

export const AssetThumbnailInfoContainerSection = styled.div`
  display: flex;
  align-items: center;
  margin: -${() => paddings.mediumPadding};
  

  @media ${() => device.md}{
    display: grid;
    grid-template-areas: 'details thumbnail placeholder';
  }
`;
export const ThumbnaildDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;

  @media ${() => device.md}{
    grid-area: details;
    padding-left: ${() => paddings.mediumPadding};
  }
`;

export const AssetNameSpan = styled.span`
  font-size: ${() => typographyMobile.size.medium};
  font-weight: ${() => typographyMobile.weight.bold};

  @media ${() => device.md}{
    font-size: ${() => typographyDesktop.size.h5};
  }
`;

export const AssetCompanyNameSpan = styled.span`
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray400 : theme.colors.gray600)};

  @media ${() => device.md}{
    font-size: ${() => typographyDesktop.size.small};
  }
`;

export const AssetModelSpan = styled.span`
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray400 : theme.colors.gray600)};

  @media ${() => device.md}{
    font-size: ${() => typographyDesktop.size.small};
    padding-top: ${() => paddings.smallPadding};
  }
`;
