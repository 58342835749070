import { headerHeight, paddings } from 'core/styles/variables';
import styled from 'styled-components';

interface Props {
  $position: 'left' | 'right';
  $visible: boolean;
}

const getPosition = ({ $position, $visible }: Props) => {
  if ($visible) {
    return '0';
  }
  if ($position === 'right') {
    return '100vw';
  }
  return '-100vw';
};

export const NavigationDrawer = styled.aside<Props>`
  position: fixed;
  left: ${({ $position, $visible: visible }) => getPosition({ $position, $visible: visible })};
  height: calc(100vh - ${() => headerHeight});
  top: ${() => headerHeight};
  background-color: ${({ theme }) => theme.colors.background};
  width: 100vw;
  transition: left 200ms ease-out;
  z-index: 1;
`;

export const MobileMenuContainer = styled.div`
  padding: ${() => paddings.hugePadding};
`;
