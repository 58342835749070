import { paddings } from 'core/styles';
import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';

export const CompanyInfoAssetsCell = styled.div`
  display: flex;
  text-align: center;
  justify-content: space-around;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => (theme === darkTheme ? theme.colors.gray200 : theme.colors.gray800)};
  padding: ${() => paddings.defaultPadding} ${() => paddings.largePadding};
`;
