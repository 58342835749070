import { device, paddings } from 'core/styles/variables';
import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';

export { ErrorSpan } from 'feature/auth/pages/Callback/styled';

export const WidgetContentDiv = styled.div`
  min-height: 330px;
`;

export const PieChartWithLegendDiv = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 100%;
  margin-top: 10px;

  @media (${() => device.xs}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const PieChartWithLegendColumnDiv = styled.div`
  display: flex;
  flex-direction: column;;
  width: 50%;
  gap: ${() => paddings.smallPadding};

  .hoverable-label {
    fill: ${({ theme }) => theme.colors.onSurface};
    cursor: pointer;
    dominant-baseline: middle;
    text-anchor: middle;
    &:hover {
      text-decoration: underline;
    }
  }

  @media ${() => device.xs} {
    width: 100%;
  }
`;

export const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 10px;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => (theme === darkTheme ? theme.colors.gray600 : theme.colors.gray200)};
    border-radius: 12px;
    color: ${({ theme }) => (theme === darkTheme ? theme.colors.onSurface : 'inherit')};
  }
`;

export const HeadingDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const HeadingText = styled.h6`
  margin: 0 0 0 8px;
`;
