import ErrorCard from 'core/components/ErrorCard';
import { Loader } from 'hudskit-framework-react';
import { useSearchableAssetList } from 'feature/assets/hooks/use-searchable-asset-list';
import type { AssetListItem as Asset } from '@uptime-bff/api-contract';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import useIntersectionObserver from 'core/hooks/use-intersection-observer';
import SearchNotFound from 'core/components/SearchNotFound/SearchNotFound';
import AssetListItem from '../AssetListItem';
import * as S from './styled';

type Props = {
  onAssetItemClickHandler: (asset: Asset) => void;
  selectedAssetId?: string;
};

const AssetList = ({ onAssetItemClickHandler, selectedAssetId }: Props) => {
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Assets, InternationalizationNameSpace.Core]);
  const observerTargetRef = useRef<HTMLDivElement | null>(null);
  const { assets, isError, isFetching, hasMoreAssets, getNextPage, loadItems } = useSearchableAssetList({
    pageSize: 25,
  });

  const entry = useIntersectionObserver(observerTargetRef, { });
  const isLastItemVisible = !!entry?.isIntersecting;

  useEffect(() => {
    (async () => {
      if (isLastItemVisible && hasMoreAssets && !isFetching && !isError) {
        await getNextPage();
      }
    })();
  }, [getNextPage, hasMoreAssets, isFetching, isLastItemVisible, isError]);

  const onRetryClickHandler = useCallback(() => {
    (async () => {
      await loadItems();
    })();
  }, [loadItems]);
  return (
    <S.ListItemsDiv>
      {isError && (
        <ErrorCard onRetryClick={onRetryClickHandler}>
          <>{t('ASSETS:list.errorLoadingAssets')}</>
        </ErrorCard>
      )}
      {!isLastItemVisible && isFetching && <Loader size="small" center />}
      {assets.length === 0 ? <SearchNotFound /> : assets.map((asset) => (
        <AssetListItem
          key={asset.id}
          active={!!(selectedAssetId && selectedAssetId === asset.id)}
          asset={asset}
          onClick={onAssetItemClickHandler}
        />
      ))}
      {isFetching && hasMoreAssets && <Loader size="medium" center />}
      {assets.length > 0 && <div ref={observerTargetRef} />}
      {assets.length > 0 && !hasMoreAssets && !isFetching && (
        <S.ListCenteredDiv>{t('ASSETS:list.noMoreAssetsToLoad')}</S.ListCenteredDiv>
      )}
    </S.ListItemsDiv>
  );
};

AssetList.defaultProps = {
  selectedAssetId: undefined,
};

export default AssetList;
