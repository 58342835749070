import { SelectField, OptionType } from 'hudskit-framework-react';
import { useCallback, useMemo } from 'react';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import * as S from './styled';

type Props = {
  options: OptionType[];
  selectedFilters?: string[];
  title: string;
  filterAction: any;
  id: string;
};

const FilterSearch = ({ options, selectedFilters, title, filterAction, id }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const selectOptions = useMemo<OptionType[]>(
    () =>
      options.map((v) => {
        const option: OptionType = {
          label: v.label,
          value: v.value,
        };
        return option;
      }),
    [options]
  );

  const toggleSectionHandler = useCallback(
    (filter: string) => {
      if (selectedFilters?.includes(filter)) {
        dispatch(filterAction(selectedFilters.filter((s) => s !== filter)));
      } else {
        dispatch(filterAction(selectedFilters ? [...selectedFilters, filter] : [filter]));
      }
    },
    [dispatch, filterAction, selectedFilters]
  );
  return (
    <S.SelectFieldDiv $active={filterAction}>
      <SelectField
        enableSearch
        id={id}
        label={title}
        noMatchText={t('ASSETS:fieldLabels.noMatchText')}
        onSelectValue={toggleSectionHandler}
        multiple
        defaultValues={selectedFilters ?? []}
        options={selectOptions}
      />
    </S.SelectFieldDiv>

  );
};

FilterSearch.defaultProps = {
  selectedFilters: undefined,
};

export default FilterSearch;
