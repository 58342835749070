import { paddings } from 'core/styles';
import {
  FunctionalColor,
  colorMix,
  getForegroundFunctionalColor,
  getFunctionalColor,
  typographyMobile,
} from 'core/styles/variables';
import styled from 'styled-components';

type PillProps = {
  color: FunctionalColor;
  $isLoading?: boolean;
};

export const PillDiv = styled.div<PillProps>`
  background-color: ${({ theme, color }) => colorMix(getFunctionalColor(theme, color), theme.colors.surface, 80)};
  color: ${({ theme, color }) => getForegroundFunctionalColor(theme, color)};
  padding: 0 ${paddings.defaultPadding};
  font-weight: ${typographyMobile.weight.bold};
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: start;
  grid-column: 2/3;
  height: 1.75rem;
  font-size: 1rem;
  border-radius: 1.75rem;
  cursor: ${({ $isLoading }) => !$isLoading && 'pointer'};
`;
