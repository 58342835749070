import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AssetListItem } from '@uptime-bff/api-contract';
import useDebounce from 'core/hooks/use-debounce';
import { useLazySearchAssetsQuery } from '../api';
import { appendAssetsToList, setAssetList } from '../store';

type Props = {
  pageSize: number;
};

const useSearchableAssetList = ({ pageSize }: Props) => {
  const [searchAssets, { isError, isSuccess, isFetching }] = useLazySearchAssetsQuery();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMoreAssets, setHasMoreAssets] = useState<boolean>(isSuccess);
  const activeSearch = useAppSelector((state) => state.assets.activeListFilters);
  const activeSorting = useAppSelector((state) => state.assets.assetListSorting);
  const uniqueAssets = useAppSelector<{ [key: string]: AssetListItem }>((state) => state.assets.assetList);
  const assets = useMemo(() => Object.values(uniqueAssets), [uniqueAssets]);
  const dispatch = useAppDispatch();
  const debouncedActiveSearch = useDebounce(activeSearch, 500);
  const debouncedActiveSorting = useDebounce(activeSorting, 500);

  const fetchAssets = useCallback(
    (pageToQuery: number) =>
      searchAssets({
        filters: debouncedActiveSearch,
        sorting: debouncedActiveSorting,
        pageSize,
        pageNumber: pageToQuery,
      }).unwrap(),
    [debouncedActiveSearch, debouncedActiveSorting, pageSize, searchAssets]
  );
  const loadItems = useCallback(async () => {
    try {
      const result = await fetchAssets(pageNumber);
      setHasMoreAssets(result.hasMorePages);
      if (pageNumber === 1) {
        dispatch(setAssetList(result.elements));
        return;
      }
      dispatch(appendAssetsToList(result.elements));
    } catch (e) {
      // Handled using RTK hook result
    }
  }, [dispatch, fetchAssets, pageNumber]);

  useEffect(() => {
    setPageNumber(1);
  }, [activeSearch, activeSorting]);
  useEffect(() => {
    (async () => {
      await loadItems();
    })();
  }, [loadItems, pageNumber]);

  const getNextPage = useCallback(async () => {
    setPageNumber((currPage) => currPage + 1);
  }, []);

  return { assets, isError, hasMoreAssets, getNextPage, isSuccess, isFetching, loadItems };
};
export { useSearchableAssetList };
