import { AssetDetailsResponse } from '@uptime-bff/api-contract';
import { Icon } from 'hudskit-framework-react';
import { useCallback, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useAddressLine } from 'feature/assets/hooks/use-address-line';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import CopyToClipboard from 'react-copy-to-clipboard';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import useMapUrl from 'feature/assets/hooks/use-map-url';
import * as S from './styled';
import { getShareableGoogleMapsURL } from './utils';

type Props = {
  asset: AssetDetailsResponse;
};

const AssetDetailsAddressline = ({ asset }: Props) => {
  const [copied, setCopied] = useState(false);
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const assetDetailsAddressline = useAddressLine(asset.assetPosition?.address);
  const hasAddress = assetDetailsAddressline !== t('ASSETS:addressline.missingAddress');

  const assetAddressURL = asset.assetPosition?.location
    ? getShareableGoogleMapsURL(asset.assetPosition?.location?.lat, asset.assetPosition?.location?.lng)
    : '';
  const mapUrl = useMapUrl(asset?.assetPosition?.location, assetAddressURL);

  const onCopyHandler = useCallback(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 2000);
    setCopied(true);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <CopyToClipboard text={hasAddress ? assetAddressURL : ''}>
        <S.AddressLineGridWrapperDiv>
          <S.InnerLeftContentDiv
            $isCopied={copied}
            $hasAddress={hasAddress}
            onClick={hasAddress ? onCopyHandler : undefined}
          >
            <S.MapIconDiv>
              <Icon type="action.map" color="gray400" size="small" />
            </S.MapIconDiv>
            <S.AssetAddressSpan data-tooltip-id="addressLine">{assetDetailsAddressline}</S.AssetAddressSpan>
            {hasAddress && (
            <Icon
              type={copied ? 'action.success' : 'action.copy'}
              color={copied ? 'success' : 'gray400'}
              size="small"
            />
            )}
          </S.InnerLeftContentDiv>
          <S.InnerRightContentDiv>
            <S.MapsAnchor data-testid="NavigateTo" href={mapUrl} target="_blank">
              <Icon type="action.open_in_new_window" color="brand110" size="small" />
              <S.TextWrapperDiv>{t('ASSETS:map.navigateTo')}</S.TextWrapperDiv>
            </S.MapsAnchor>
          </S.InnerRightContentDiv>
        </S.AddressLineGridWrapperDiv>
      </CopyToClipboard>
      <S.Tooltip>
        {!copied && hasAddress && (
          <Tooltip
            id="addressLine"
            content={t('ASSETS:details.labels.copyToClipboard')}
            className="tooltip"
            place="left"
            offset={20}
          />
        )}
      </S.Tooltip>
    </>
  );
};

export default AssetDetailsAddressline;
