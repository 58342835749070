import { navigationWithChildren } from 'core/config';
import * as S from './styled';
import AssetInfoCellContent, { ContentProps } from '../AssetInfoCellContent/AssetInfoCellContent';

type LinkProps = {
  link?: string;
};

type Props = ContentProps & LinkProps;

const AssetInfoCell = ({ label, value, icon, link, copyIcon }: Props) => {
  if (!navigationWithChildren) {
    if (!link) {
      return (
        <S.InfoCellDivV1>
          <AssetInfoCellContent label={label} value={value} icon={icon} copyIcon={copyIcon} />
        </S.InfoCellDivV1>
      );
    }
    return (
      <S.InfoCellAnchorV1 target="_blank" href={link}>
        <AssetInfoCellContent label={label} value={value} icon={icon} />
      </S.InfoCellAnchorV1>
    );
  }
  if (!link) {
    return (
      <S.InfoCellDiv $icon={!!icon}>
        <AssetInfoCellContent label={label} value={value} icon={icon} copyIcon={copyIcon} />
      </S.InfoCellDiv>
    );
  }
  return (
    <S.InfoCellAnchor target="_blank" href={link} $icon={!!icon}>
      <AssetInfoCellContent label={label} value={value} icon={icon} />
    </S.InfoCellAnchor>
  );
};

AssetInfoCell.defaultProps = {
  link: undefined,
};

export default AssetInfoCell;
