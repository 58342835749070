import { initializeApp } from 'firebase/app';
import { getRemoteConfig, isSupported } from 'firebase/remote-config';
import { getAnalytics } from 'firebase/analytics';
import { configFirebase } from '../environment-variables';

const firebaseConfig = {
  apiKey: configFirebase.apiKey,
  projectId: configFirebase.projectId,
  messagingSenderId: configFirebase.messagingSenderId,
  appId: configFirebase.appId,
  measurementId: configFirebase.measurementId,
  authDomain: configFirebase.authDomain,
};

export const app = initializeApp(firebaseConfig, configFirebase.appName);
export const analytics = getAnalytics(app);
export const remoteConfig = async () => (await isSupported()) && getRemoteConfig(app);
