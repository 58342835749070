import { borderRadius, paddings } from 'core/styles';
import styled from 'styled-components';

type MenuLiProps = {
  $selected?: boolean;
};

export const MenuUl = styled.ul`
  list-style: none;
  margin: 0;
  border-radius: ${() => borderRadius.default};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.colors.surface};
  font-size: 0.75rem;
  padding: 0;
`;

export const MenuSpan = styled.span`
  flex: 1;
  padding: ${() => paddings.smallPadding} ${() => paddings.defaultPadding};
  text-decoration: none;
  margin: 0;
  cursor: pointer;
`;

export const MenuLi = styled.li<MenuLiProps>`
  padding: 0;
  margin: 0;
  display: flex;
  color: ${({ theme }) => theme.colors.onSurface};
  &:first-child {
    ${MenuSpan} {
      border-radius: ${() => borderRadius.default} ${() => borderRadius.default} 0 0;
    }
  }
  &:last-child {
    ${MenuSpan} {
      border-radius: 0 0 ${() => borderRadius.default} ${() => borderRadius.default};
    }
  }
  ${MenuSpan} {
    background-color: ${({ theme, $selected }) => ($selected ? theme.colors.background : 'transparent')};

    color: ${({ theme, $selected }) => ($selected ? theme.colors.primary : theme.colors.onSurface)};
    &:hover {
      background-color: ${({ theme }) => theme.colors.background};
    }
  }
`;

export const TitleSpan = styled.span`
  font-size: 0.75rem;
  padding-top: ${() => paddings.defaultPadding};
  padding-left: ${() => paddings.defaultPadding};
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;
