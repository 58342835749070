import { paddings, typographyMobile } from 'core/styles/variables';
import styled from 'styled-components';

type Props = {
  $icon?: boolean;
};

export const IconContainerDiv = styled.div<Props>`
  display: ${({ $icon }) => ($icon ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const ContentDiv = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  overflow: auto;
  gap: ${paddings.smallPadding};
`;

export const LabelSpan = styled.span`
  font-weight: bold;
  min-width: fit-content;
`;

export const ValueSpan = styled.span`
  font-size: ${typographyMobile.size.default};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: ${typographyMobile.weight.regular};
  color: ${({ theme }) => theme.colors.onSurface};

`;

export const IconCopyAbleDiv = styled.div<Props>`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

// OLD STYLE
export const IconContainerDivV1 = styled.div`
  grid-area: icon;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LabelSpanV1 = styled.span`
  grid-area: label;
  font-weight: bold;
`;

export const ValueSpanV1 = styled.span`
  grid-area: value;
  font-size: ${() => typographyMobile.size.default};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconCopyAbleDivV1 = styled.div`
  grid-area: copy;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
