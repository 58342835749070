import { paddings } from 'core/styles';
import { darkTheme } from 'hudskit-framework-react';
import { styled } from 'styled-components';

export const SearchNotFoundDiv = styled.div`
  display: grid;
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray100 : theme.colors.gray800)};
  row-gap: 12px;
  padding: ${() => paddings.largePadding} 0 ${() => paddings.largePadding} ${() => paddings.largePadding} ;
  border-radius: 10px ;
`;

export const SearchLabel = styled.label`
  font-weight: 700;
  font-size: 20px;
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray200 : theme.colors.gray600)};
`;

export const DescriptionSpan = styled.span`
  font-size: 16px;
`;
