import Drawer from 'core/layout/Drawer';
import { device } from 'core/styles/variables';
import useMediaQuery from 'core/hooks/use-media-query';
import { useEffect } from 'react';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { setIsOpen } from 'core/layout/reducer';
import Filters from '../Filters';

const FiltersDrawer = () => {
  const filtersDrawerMenu = [
    { icon: 'action.filter', onClick: () => {}, text: 'Filter', title: 'Open Filters', key: '1', hasContent: true },
  ];
  const dispatch = useAppDispatch();
  const isLargeScreen = useMediaQuery(device.md);

  useEffect(() => {
    dispatch(setIsOpen(isLargeScreen));
  }, [isLargeScreen, dispatch]);

  return (
    <Drawer
      menu={filtersDrawerMenu}
      absolute={!isLargeScreen}
    >
      <Filters />
    </Drawer>
  );
};

export default FiltersDrawer;
