import { useMemo } from 'react';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import TagCheckbox from 'core/components/TagCheckbox';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { OnlineStateEnum } from '@uptime-bff/api-contract';
import { ConnectionStatus, setFilterByConnectionStatusOnActiveSearch, } from 'feature/assets/store';
import FilterCard from '../FilterCard';

const ConnectionStatusFilter = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const connectionStatusFilters = useMemo(() => Object.values(OnlineStateEnum).filter((s) => s), []);
  const activeConnectionStatusFilter = useAppSelector((state) => state.assets.activeListFilters?.connectionStatus);
  const dispatch = useAppDispatch();
  const connectionStatusTagClickHandler = (status:string, checked: boolean) => {
    if (!checked && activeConnectionStatusFilter) {
      dispatch(setFilterByConnectionStatusOnActiveSearch(activeConnectionStatusFilter !== status ? status as ConnectionStatus : undefined));
    } else {
      dispatch(setFilterByConnectionStatusOnActiveSearch(
        activeConnectionStatusFilter === status
          ? activeConnectionStatusFilter : status as ConnectionStatus
      ));
    }
  };

  return (
    <FilterCard title={t('ASSETS:labels.connection')}>
      <div>
        {connectionStatusFilters.map((connection) => (
          <TagCheckbox
            key={connection}
            name="connection-status"
            id={connection}
            value={connection}
            checked={activeConnectionStatusFilter?.includes(connection) ?? false}
            onChange={connectionStatusTagClickHandler}
          >
            {
              // @ts-ignore
              t(`ASSETS:mowerConnectionStatus.${connection}`)
            }
          </TagCheckbox>
        ))}
      </div>
    </FilterCard>
  );
};

export default ConnectionStatusFilter;
