import { useAppSelector } from 'core/hooks/use-app-selector';
import { setOidcState } from '../store';
import { useAuthHash } from './use-auth-hash';

const useAuthState = () => {
  const stateCode = useAppSelector((state) => state.auth.oidcState);
  const { cryptographicHash } = useAuthHash(setOidcState, stateCode);
  return { stateCryptographicHash: cryptographicHash };
};

export { useAuthState };
