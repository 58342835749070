import MapsMarker from 'core/components/MapsMarker';
import { MowerStatus, MowerStatusSeverity } from '@uptime-bff/api-contract';
import { getStatusColor } from 'feature/assets/utils';
import * as S from './styled';
import AssetThumbnail from '../AssetThumbnail';

type Props = {
  map: google.maps.Map;
  location?: {
    lat: number;
    lng: number;
  };
  name: string;
  thumbnail: string;
  assetStatusSeverity?: MowerStatusSeverity;
  assetStatus: MowerStatus;
};
const AssetListMarker = ({ map, location, name, thumbnail, assetStatusSeverity, assetStatus }: Props) => {
  const statusColor = getStatusColor(assetStatus);

  return (
    // @ts-ignore
    <MapsMarker map={map} position={location}>
      {assetStatusSeverity ? (
        <AssetThumbnail imageAlt={name} imageSrc={thumbnail} color={assetStatusSeverity} marker />
      ) : (
        <S.StatusCircle color={statusColor} />
      )}
    </MapsMarker>
  );
};
AssetListMarker.defaultProps = {
  assetStatusSeverity: undefined,
  location: {
    lat: 0,
    lng: 0,
  },
};
export default AssetListMarker;
