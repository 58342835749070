import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useLastUpdatedTimeStamp = (time?: number) => {
  const { t } = useTranslation([InternationalizationNameSpace.Dashboard]);
  const [passedTime, setPassedTime] = useState<string>('');

  const calcTimeHasPassed = useCallback(() => {
    const now = new Date();
    const diff = now.getTime() - (time ?? 0);
    const minutes = Math.floor((diff / 1000 / 60) % 60);
    if (minutes < 1) {
      return t('DASHBOARD:dashboard.customerWidgets.justAMomentAgo');
    }
    if (minutes === 1) {
      return `${minutes} ${t('DASHBOARD:dashboard.customerWidgets.minuteAgo')}`;
    }
    if (minutes < 60) {
      return `${minutes} ${t('DASHBOARD:dashboard.customerWidgets.moreThanOneMinuteAgo')}`;
    }
    return DateTime.now().toLocaleString(DateTime.DATETIME_MED);
  }, [t, time]);

  useEffect(() => {
    const timer = setInterval(() => {
      setPassedTime(calcTimeHasPassed());
    }, 60000);
    setPassedTime(calcTimeHasPassed());
    return () => clearInterval(timer);

  }, [calcTimeHasPassed, time]);

  return { passedTime };
};

export { useLastUpdatedTimeStamp };
