import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import FilterSearch from 'feature/assets/components/FilterSearch';
import { useSearchAvailableFiltersQuery } from 'feature/companies/api';
import { clearFilters, setFiltersByCountryCodeOnActiveSearch } from 'feature/companies/store';
import { Button, Loader, OptionType } from 'hudskit-framework-react';
import { useCallback, useMemo } from 'react';
import i18next from 'i18next';
import * as S from './styled';

export const convertCountryCodeToCountryName = (countryCode: string): string => {
  const countryNames = new Intl.DisplayNames([i18next.language ?? 'en'], { type: 'region' }).of(countryCode) ?? '';
  return countryNames;
};

const CompaniesFilter = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const filters = useAppSelector((state) => state.companies.activeCompanyFilters);
  const { data, isLoading } = useSearchAvailableFiltersQuery({ ...filters });
  const selectedCountries = useAppSelector((state) => state.companies.activeCompanyFilters?.countryCode);
  const dispatch = useAppDispatch();
  const onClearFiltersClick = useCallback(() => {
    dispatch(clearFilters());
  }, [dispatch]);

  const companiesOptions = useMemo<OptionType[]>(
    () => data?.countries.map((country) => ({
      label: convertCountryCodeToCountryName(country),
      value: country,
    })) ?? [],
    [data]
  ).sort((a, b) => a.label.localeCompare(b.label));

  return (
    <S.FilterContainerDiv data-testid="filters">
      <S.FiltersForm>
        <Button
          onClick={onClearFiltersClick}
          type="button"
          variant="secondary"
          disabled={!filters}
        >
          {t('ASSETS:filters.buttons.clearFilters')}
        </Button>
        {isLoading && <Loader size="small" center />}
        <div data-testid="card">
          {data?.countries && (
            <FilterSearch
              data-testid="filtersSearch"
              title={t('ASSETS:labels.countries')}
              id="companies-countries"
              selectedFilters={selectedCountries}
              filterAction={setFiltersByCountryCodeOnActiveSearch}
              options={companiesOptions}
            />
          )}
        </div>
      </S.FiltersForm>
    </S.FilterContainerDiv>
  );
};

export default CompaniesFilter;
