import { useCallback } from 'react';
import { TranslationKeys } from 'core/models/translations.model';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useTranslation } from 'react-i18next';

const useTranslationWrapper = (namespaces:InternationalizationNameSpace | InternationalizationNameSpace[]) => {
  const { t } = useTranslation(namespaces);
  const translate = useCallback((key:TranslationKeys) => t(key), [t]);
  return { t: translate };
};
export { useTranslationWrapper };
