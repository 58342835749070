/* eslint-disable max-len */
import { Icon, IconTypeKey } from 'hudskit-framework-react';
import { useCallback } from 'react';
import * as S from './styled';

export type MenuItemProps = {
  key: string;
  icon: IconTypeKey;
  text: string;
  hasContent: boolean;
  title: string;
  onClick: () => void;
};

type Props = {
  menuItem: MenuItemProps;
  toggleDrawer?: (key: string) => void;
  isSelected: boolean;
};

const DrawerMenuItem = ({ menuItem, toggleDrawer, isSelected }: Props) => {
  const drawerMenuClick = useCallback(() => {
    if (menuItem.hasContent) {
      toggleDrawer?.(menuItem.key);
    }
    menuItem.onClick?.();
  }, [menuItem, toggleDrawer]);
  return (
    <S.MenuLi $selected={isSelected}>
      <S.ShieldAside>
        <S.ShieldEdgeSvg>
          <path d="M 4.5342 3.669 C 4.8351 4.0289 5 4.483 5 4.9521 L 5 6 L 0 6 L -0 1.002 C -0 0.0676 1.1676 -0.3563 1.7671 0.3605 L 4.5342 3.669 Z" />
        </S.ShieldEdgeSvg>
        <S.ShieldCenterDiv data-testid="listmenu-shield-center" />
        <S.ShieldEdgeSvg>
          <path d="m 4.534 2.331 c 0.301 -0.36 0.466 -0.814 0.466 -1.283 l 0 -1.048 l -5 0 l 0 4.998 c 0 0.934 1.168 1.358 1.767 0.642 l 2.767 -3.309 z" />
        </S.ShieldEdgeSvg>
      </S.ShieldAside>

      <S.Button onClick={drawerMenuClick} title={menuItem.title}>
        <Icon type={menuItem.icon} size="medium" color={isSelected ? 'primary' : 'onBackground'} />
        {menuItem.text}
      </S.Button>
    </S.MenuLi>
  );
};

DrawerMenuItem.defaultProps = {
  toggleDrawer: undefined,
};

export default DrawerMenuItem;
