import { MowerStatus } from '@uptime-bff/api-contract';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useMemo } from 'react';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { setFilterByStatusOnActiveSearch } from 'feature/assets/store';
import TagCheckbox from 'core/components/TagCheckbox';
import FilterCard from '../FilterCard';
import * as S from './styled';

const StatusFilter = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const statusList = useMemo(() => Object.values(MowerStatus).filter((status) => status !== MowerStatus.Unknown), []);
  const activeStatusFilters = useAppSelector((state) => state.assets.activeListFilters?.status);
  const dispatch = useAppDispatch();
  const statusTagClickHandler = (status: string, checked: boolean) => {
    if (!checked && activeStatusFilters) {
      dispatch(setFilterByStatusOnActiveSearch(activeStatusFilters.filter((s) => s !== status)));
    } else {
      dispatch(setFilterByStatusOnActiveSearch(activeStatusFilters ? [...activeStatusFilters, status] : [status]));
    }
  };
  return (
    <FilterCard title={t('ASSETS:filters.labels.status')}>
      <S.StatusTagsDiv>
        {statusList.map((statusItem) => (
          <TagCheckbox
            key={statusItem}
            checked={activeStatusFilters?.includes(statusItem) ?? false}
            value={statusItem}
            onChange={statusTagClickHandler}
            name="mower-status"
            id={statusItem}
          >
            {
              // @ts-ignore
              t(`ASSETS:mowerStatus.${statusItem}`)
            }
          </TagCheckbox>
        ))}
      </S.StatusTagsDiv>
    </FilterCard>
  );
};

export default StatusFilter;
