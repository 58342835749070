import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import * as S from './styled';

const SearchNotFound = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Core);

  return (
    <S.SearchNotFoundDiv>
      <S.SearchLabel>{t('CORE:notFound.searchNotMatch')}</S.SearchLabel>
      <S.DescriptionSpan>{t('CORE:notFound.searchSpelling')}</S.DescriptionSpan>
    </S.SearchNotFoundDiv>
  );
};

export default SearchNotFound;
