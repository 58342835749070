/* eslint-disable max-len */
import DrawerMenuItem from '../DrawerMenuItem';
import { MenuItemProps } from '../DrawerMenuItem/DrawerMenuItem';
import * as S from './styled';

type MenuProps = {
  menu: MenuItemProps[];
  isOpen: boolean;
  activeMenu?:string;
  onToggleMenuClick:(key: string) =>void;
};
const DrawerMenu = ({ menu, onToggleMenuClick, isOpen, activeMenu }:MenuProps) => (
  <>
    <S.MenuUl>
      {menu.map((menuItem) => (
        <DrawerMenuItem
          key={menuItem.key}
          menuItem={menuItem}
          toggleDrawer={onToggleMenuClick}
          isSelected={activeMenu === menuItem.key && isOpen}
        />
      ))}
    </S.MenuUl>
    <S.CorniceSvg width="72" height="56" viewBox="0 0 72 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5224 37.4563L59.4776 18.5437C67.044 15.4962 72 8.15714 72 0L-2.44784e-06 3.14722e-06L0 56C-3.5656e-07 47.8429 4.95596 40.5039 12.5224 37.4563Z" />
    </S.CorniceSvg>
  </>
);

DrawerMenu.defaultProps = {
  activeMenu: undefined,
};

export default DrawerMenu;
