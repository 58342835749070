import { MowerStatus, StatusCountResponse } from '@uptime-bff/api-contract';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { getColorBasedOnStatus } from 'core/styles/variables';
import { useMemo } from 'react';
import { DefaultTheme } from 'styled-components';

type ChartDataProps = {
  data?: StatusCountResponse<MowerStatus>;
  theme: DefaultTheme;
};

export type ChartData = {
  name: string;
  value: number;
  color: string;
  key: string;
};
const useStatusChartData = ({ data, theme }: ChartDataProps): { chartData?: ChartData[]; total?: number } => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);

  const chartData = useMemo(() => {
    if (!data) {
      return undefined;
    }
    return data.items.filter((stat) => stat.status !== MowerStatus.Unknown).map((item) => ({
      name: t(`ASSETS:mowerStatus.${item.status}` as 'ASSETS:mowerStatus'),
      value: item.count,
      color: getColorBasedOnStatus(theme, item.status),
      key: item.status,
    }));
  }, [data, t, theme]);
  const total = useMemo(
    () => data?.totalCount,
    [data?.totalCount]
  );

  return { chartData, total };
};
export { useStatusChartData };
