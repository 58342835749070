import { Button, Card, Icon, Loader } from 'hudskit-framework-react';
import SortableList, { SortableItem } from 'react-easy-sort';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'core/models/routes.model';
import { Tooltip } from 'react-tooltip';
import ErrorCard from 'core/components/ErrorCard';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetCustomerWidgetsQuery } from 'feature/dashboard/api';
import { numberOfCustomerWidgetsAllowed } from 'core/config';
import ErrorBanner from 'core/components/ErrorBanner';
import { arrayMoveImmutable } from 'feature/dashboard/utils';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useLastUpdatedTimeStamp } from 'feature/dashboard/hooks/use-last-updated-time-stamp';
import * as S from './styled';
import CustomerWidgetCard from '../CustomerWidgetCard';

const CustomerWidgets = () => {
  const { t } = useTranslation([InternationalizationNameSpace.Dashboard]);
  const [doneEditing, setDoneEditing] = useState(false);

  const navigate = useNavigate();
  const toolTipDescription = t('DASHBOARD:dashboard.customerWidgets.companiesAllowedTooltip', {
    customerWidgetsAllowed: `${numberOfCustomerWidgetsAllowed}`,
    defaultValue: t('DASHBOARD:dashboard.error.unexpectedError'),
  });

  const { isSuccess, data, isError, isFetching, refetch: getCustomerWidgets, isLoading } = useGetCustomerWidgetsQuery();
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [items, setItems] = useState(data?.widgets || []);
  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setItems((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };
  const { passedTime } = useLastUpdatedTimeStamp(data?.lastUpdatedTimeStamp);
  const NoCustomerWidgets = useMemo(
    () => (
      <>
        <S.NoWidgetTitleDiv data-testid="NoWidgetsTitle">
          {t('DASHBOARD:dashboard.customerWidgets.noCustomerWidgetsTitle')}
        </S.NoWidgetTitleDiv>
        <S.NoWidgetSubTitleDiv>
          {t('DASHBOARD:dashboard.customerWidgets.noCustomerWidgetsSubTitle')}
        </S.NoWidgetSubTitleDiv>
      </>
    ),
    [t]
  );

  const handleDeleteAPIResp = useCallback((isAPIError: boolean) => {
    setShowErrorBanner(false);
    if (isAPIError) {
      setShowErrorBanner(true);
    }
  }, []);

  const handleRefreshClick = useCallback(() => {
    getCustomerWidgets();
    setShowErrorBanner(false);
  }, [getCustomerWidgets]);

  const handleEditButtonClick = useCallback(() => {
    setDoneEditing(!doneEditing);
    setShowErrorBanner(false);
  }, [doneEditing]);

  useEffect(() => {
    setItems(data?.widgets || []);
  }, [data]);
  return (
    <>
      <S.HeaderRowDiv>
        <S.LeftContentDiv>
          <S.HeaderTitle data-testid="headerTitle">
            {t('DASHBOARD:dashboard.customerWidgets.headerTitle')}
          </S.HeaderTitle>
          {!isError && data?.widgets.length !== 0 && (
            <S.LastUpdatedDiv>
              <Button
                variant="surface"
                icon={
                  {
                    position: 'right',
                  }
                }
                onClick={handleRefreshClick}
              >
                <S.RefreshIconDiv $isFetching={isFetching} data-testid="refreshIcon">
                  <Icon type="action.refresh" size="medium" color="gray200" />
                </S.RefreshIconDiv>
              </Button>
              {!isLoading && !isFetching && (
                <span>
                  {`${t('DASHBOARD:dashboard.customerWidgets.lastupdated')} ${passedTime}`}
                </span>
              )}

            </S.LastUpdatedDiv>
          )}
        </S.LeftContentDiv>
        <S.RightContentDiv>
          {isSuccess && (
            <>
              <div data-tooltip-id="companiesAllowedTooltipId">
                {t('DASHBOARD:dashboard.customerWidgets.customerWidgetsSelectedOutOfAllowedWidgets', {
                  customerWidgetsSelected: `${data?.widgets?.length}`,
                  customerWidgetsAllowed: `${numberOfCustomerWidgetsAllowed}`,
                  defaultValue: 'Unexpected error',
                })}
              </div>
              <Tooltip id="companiesAllowedTooltipId" data-testid="countTooltip" className="toolTip">
                {toolTipDescription}
              </Tooltip>
              <S.AddPillDiv data-tooltip-id="addCompanyId">
                <Button
                  variant="surface"
                  icon={{
                    type: 'action.add',
                    position: 'right',
                  }}
                  disabled={data?.widgets.length >= numberOfCustomerWidgetsAllowed}
                  onClick={() => navigate(RoutePaths.Companies)}
                />
              </S.AddPillDiv>
              {data?.widgets?.length > 0 && (
                <S.EditPillDiv data-testid="editBtn">
                  <Button
                    variant="surface"
                    icon={
                      !doneEditing
                        ? {
                          type: 'action.edit',
                          position: 'right',
                        }
                        : undefined
                    }
                    onClick={handleEditButtonClick}
                  >
                    {doneEditing && <>{t('DASHBOARD:dashboard.customerWidgets.doneEditingButtonText')}</>}
                  </Button>
                </S.EditPillDiv>
              )}
              {data?.widgets.length >= numberOfCustomerWidgetsAllowed && (
                <Tooltip id="addCompanyId" data-testid="addButtonTooltip" place="top" className="toolTip">
                  {t('DASHBOARD:dashboard.customerWidgets.disableAddButtonTooltip')}
                </Tooltip>
              )}
            </>
          )}

        </S.RightContentDiv>
      </S.HeaderRowDiv>
      {isError && (
        <Card>
          <ErrorCard onRetryClick={() => getCustomerWidgets()} />
        </Card>
      )}
      {(isLoading || (isFetching && data?.widgets.length === 0)) && <Loader size="medium" center padding />}
      {showErrorBanner && !isError && (
        <S.ErrorWrapperDiv>
          <ErrorBanner
            messageText={t('DASHBOARD:dashboard.customerWidgets.errors.deleteCompanyWidget')}
            dismissText="Dismiss"
            iconType="notification.error"
            iconColor="error"
            borderColor="error"
            dismissClickHandler={() => setShowErrorBanner(false)}
          />
        </S.ErrorWrapperDiv>
      )}
      {isSuccess && data && data.widgets.length > 0 && (
        <S.CardsContainerDiv>
          <SortableList onSortEnd={onSortEnd} allowDrag={false} className="grid">
            {items.map((item) => (
              <SortableItem key={item.companyId}>
                <S.CustomerWidgetCardWrapperDiv>
                  <CustomerWidgetCard
                    isWidgetsListUpdating={isFetching}
                    cardData={item}
                    key={item.companyId}
                    doneEditing={doneEditing}
                    showErrorBanner={(resp) => handleDeleteAPIResp(resp)}
                  />
                </S.CustomerWidgetCardWrapperDiv>
              </SortableItem>
            ))}
          </SortableList>
        </S.CardsContainerDiv>
      )}
      {isSuccess && !isFetching && data && data.widgets.length === 0 && NoCustomerWidgets}
    </>
  );
};
export default CustomerWidgets;
