import { zDrawers } from 'core/styles/z-index';
import styled from 'styled-components';

export const DrawerDiv = styled.div`
  position: relative;
  z-index: ${() => zDrawers};
`;

export const DropdownDiv = styled.div`
  position: absolute;
  transition: all 200ms ease;
  z-index: ${() => zDrawers};
  top: calc(100% + 0.75rem);
  white-space: nowrap;
  overflow: visible;
  right: 0;
  transform: translateY(-10px);
`;
