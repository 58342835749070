const {
  VITE_APP_API_URL,
  VITE_FB_API_KEY,
  VITE_FB_PROJECT_ID,
  VITE_FB_MESSAGING_SENDER_ID,
  VITE_FB_APP_ID,
  VITE_FB_MEASUREMENT_ID,
  VITE_FB_AUTH_DOMAIN,
  VITE_FB_APP_NAME,
  VITE_FB_CONFIG_FETCH_INTERVAL,
  VITE_APP_ISAM_OIDC_URL,
  VITE_APP_ISAM_OIDC_CLIENT_ID,
  VITE_APP_ISAM_OIDC_REDIRECT_URL,
  VITE_GOOGLE_MAPS_API_KEY,
  VITE_FEEDBACK_EMAIL,
  VITE_APP_ENV,
} = import.meta.env;

export const configFirebase = {
  apiKey: VITE_FB_API_KEY,
  projectId: VITE_FB_PROJECT_ID,
  messagingSenderId: VITE_FB_MESSAGING_SENDER_ID,
  appId: VITE_FB_APP_ID,
  measurementId: VITE_FB_MEASUREMENT_ID,
  authDomain: VITE_FB_AUTH_DOMAIN,
  appName: VITE_FB_APP_NAME,
  remoteConfigFetchInterval: VITE_FB_CONFIG_FETCH_INTERVAL,
};

export const apiKeys = {
  maps: VITE_GOOGLE_MAPS_API_KEY,
};

export const configApp = {
  feedbackEmail: VITE_FEEDBACK_EMAIL,
  env: VITE_APP_ENV,
};

export const configAuth = {
  oidcUrl: VITE_APP_ISAM_OIDC_URL,
  oidcClientId: VITE_APP_ISAM_OIDC_CLIENT_ID,
  oidcRedirectUrl: VITE_APP_ISAM_OIDC_REDIRECT_URL,
};

export const configBff = {
  apiUrl: VITE_APP_API_URL,
};
