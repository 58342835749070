import { paddings } from 'core/styles';
import styled from 'styled-components';

export const BackgroundDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.surface};
  color: ${({ theme }) => theme.colors.onSurface};
  padding: ${() => paddings.defaultPadding};
`;

export const MessageDiv = styled.div`
  padding: ${() => paddings.defaultPadding} 0;
`;
