import { TokenExpirationResponse, TokenRequest, TokenResponse } from '@uptime-bff/api-contract';
import { uptimeApi } from 'core/api';

const authApi = uptimeApi.injectEndpoints({
  endpoints: (build) => ({
    exchangeToken: build.mutation<TokenResponse, TokenRequest>({
      query: (body) => ({
        url: 'token',
        method: 'POST',
        body,
      }),
    }),
    deleteToken: build.mutation<void, void>({
      query: () => ({
        url: 'token',
        method: 'DELETE',
      }),
    }),

    getTokenDetails: build.query<TokenExpirationResponse, void>({
      query: () => ({
        url: 'token',
        method: 'GET',
      }),
    }),
  }),
});

export const { useExchangeTokenMutation, useDeleteTokenMutation, useGetTokenDetailsQuery } = authApi;
