import { useOutletContext } from 'react-router-dom';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { AssetDetailsResponse } from '@uptime-bff/api-contract';
import { navigationWithChildren } from 'core/config';
import AssetInfoCell from '../AssetInfoCell';

type Props = {
  assetDetails?: AssetDetailsResponse;
};

const AssetDetailsContact = ({ assetDetails }: Props) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const asset: AssetDetailsResponse = useOutletContext();
  const assetResponse = navigationWithChildren ? asset : assetDetails;
  return (
    <>
      {assetResponse?.companyCountry && (
      <AssetInfoCell
        label={t('ASSETS:details.labels.country')}
        value={assetResponse.companyCountry}
        icon="other.location"
      />
      )}

      {assetResponse?.companyName && (
      <AssetInfoCell
        label={t('ASSETS:details.labels.companyName')}
        value={assetResponse.companyName}
        icon="action.home_outlined"
      />
      )}

      {assetResponse?.customerContactInformation && (
      <AssetInfoCell
        label={t('ASSETS:details.labels.contactInformation')}
        value={assetResponse.customerContactInformation}
        icon="action.call"
        link={`tel:${assetResponse.customerContactInformation}`}
      />
      )}
    </>
  );
};

export default AssetDetailsContact;

AssetDetailsContact.defaultProps = {
  assetDetails: undefined,
};
