import { darkTheme, boxShadows } from 'hudskit-framework-react';
import { zFloatingButton } from 'core/styles/z-index';
import styled from 'styled-components';

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  box-shadow: ${({ theme }) => (theme === darkTheme ? boxShadows.dark : boxShadows.normal)};
  position: fixed;
  bottom: 210px;
  right:8px;
  z-index: ${() => zFloatingButton};

  &:hover {
    background-color: ${({ theme }) => theme.colors.brand100};
    cursor: pointer;
  }
`;
