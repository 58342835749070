/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CompanyTableRow, CompaniesRequest, CompaniesSearchProps, TotalDashboardWidgetsResponse, } from '@uptime-bff/api-contract';
import { CountryCode } from '@uptime-bff/api-contract/dist/common';

type CompanyFiltersType = CompaniesRequest['filters'];
type CompanySortingType = CompaniesRequest['sorting'];
type CompanyListFilterSearchType = {
  query: string;
  fields: CompaniesSearchProps[];
};

export const defaultState: CompanyListState = {
  activeCompanyFilters: undefined,
  companiesList: {},
  companiesSorting: undefined,
  companyMapActiveCompanyId: undefined,
  companySearch: {
    query: '',
    fields: [],
  },
  companyDashboardWidgets: {
    totalNumberOfDashboardWidgets: 0,
  },
  selectedCompany: undefined,
};

interface CompanyListState {
  activeCompanyFilters: CompanyFiltersType;
  companiesList: { [key: string]: CompanyTableRow };
  companiesSorting?: CompanySortingType;
  companyMapActiveCompanyId?: string;
  companySearch?: CompanyListFilterSearchType;
  companyDashboardWidgets?: TotalDashboardWidgetsResponse;
  selectedCompany?: CompanyTableRow;
}

const companyListToMap = (list: CompanyTableRow[]): { [key: string]: CompanyTableRow } => {
  const payloadCompaniesMap: { [key: string]: CompanyTableRow } = {};
  for (const company of list) {
    payloadCompaniesMap[company.id] = { ...company };
  }
  return payloadCompaniesMap;
};

const slice = createSlice({
  name: 'companies',
  initialState: defaultState,
  reducers: {
    setSelectedCompany: (state, action: PayloadAction<CompanyTableRow | undefined>) => {
      state.selectedCompany = action.payload;
    },

    setWidgetToDashboard: (state, action: PayloadAction<CompanyTableRow>) => {
      const company = action.payload;
      if (state.companiesList[company.id]) {
        state.companiesList[company.id] = company;
      }
    },
    setTotalNumberOfDashboardWidgets: (state, action: PayloadAction<TotalDashboardWidgetsResponse>) => {
      if (state.companyDashboardWidgets) {
        state.companyDashboardWidgets.totalNumberOfDashboardWidgets = action.payload.totalNumberOfDashboardWidgets;
      }
    },

    setSortingOnActiveSearch: (state, action: PayloadAction<CompanySortingType>) => {
      state.companiesSorting = action.payload;
    },
    setFilterByIdOnActiveSearch: (state, action: PayloadAction<string>) => {
      state.activeCompanyFilters = { ...state.activeCompanyFilters, id: action.payload };
    },

    setFuzzySearchQuery: (state, action: PayloadAction<string>) => {
      state.companySearch = {
        fields: state.companySearch?.fields ?? [CompaniesSearchProps.Name],
        query: action.payload,
      };
      slice.caseReducers.setSearchOnActiveSearch(state);
    },
    setFuzzySearchField: (state, action: PayloadAction<{ field: CompaniesSearchProps; selected: boolean }>) => {
      const currentProps: CompaniesSearchProps[] = state.companySearch?.fields ?? [];
      state.companySearch = {
        fields: action.payload.selected
          ? [...currentProps, action.payload.field]
          : currentProps.filter((prop) => prop !== action.payload.field),
        query: state.companySearch?.query ?? '',
      };
      slice.caseReducers.setSearchOnActiveSearch(state);
    },

    setSearchOnActiveSearch: (state) => {
      if (
        state.companySearch?.query.length
        && state.companySearch?.query.length > 1
        && state.companySearch?.fields.length
        && state.companySearch?.fields.length > 0
      ) {
        state.activeCompanyFilters = {
          ...state.activeCompanyFilters,
          search: state.companySearch,
        };
      } else {
        state.activeCompanyFilters = {
          ...state.activeCompanyFilters,
          search: undefined
        };
      }
    },

    clearFuzzySearchOnActiveSearch: (state) => {
      state.activeCompanyFilters = {
        ...state.activeCompanyFilters,
        search: undefined,
      };
      state.companySearch = undefined;
    },

    setFiltersByCountryCodeOnActiveSearch: (state, action: PayloadAction<CountryCode[]>) => {
      state.activeCompanyFilters = {
        ...state.activeCompanyFilters,
        countryCode: action.payload.length > 0 ? action.payload : undefined,
      };
    },
    appendCompaniesToList: (state, action: PayloadAction<CompanyTableRow[]>) => {
      state.companiesList = { ...state.companiesList, ...companyListToMap(action.payload) };
    },
    setCompanyMapActiveCompnayId: (state, action: PayloadAction<string | undefined>) => {
      state.companyMapActiveCompanyId = action.payload;
    },
    setCompanies: (state, action: PayloadAction<CompanyTableRow[]>) => {
      state.companiesList = companyListToMap(action.payload);
    },
    clearFilters: (state) => {
      state.activeCompanyFilters = undefined;
      state.companiesSorting = undefined;
      state.companySearch = undefined;
    }
  }
});

export const {
  setSelectedCompany,
  setWidgetToDashboard,
  setTotalNumberOfDashboardWidgets,
  setSortingOnActiveSearch,
  setFilterByIdOnActiveSearch,
  setFuzzySearchQuery,
  setFuzzySearchField,
  setSearchOnActiveSearch,
  clearFuzzySearchOnActiveSearch,
  setFiltersByCountryCodeOnActiveSearch,
  appendCompaniesToList,
  setCompanyMapActiveCompnayId,
  setCompanies,
  clearFilters
} = slice.actions;

export default slice.reducer;
