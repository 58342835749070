import { Icon, IconTypeKey } from 'hudskit-framework-react';
import { navigationWithChildren } from 'core/config';
import { useCallback, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import * as S from './styled';

export type ContentProps = {
  label: string;
  value: string;
  icon?: IconTypeKey;
  copyIcon?: IconTypeKey;
};

const AssetInfoCellContent = ({ label, value, icon, copyIcon }: ContentProps) => {
  const [copied, setCopied] = useState(false);
  const iconType = copied ? 'action.success' : copyIcon;
  const defaultIcon = navigationWithChildren ? '' : 'notification.info_outlined';

  const onCopyText = useCallback(() => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, []);

  const assetInfoV1 = (
    <>
      <S.IconContainerDivV1 data-testid={icon}>
        <Icon type={icon ?? defaultIcon} size="medium" />
      </S.IconContainerDivV1>
      <S.LabelSpanV1>{label}</S.LabelSpanV1>
      <S.ValueSpanV1>{value}</S.ValueSpanV1>
      {copyIcon && (
        <CopyToClipboard text={value} onCopy={onCopyText}>
          <S.IconCopyAbleDivV1 data-testid={iconType}>
            <Icon
              type={iconType}
              color={copied ? 'success' : 'onSurface'}
              size="medium"
            />
          </S.IconCopyAbleDivV1>
        </CopyToClipboard>
      )}
    </>
  );

  const assetInfo = (
    <>
      <S.IconContainerDiv $icon={!!icon} data-testid={icon}>
        <Icon type={icon ?? defaultIcon} size="medium" />
      </S.IconContainerDiv>
      <S.ContentDiv>
        <S.LabelSpan>{`${label}: `}</S.LabelSpan>
        <S.ValueSpan>{value}</S.ValueSpan>
      </S.ContentDiv>
      {copyIcon && (
        <CopyToClipboard text={value} onCopy={onCopyText}>
          <S.IconCopyAbleDiv data-testid={iconType}>
            <Icon
              type={iconType}
              color={copied ? 'success' : 'onSurface'}
              size="medium"
            />
          </S.IconCopyAbleDiv>
        </CopyToClipboard>
      )}
    </>
  );

  return navigationWithChildren ? assetInfo : assetInfoV1;
};

AssetInfoCellContent.defaultProps = {
  icon: undefined,
  copyIcon: undefined,
};

export default AssetInfoCellContent;
