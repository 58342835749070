import { useEffect, useState } from 'react';
import * as S from './styled';
import { CircleColor, Size } from './styled';

export type Props = {
  size?: Size;
  color?: CircleColor;
  imageSrc: string;
  imageAlt: string;
  marker?: boolean;
};

const AssetThumbnail = ({ color, size, imageSrc, imageAlt, marker }: Props) => {
  const [imgState, setImageState] = useState<string>(imageSrc);
  useEffect(() => {
    setImageState(imageSrc);
  }, [imageSrc]);
  return (
    <S.ImageContainer size={size} color={color}>
      {color && (
        <S.CircleBackground
          data-testid="assetThumbnailBackgroundDiv"
          className={marker && size === 'sm' ? 'marker' : undefined}
        />
      )}
      <S.AssetImage
        id="assetImage"
        src={imgState}
        alt={imageAlt}
        onError={() => {
          setImageState(
            'https://common-assets-054390323997-dev.s3-eu-west-1.amazonaws.com/images/120x100/icon_machine_robotic_lawn_mower_120x100.png'
          );
        }}
      />
    </S.ImageContainer>
  );
};
AssetThumbnail.defaultProps = {
  color: undefined,
  size: 'sm',
  marker: false,
};

export default AssetThumbnail;
