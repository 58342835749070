/* eslint-disable max-len */

import uptimeLogoDark from 'images/logos/uptime-logo-dark.svg';
import uptimeLogoLight from 'images/logos/uptime-logo-light.svg';
import { useTheme } from 'styled-components';
import { lightTheme } from 'hudskit-framework-react';
import * as S from './styled';

interface Props {
  size?: number;
}
const Logo = ({ size }: Props) => {
  const theme = useTheme();
  const logo = theme === lightTheme ? uptimeLogoLight : uptimeLogoDark;
  return (
    <S.LogoContainer>
      <S.LogoImage id="utc_logo" src={logo} alt="Husqvarna Uptime Center" size={size} />
    </S.LogoContainer>
  );
};

Logo.defaultProps = {
  size: 200,
};

export default Logo;
