import { DateTime } from 'luxon';
import { Tooltip } from 'react-tooltip';
import * as S from './styled';

interface Props {
  dataUpdatedOn: string;
}

const LatestCompanyListUpdate = ({ dataUpdatedOn }: Props) => {

  const lastUpdatedOn = DateTime.fromISO(dataUpdatedOn);
  const currentDate = DateTime.now();
  const lastDateUpdatedOn = lastUpdatedOn.toLocaleString(DateTime.DATETIME_MED);
  const diffInHours = Math.floor(currentDate.diff(lastUpdatedOn, 'hour').toObject().hours ?? 0);

  return (
    <S.TimeStampDiv data-testid="lastTimeUpdate">
      <S.TimeStampParagraph
        data-tooltip-id="latestDateUpdatedId"
        data-tooltip-content={lastDateUpdatedOn}
        data-tooltip-place="left"
      >
        {`Updated: ${diffInHours}h ago`}
      </S.TimeStampParagraph>
      <Tooltip id="latestDateUpdatedId" />
    </S.TimeStampDiv>
  );
};

export default LatestCompanyListUpdate;
