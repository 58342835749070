import { paddings } from 'core/styles';
import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';

export const CompanyCellDiv = styled.div`
  border: 1px solid ${({ theme }) => (theme === darkTheme ? theme.colors.gray200 : theme.colors.gray800)};
  border-radius: 10px;
  padding: ${() => paddings.defaultPadding} ${() => paddings.largePadding};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
