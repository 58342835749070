import { Button, NavMenuDropdown } from 'hudskit-framework-react';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useUptimeNavigation } from 'core/hooks/use-uptime-navigation';
import * as S from './styled';
import { getUptimeMenu } from '../NavMenu/menu';

type Props = {
  isOpen: boolean;
  toggleOpen: () => void;
  children: ReactNode;
};

const MobileNavMenuDrawer = ({ isOpen, toggleOpen, children }: Props) => (
  <>
    {isOpen
      ? (
        <Button
          icon={{
            position: 'right',
            type: 'action.close',
          }}
          variant="transparent"
          onClick={toggleOpen}
        />
      ) : (
        <Button
          icon={{
            position: 'right',
            type: 'action.hamburger',
          }}
          variant="transparent"
          onClick={toggleOpen}
        />
      )}
    <S.NavigationDrawer
      $position="left"
      $visible={isOpen}
      data-testid="navigationDrawer"
    >
      {children}
    </S.NavigationDrawer>
  </>
);

const MobileNavMenu = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  const onMenuClickHandler = useCallback(() => {
    setMobileMenuOpen((open) => !open);
  }, []);

  const navMenu = useMemo(() => getUptimeMenu(), []);

  const { activeMenuId, onClickHandler } = useUptimeNavigation(navMenu.menu);
  return (
    <MobileNavMenuDrawer
      isOpen={mobileMenuOpen}
      toggleOpen={onMenuClickHandler}
    >
      <S.MobileMenuContainer>
        <NavMenuDropdown
          menu={getUptimeMenu().menu}
          activeMenuItemId={activeMenuId}
          onClick={onClickHandler}
        />
      </S.MobileMenuContainer>
    </MobileNavMenuDrawer>
  );
};

export default MobileNavMenu;
