import { setUserId, setUserProperties } from 'firebase/analytics';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { browserRouter, newBrowserRouter } from 'core/routes';
import { analytics, maintenanceScreenEnabled, navigationWithChildren } from 'core/config';
import { useRemoteConfig } from 'core/hooks/use-remote-config';
import MaintenanceScreen from 'feature/maintenance/components/MaintenanceScreen';
import PageLoader from 'core/components/PageLoader';
import ThemeWrapper from 'core/styles/ThemeWrapper';

const App = () => {
  const isRemoteConfigReady = useRemoteConfig();
  const router = maintenanceScreenEnabled
    ? <MaintenanceScreen /> : <RouterProvider router={navigationWithChildren ? newBrowserRouter : browserRouter} />;
  const user = useAppSelector((state) => state.auth.user);

  let userType: string | undefined;
  if (user?.type === 'dealer') {
    userType = 'dealer';
  } else if (user?.type === 'Employee') {
    userType = 'employee';
  } else {
    userType = undefined;
  }
  useEffect(() => {
    if (user) {
      setUserId(analytics, user.id);
      if (user.salesCompanyCodeAndCustomerNumber) {
        setUserProperties(analytics, {
          dealer_customer: user.salesCompanyCodeAndCustomerNumber,
        });
      }
      if (userType) {
        setUserProperties(analytics, {
          user_type: userType,
        });
        window.clarity('set', 'role', userType);
      }
    }
  }, [user, userType]);
  return <ThemeWrapper>{isRemoteConfigReady ? router : <PageLoader />}</ThemeWrapper>;
};

export default App;
