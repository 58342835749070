import { CompaniesSortOrder, CompaniesSorting, CompaniesSortingProps } from '@uptime-bff/api-contract';
import DropdownDrawer from 'core/components/DropdownDrawer';
import DropdownMenu, { DropdownMenuItemProps } from 'core/components/DropdownMenu/DropdownMenu';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { setSortingOnActiveSearch } from 'feature/companies/store';
import { Button } from 'hudskit-framework-react';
import { useCallback, useMemo, useState } from 'react';
import * as S from './styled';

type SortIconButtonType = {
  onClick?: () => void;
  sortingLabel?: string;
};

const SortIconButton = ({ onClick, sortingLabel }: SortIconButtonType) => (
  <S.SortMenuDiv>
    <S.SortMenuButtonDiv data-testid="sort">
      <Button
        variant="secondary"
        onClick={onClick}
        icon={{
          position: 'left',
          type: 'action.swap'
        }}
      />
    </S.SortMenuButtonDiv>
    <span>{sortingLabel}</span>
  </S.SortMenuDiv>
);

type SortMenuItem = {
  sortValue: CompaniesSorting;
  label: string;
};

const CompanySortMenu = () => {
  const dispatch = useAppDispatch();
  const activeSort = useAppSelector((state) => state.companies.companiesSorting);
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Companies);

  const sortingProps: SortMenuItem[] = useMemo(() => [
    {
      sortValue: {
        field: CompaniesSortingProps.Name,
        order: CompaniesSortOrder.Ascending,
      },
      label: t('COMPANIES:companiesTitle.company'),
    },
    {
      sortValue: {
        field: CompaniesSortingProps.CountryCodes,
        order: CompaniesSortOrder.Ascending,
      },
      label: t('COMPANIES:companiesTitle.country'),
    },
  ], [t]);

  const menuItems = useMemo(
    () => sortingProps.map((sortItem) => ({
      key: `${sortItem.sortValue.field}${sortItem.sortValue.order}`,
      label: sortItem.label,
      value: sortItem.sortValue,
    })),
    [sortingProps]
  );

  const [selected, setSelected] = useState<DropdownMenuItemProps<CompaniesSorting> | undefined>(
    menuItems.find((item) => item.value.field === activeSort?.field)
  );

  const onSelectedHandler = useCallback(
    (selectedItem: DropdownMenuItemProps<CompaniesSorting>) => {
      setSelected(selectedItem);
      dispatch(setSortingOnActiveSearch(selectedItem.value));
    },
    [dispatch]
  );
  return (
    <DropdownDrawer menu={<SortIconButton sortingLabel={selected?.label} />}>
      <DropdownMenu
        title={t('COMPANIES:companiesTitle.dropdownTitle')}
        items={menuItems}
        onSelected={onSelectedHandler}
        selected={selected}
      />
    </DropdownDrawer>
  );
};

SortIconButton.defaultProps = {
  onClick: undefined,
  sortingLabel: undefined,
};

export default CompanySortMenu;
