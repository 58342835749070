import { useTranslation } from 'react-i18next';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import * as S from './styled';

interface Props {
  error?: number | number[];
  errorDate?: string;
}

const AssetErrorCode = ({ error, errorDate }: Props) => {
  const { t } = useTranslation([InternationalizationNameSpace.RoboticMowerCodes, InternationalizationNameSpace.Dashboard]);
  const date = new Date(String(errorDate)).toLocaleString(
    'default',
    { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }
  ).replaceAll('/', '-');
  const errorCode = Array.isArray(error) ? error[0] ?? 0 : error ?? 0;

  const errorDescriptionWithErrorDate: string = t(
    `MOWER_ERROR_CODES:code.${errorCode}`,
    { errorDate: date, defaultValue: t('DASHBOARD:dashboard.error.unexpectedError') }
  );
  const errorDescription = t(
    `MOWER_ERROR_CODES:description.${errorCode}`,
    { defaultValue: t('DASHBOARD:dashboard.error.unexpectedError') }
  );
  const formattedErrorDescription = !errorDate ? errorDescription : errorDescriptionWithErrorDate;

  return (
    <S.ErrorCodesContainerDiv>
      <S.ErrorCodeSpan data-testid="errocodes">
        {`[${errorCode}] ${formattedErrorDescription}`}
      </S.ErrorCodeSpan>
    </S.ErrorCodesContainerDiv>
  );
};

AssetErrorCode.defaultProps = {
  error: undefined,
  errorDate: undefined,
};

export default AssetErrorCode;
