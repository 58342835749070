import { Card } from 'hudskit-framework-react';
import { ReactNode } from 'react';

type Props = {
  title:string,
  children: ReactNode
};
const FilterCard = ({ title, children }:Props) => (
  <Card>
    <h6>{title}</h6>
    {children}
  </Card>
);
export default FilterCard;
