import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { CompaniesDetailsResponse } from '@uptime-bff/api-contract';
import CompanyInfoCell from '../CompanyInfoCell';
import * as S from './styled';

type Props = {
  company: CompaniesDetailsResponse;
};

const CompaniesInfoAssetsCell = ({ company }: Props) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Companies);
  return (
    <S.CompanyInfoAssetsCell>
      <CompanyInfoCell id="totalAssetsCount" label={t('COMPANIES:companiesDetails.totalAssets')} value={company.totalAssets} />
      <CompanyInfoCell id="machinesCount" label={t('COMPANIES:companiesDetails.machines')} value={company.machines} />
      <CompanyInfoCell id="mowersCount" label={t('COMPANIES:companiesDetails.roboticMowers')} value={company.mowers} />
    </S.CompanyInfoAssetsCell>
  );
};

export default CompaniesInfoAssetsCell;
