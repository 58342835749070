import { useAppSelector } from 'core/hooks/use-app-selector';
import { setOidcNonce } from '../store';
import { useAuthHash } from './use-auth-hash';

const useAuthNonce = () => {
  const nonce = useAppSelector((state) => state.auth.oidcNonce);
  const { cryptographicHash } = useAuthHash(setOidcNonce, nonce);
  return { nonceCryptographicHash: cryptographicHash };
};

export { useAuthNonce };
