import { borderRadius, paddings } from 'core/styles';
import { FunctionalColor, getFunctionalColor, getFunctionalLightColor } from 'core/styles/variables';
import styled from 'styled-components';

type Props = {
  $status: FunctionalColor;
};

export const PillTagContainerDiv = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: ${() => paddings.mediumPadding};
  padding: ${() => paddings.smallPadding} ${({ $status }) => ($status === 'none' ? 0 : paddings.mediumPadding)};
  margin-bottom: ${() => paddings.smallPadding};
  border-color: ${({ theme, $status }) => getFunctionalColor(theme, $status)};
  background-color: ${({ theme, $status }) => getFunctionalLightColor(theme, $status)};
  border-radius: ${() => borderRadius.default};
  width: fit-content;
`;
