import React, { useEffect, useState } from 'react';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import ErrorCard from 'core/components/ErrorCard';
import GoogleMap from 'core/components/GoogleMap';
import { apiKeys } from 'core/config';
import { Loader } from 'hudskit-framework-react';
import { AssetMapItem } from '@uptime-bff/api-contract';
import { mapContainerStyle } from './styled';
import AssetsMapMarker from '../AssetsMapMarker';

const MapFallbackComponent = (status: Status) => {
  if (status === Status.FAILURE) {
    return <ErrorCard />;
  }
  return <Loader size="small" />;
};

type Props = {
  assets?: AssetMapItem[];
};
const MultipleAssetMap = ({ assets }: Props) => {
  const [map, setMap] = useState<google.maps.Map | undefined>();
  useEffect(() => {
    if (map) {
      const latLngBounds = new window.google.maps.LatLngBounds();
      if (assets && assets.length > 0) {
        for (const asset of assets) {
          if (asset.assetPosition) {
            latLngBounds.extend({ lat: asset.assetPosition.lat, lng: asset.assetPosition.lng });
          }
        }
        map.fitBounds(latLngBounds);
      }
    }
  }, [assets, map]);
  const onMapRenderHandler = (renderedMap: google.maps.Map) => {
    setMap(renderedMap);
  };
  return (
    <Wrapper apiKey={apiKeys.maps} render={MapFallbackComponent} libraries={['marker']}>
      <GoogleMap
        mapOptions={{
          center: { lng: 14.248514980735365, lat: 57.77192933865891 },
          zoom: 14,
          mapId: '3594ab97a2f4e43a',
          mapTypeId: 'satellite',
        }}
        containerStyle={mapContainerStyle}
        onMapRender={onMapRenderHandler}
      />
      {map
        && assets
        && assets.map((asset) => {
          if (!asset.assetPosition) {
            return null;
          }
          return (
            <AssetsMapMarker
              key={asset.id}
              map={map}
              id={asset.id}
              location={asset.assetPosition}
              name={asset.name}
              assetStatusSeverity={asset.mowerStatusSeverity}
              assetStatus={asset.mowerStatus}
            />
          );
        })}
    </Wrapper>
  );
};

MultipleAssetMap.defaultProps = {
  assets: undefined,
};

export default MultipleAssetMap;
