import ErrorCard from 'core/components/ErrorCard';
import { useGetAssetMarkersQuery } from 'feature/assets/api';
import MultipleAssetMap from 'feature/assets/components/MultipleAssetMap/MultipleAssetMap';
import AssetMapInfoWindow from 'feature/assets/components/AssetMapInfoWindow';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { getMarkersPollingInterval } from 'core/config/firebase/remote-config';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import * as S from './styled';

const AssetsMap = () => {
  const { data, isError } = useGetAssetMarkersQuery(undefined, { pollingInterval: getMarkersPollingInterval });
  const activeAssetId = useAppSelector((state) => state.assets.assetMapActiveAssetId);
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  return (
    <>
      {!activeAssetId && (
        <S.ActiveAssetInfoDiv>
          {t('ASSETS:map.mapFeatureConstraints')}
        </S.ActiveAssetInfoDiv>
      )}
      {activeAssetId && <AssetMapInfoWindow id={activeAssetId} />}
      {!isError && <MultipleAssetMap assets={data?.elements} />}
      {isError && <ErrorCard />}
    </>
  );
};
export default AssetsMap;
