import { device, paddings, typographyDesktop, typographyMobile } from 'core/styles/variables';
import { darkTheme } from 'hudskit-framework-react';
import styled, { css } from 'styled-components';

export const mapContainerStyle = css`
  min-height: 45svh;
  width: 100%;
  @media ${() => device.md} {
    height: 45svh;
    border-start-start-radius: 12px;
    border-start-end-radius: 12px;
  }
`;

export const NoPositionSpan = styled.span`
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray200 : theme.colors.gray600)};
  background-color:  ${({ theme }) => (theme === darkTheme ? theme.colors.gray600 : theme.colors.gray200)};
  font-size: ${() => typographyMobile.size.h5};
  text-align: center;
  z-index: 1;
  padding: ${() => paddings.largePadding} ${() => paddings.hugePadding};

  @media ${() => device.md} {
    font-size: ${() => typographyDesktop.size.default};
    padding: ${() => paddings.hugePadding};
  }
`;
