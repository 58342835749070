import React, { CSSProperties, useCallback, useMemo } from 'react';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'core/models';
import { clearFilters, setFilterByModelsOnActiveSearch, setFilterByStatusOnActiveSearch } from 'feature/assets/store';
import * as S from './styled';

export interface Props {
  keyName: string;
  color: string;
  text: string;
  num: number | string;
  borderColor?: string;
  model?: string[];
}

const LegendItem = ({ keyName, color, borderColor, text, num, model }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pillStyle = useMemo(() => {
    const style: CSSProperties = {
      backgroundColor: color,
    };

    if (borderColor) {
      style.border = `1px solid ${borderColor}`;
    }
    return style;
  }, [color, borderColor]);
  const onClickHandler = useCallback(() => {
    if (keyName) {
      dispatch(clearFilters());
      dispatch(setFilterByStatusOnActiveSearch([keyName]));
      dispatch(setFilterByModelsOnActiveSearch(model ?? []));
      navigate(RoutePaths.Assets);
    }
  }, [dispatch, keyName, model, navigate]);

  return (
    <S.LegendDiv data-testid="legend" onClick={onClickHandler}>
      <S.LegendLeftDiv data-testid={keyName}>
        <S.LegendPillDiv data-testid="legend-pill" style={pillStyle} />
        <S.LegendNameDiv data-testid="legend-name">
          <span>{text}</span>
        </S.LegendNameDiv>
      </S.LegendLeftDiv>
      <S.LegendRightDiv data-testid="legend-right">
        <S.LegendNumDiv data-testid="legend-num">{num}</S.LegendNumDiv>
      </S.LegendRightDiv>
    </S.LegendDiv>
  );
};

LegendItem.defaultProps = {
  borderColor: undefined,
  model: [],
};

export default LegendItem;
