import { GeoCoordinates } from '@uptime-bff/api-contract';
import { useState, useEffect } from 'react';

const useMapUrl = (assetPosition: GeoCoordinates | undefined, assetAddressURL: string): string => {
  const [mapUrl, setMapUrl] = useState<string>(assetAddressURL);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  useEffect(() => {
    if (!assetPosition) return;
    if (isIOS) {
      setMapUrl(`maps://maps.apple.com/maps?q=${assetPosition?.lat},${assetPosition?.lng}`);
    } else {
      setMapUrl(assetAddressURL);
    }
  }, [assetPosition, assetAddressURL, isIOS]);

  return mapUrl;
};

export default useMapUrl;
