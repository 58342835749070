import MapsMarker from 'core/components/MapsMarker';
import { MowerStatus, MowerStatusSeverity } from '@uptime-bff/api-contract';
import { getStatusColor } from 'feature/assets/utils';
import { useCallback } from 'react';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { setAssetMapActiveAssetId } from 'feature/assets/store';
import * as S from './styled';

type Props = {
  map: google.maps.Map;
  location: {
    lat: number;
    lng: number;
  };
  name: string;
  assetStatusSeverity?: MowerStatusSeverity;
  assetStatus: MowerStatus;
  id:string;
};
const AssetsMapMarker = ({ map, location, name, assetStatusSeverity, assetStatus, id }: Props) => {
  const statusColor = getStatusColor(assetStatus);

  const dispatch = useAppDispatch();

  const onClickHandler = useCallback(() => {
    dispatch(setAssetMapActiveAssetId(id));
  }, [dispatch, id]);

  return (
    // @ts-ignore
    <MapsMarker map={map} position={location} onClick={onClickHandler}>

      <S.StatusCircle title={name} color={assetStatusSeverity ?? statusColor} />

    </MapsMarker>
  );
};
AssetsMapMarker.defaultProps = {
  assetStatusSeverity: undefined,
};
export default AssetsMapMarker;
