import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useDeleteTokenMutation } from 'feature/auth/api';
import { logout } from 'feature/auth/store';
import { Button } from 'hudskit-framework-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { configAuth } from 'core/config';

const Logout = () => {
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Auth]);
  const [deleteToken, { isError, isSuccess }] = useDeleteTokenMutation();
  const logoutHandler = () => {
    void deleteToken();
  };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess || isError) {
      dispatch(logout());
      window.location.href = configAuth.oidcUrl
        .concat('/pkmslogout?filename=logout.html&targeturl=')
        .concat(encodeURIComponent(configAuth.oidcRedirectUrl.concat('?logout=true')));
    }
  }, [dispatch, isError, isSuccess, navigate]);
  return (
    <Button
      data-testid="utcLogoutButton"
      variant="primary"
      size="small"
      onClick={logoutHandler}
      icon={{ type: 'notification.locked', position: 'left' }}
    >
      {t('AUTH:logout.buttonText')}
    </Button>
  );
};
export default Logout;
