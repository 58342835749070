import { CompanyTableRow as Company } from '@uptime-bff/api-contract';
import { useSearchableCompanies } from 'feature/companies/hook/use-searchable-companies';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useCallback, useEffect, useRef, useState } from 'react';
import useIntersectionObserver from 'core/hooks/use-intersection-observer';
import ErrorCard from 'core/components/ErrorCard';
import { Loader } from 'hudskit-framework-react';
import { setTotalNumberOfDashboardWidgets } from 'feature/companies/store';
import { useGetTotalDashboardWidgetsQuery } from 'feature/companies/api';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import SearchNotFound from 'core/components/SearchNotFound/SearchNotFound';
import { useAppSelector } from 'core/hooks/use-app-selector';
import ErrorBanner from 'core/components/ErrorBanner';
import * as S from './styled';
import CompaniesFilters from '../CompaniesFilter';
import CompanyListHeader from '../CompanyListHeader/CompanyListHeader';
import CompanyListItem from '../CompanyListItem/CompanyListItem';

type Props = {
  isOpen: boolean,
  onCompanyItemClickHandler: (company: Company) => void;
  selectedCompanyId?: string;
};

const CompanyList = ({ isOpen, onCompanyItemClickHandler, selectedCompanyId }: Props) => {
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Companies, InternationalizationNameSpace.Core]);
  const [showErrorBanner, setShowErrorBanner] = useState(false);

  const observerTargetRef = useRef<HTMLDivElement | null>(null);
  const searchQuery = useAppSelector((state) => state.companies.companySearch?.query);
  const { data: getTotalWidgets, isError: isGetTotalWidgetsError, refetch } = useGetTotalDashboardWidgetsQuery();
  const { companies, isError, hasMoreCompanies, getNextPage, isFetching, loadCompanies, isSuccess } = useSearchableCompanies({
    take: 40,
  });
  const entry = useIntersectionObserver(observerTargetRef, { });
  const isLastItemVisible = !!entry?.isIntersecting;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (getTotalWidgets && isSuccess) {
      dispatch(setTotalNumberOfDashboardWidgets({ totalNumberOfDashboardWidgets: getTotalWidgets.totalNumberOfDashboardWidgets }));
    }
    refetch();
  }, [dispatch, getTotalWidgets, isSuccess, refetch]);

  useEffect(() => {
    (async () => {
      if (isLastItemVisible && hasMoreCompanies && !isFetching && !isError) {
        await getNextPage();
      }
    })();
  }, [getNextPage, hasMoreCompanies, isFetching, isLastItemVisible, isError]);

  const onRetryClickHandler = useCallback(() => {
    (async () => {
      refetch();
      await loadCompanies();
    })();
  }, [refetch, loadCompanies]);

  const showErrorBannerClickHandler = useCallback(() => {
    setShowErrorBanner(false);
  }, [setShowErrorBanner]);

  return (
    <>
      {showErrorBanner && (
        <S.BannerDiv data-testid="errorbanner">
          <ErrorBanner
            messageText={t('COMPANIES:companiesBanner.error')}
            dismissText={t('COMPANIES:companiesBanner.dismiss')}
            iconType="notification.error"
            iconColor="error"
            borderColor="error"
            dismissClickHandler={showErrorBannerClickHandler}
          />
        </S.BannerDiv>
      )}
      {(isError || isGetTotalWidgetsError) && (
        <ErrorCard onRetryClick={onRetryClickHandler}>
          {t('COMPANIES:rows.errorLoadingCompanies')}
        </ErrorCard>
      )}
      {!isLastItemVisible && isFetching && <Loader size="small" center />}
      <S.CompaniesListSection>
        <S.CompaniesListFilterDiv $isOpen={isOpen}>
          {isOpen && <CompaniesFilters />}
        </S.CompaniesListFilterDiv>
        <S.CompaniesListDiv>
          {searchQuery && companies.length === 0 ? <SearchNotFound /> : <CompanyListHeader />}
          {companies.map((company) => (
            <CompanyListItem
              key={company.id}
              company={{
                id: company.id,
                iamId: company.iamId,
                customerNumber: company.customerNumber,
                contactInfo: company.contactInfo,
                countryCode: company.countryCode,
                name: company.name,
                mowers: company.mowers,
                machines: company.machines,
                hasDashboardWidget: company.hasDashboardWidget,
              }}
              selectedCompanyId={selectedCompanyId}
              onClick={onCompanyItemClickHandler}
              isFetchingCompanyList={isFetching}
              setShowErrorBanner={setShowErrorBanner}
            />
          ))}

          {isFetching && hasMoreCompanies && <Loader size="medium" center />}
          {companies.length > 0 && <div ref={observerTargetRef} />}
          {companies.length > 0 && !hasMoreCompanies && !isFetching && (
            <S.ListItemCenteredDiv>{t('COMPANIES:rows.notMoreCompainesToLoad')}</S.ListItemCenteredDiv>
          )}
        </S.CompaniesListDiv>
      </S.CompaniesListSection>
    </>
  );
};

CompanyList.defaultProps = {
  selectedCompanyId: undefined,
};

export default CompanyList;
