import { Button } from 'hudskit-framework-react';
import { useCallback } from 'react';
import * as S from './styled';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const CompanyFiltersButton = ({ isOpen, setIsOpen }: Props) => {
  const onHideClickHandler = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  return (
    <S.FiltersButtonDiv $isOpen={isOpen}>
      <Button
        onClick={onHideClickHandler}
        variant="secondary"
        icon={{
          position: 'right',
          type: 'action.filter',
        }}
      />
    </S.FiltersButtonDiv>
  );
};

export default CompanyFiltersButton;
