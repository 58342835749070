import { Button, Loader } from 'hudskit-framework-react';
import { CompanyTableRow } from '@uptime-bff/api-contract';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useAddCompanyWidgetToDashboardMutation } from 'feature/companies/api';
import { numberOfCustomerWidgetsAllowed } from 'core/config';
import { useCallback, useEffect } from 'react';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { setTotalNumberOfDashboardWidgets, setWidgetToDashboard } from 'feature/companies/store';
import { Tooltip } from 'react-tooltip';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import useDebounce from 'core/hooks/use-debounce';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import * as S from './styled';

export interface Props {
  company: CompanyTableRow;
  isFetchingCompanyList: boolean;
  setShowErrorBanner: (value: boolean) => void;
}

const AddToDashboard = ({ company, isFetchingCompanyList, setShowErrorBanner }: Props) => {
  const dispatch = useAppDispatch();
  const hasCompanyWidget = useAppSelector((state) => state.companies.companiesList[company.id]?.hasDashboardWidget);
  const addedDashboardWidgetsCount = useAppSelector(
    (state) => state.companies.companyDashboardWidgets?.totalNumberOfDashboardWidgets
  ) ?? 0;
  const showMaxLimitReachedTT = addedDashboardWidgetsCount >= numberOfCustomerWidgetsAllowed && hasCompanyWidget;
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Companies);

  const [addCompanyToDashboard,
    { data: addedTotalWidgets, isSuccess, isError, isLoading, reset }] = useAddCompanyWidgetToDashboardMutation();

  const debouncedAddedTotalWidgets = useDebounce(addedTotalWidgets, 500);

  const onClickHandler = useCallback(() => {
    if (!isError && addedDashboardWidgetsCount <= numberOfCustomerWidgetsAllowed) {
      addCompanyToDashboard({ diamGroupId: company.iamId, companyName: company.name });
      dispatch(setWidgetToDashboard({ ...company, hasDashboardWidget: true }));

    }
  }, [addCompanyToDashboard, addedDashboardWidgetsCount, company, dispatch, isError]);

  useEffect(() => {
    if (isSuccess && debouncedAddedTotalWidgets?.totalNumberOfDashboardWidgets) {
      dispatch(
        setTotalNumberOfDashboardWidgets({
          totalNumberOfDashboardWidgets: debouncedAddedTotalWidgets.totalNumberOfDashboardWidgets,
        })
      );
      reset();
    }
  }, [company, debouncedAddedTotalWidgets, dispatch, isSuccess, reset]);

  useEffect(() => {
    if (isError) {
      dispatch(setWidgetToDashboard({ ...company, hasDashboardWidget: false }));
      setShowErrorBanner(true);
      reset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hasCompanyWidget, isError, reset, setShowErrorBanner]);

  return (
    <S.AddToDashboardDiv>
      {isLoading || isFetchingCompanyList ? (
        <S.LoaderDiv>
          <Loader size="small" center />
        </S.LoaderDiv>
      ) : (
        <div data-tooltip-id={!isError && !hasCompanyWidget ? 'addButtonCompanyId' : undefined}>
          <Button
            disabled={hasCompanyWidget || (addedDashboardWidgetsCount >= numberOfCustomerWidgetsAllowed)}
            onClick={onClickHandler}
            variant="secondary"
            icon={{
              position: 'left',
              type: `${!isError && hasCompanyWidget ? 'action.success' : 'action.add'}`,
            }}
            type="button"
          />
        </div>
      )}
      {showMaxLimitReachedTT && (
        <Tooltip id="addButtonCompanyId" data-testid="addButtonTooltip" place="top" className="toolTip">
          {t('COMPANIES:tooltips.disableAddButtonTooltip')}
        </Tooltip>
      )}
    </S.AddToDashboardDiv>
  );
};

export default AddToDashboard;
