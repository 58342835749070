import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { AssetDetailsResponse } from '@uptime-bff/api-contract';
import { getPurchaseDate } from 'feature/assets/utils';
import { useOutletContext } from 'react-router-dom';
import { navigationWithChildren } from 'core/config';
import AssetInfoCell from '../AssetInfoCell';

type Props = {
  assetDetails?: AssetDetailsResponse;
};

const AssetDetailsProductInfo = ({ assetDetails }: Props) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const asset: AssetDetailsResponse = useOutletContext();
  const purchaseDate = getPurchaseDate(navigationWithChildren ? asset : assetDetails);
  const assetResponse = navigationWithChildren ? asset : assetDetails;
  return (
    <>
      {assetResponse?.purchaseDate && (
        <AssetInfoCell
          label={t('ASSETS:details.labels.purchaseDate')}
          value={purchaseDate}
        />
      )}
      {assetResponse?.fwVersion && (
        <AssetInfoCell
          label={t('ASSETS:details.labels.firmwareVersion')}
          value={assetResponse.fwVersion}
        />
      )}
      {assetResponse?.serialNumber && (
        <AssetInfoCell
          label={t('ASSETS:details.labels.serialNumber')}
          value={assetResponse.serialNumber}
          copyIcon="action.copy"
        />
      )}
      {assetResponse?.iprId && (
        <AssetInfoCell
          label={t('ASSETS:details.labels.ipr')}
          value={assetResponse.iprId}
          copyIcon="action.copy"
        />
      )}
      {assetResponse?.pnc && (
        <AssetInfoCell
          label={t('ASSETS:details.labels.pnc')}
          value={assetResponse.pnc}
          copyIcon="action.copy"
        />
      )}
    </>
  );
};

export default AssetDetailsProductInfo;

AssetDetailsProductInfo.defaultProps = {
  assetDetails: undefined,
};
