import { getFunctionalColor, getFunctionalLightColor } from 'core/styles/variables';
import styled, { css } from 'styled-components';

export type Size = 'sm' | 'md' | 'lg';

export type CircleColor = 'caution' | 'error';
export type StatusColor = 'success' | 'error';
interface CircleProps {
  size?: Size;
  color?: CircleColor;
}

const getStatusCircleSizeInPx = (size?: Size) => {
  switch (size) {
    case 'lg':
      return '56px';
    case 'md':
      return '28px';
    case 'sm':
    default:
      return '14px';
  }
};
const getCricleSizeInPx = (size?: Size) => {
  switch (size) {
    case 'lg':
      return '164px';
    case 'md':
      return '82px';
    case 'sm':
    default:
      return '41px';
  }
};

const getImageContainerSizeInPx = (size?: Size) => {
  switch (size) {
    case 'lg':
      return '256px';
    case 'md':
      return '128px';
    case 'sm':
    default:
      return '64px';
  }
};

const centerAbsolutePosition = css`
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

export const CircleBackground = styled.span`
  ${centerAbsolutePosition}
  border-radius: 50%;
  border: 2px solid;
  position: absolute;
`;

export const IconDiv = styled.div`
  ${centerAbsolutePosition}
  position: absolute;
  border-radius: 50%;
  background-size: cover;
`;

export const AssetImage = styled.img`
  ${centerAbsolutePosition}
  position: absolute;
  width: 100%;
  object-fit: contain;
  font-size: 10pt;
  color: ${({ theme }) => theme.colors.onSurface};
`;

export const GrassImage = styled.img`
  ${centerAbsolutePosition}
  position: absolute;
  width: 100%;
  object-fit: contain;
`;

export const ImageContainer = styled.div<CircleProps>`
  position: relative;
  display: flex;
  height: ${({ size }) => getImageContainerSizeInPx(size)};
  width: ${({ size }) => getImageContainerSizeInPx(size)};
  min-height: ${({ size }) => getImageContainerSizeInPx(size)};
  min-width: ${({ size }) => getImageContainerSizeInPx(size)};
  ${CircleBackground} {
    width: ${({ size }) => getCricleSizeInPx(size)};
    height: ${({ size }) => getCricleSizeInPx(size)};
    min-width: ${({ size }) => getCricleSizeInPx(size)};
    min-height: ${({ size }) => getCricleSizeInPx(size)};
    border-color: ${({ theme, color }) => getFunctionalColor(theme, color)};
    background-color: ${({ theme, color }) => getFunctionalLightColor(theme, color, false)};
    &.marker {
      &::after {
        position: absolute;
        margin: auto;
        content: '';
        width: 0px;
        height: 0px;
        bottom: -26px;
        right: 0;
        left: 0;
        border: 10px solid transparent;
        border-top: 17px solid ${({ theme, color }) => getFunctionalColor(theme, color)};
      }
    }
  }
  ${IconDiv} {
    width: ${({ size }) => getStatusCircleSizeInPx(size)};
    height: ${({ size }) => getStatusCircleSizeInPx(size)};
    min-width: ${({ size }) => getStatusCircleSizeInPx(size)};
    min-height: ${({ size }) => getStatusCircleSizeInPx(size)};
    left: ${({ size }) => `calc(${getCricleSizeInPx(size)} - 4px)`};
    top: ${({ size }) => `calc(${getCricleSizeInPx(size)} - 16px)`};
  }
  ${GrassImage} {
    top: ${({ size }) => `calc(${getCricleSizeInPx(size)} - 60px)`};
  }
`;
