import { paddings } from 'core/styles';
import { FunctionalColor, device, getFunctionalColor, getFunctionalLightColor, typographyMobile } from 'core/styles/variables';
import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';

interface ListItemOptions {
  $active?: boolean;
}

interface ListItemStatusOptions {
  $status: FunctionalColor;
}

export const ThumbnailDiv = styled.div`
  display: none;

  @media ${() => device.xl} {
    grid-area: image;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 60px;
    }
  }
`;

export const ItemBodyDiv = styled.div`
  grid-area: description;
  min-width: 0;
  display: flex;
  flex-direction: column;
`;

export const MowerDetailsDiv = styled.div`
  flex-direction: column;
  display: flex;
`;

export const CompanyDetailsDiv = styled.div`
  display: none;

  @media ${() => device.xl} {
    flex-direction: column;
    display: flex;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
export const ItemTitleSpan = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
  max-width: 200px;
  flex-grow: 1;
`;

export const ItemTitleContainer = styled.span`
  font-weight: bold;
  display: flex;
  color: ${({ theme }) => theme.colors.onSurface};
  max-height: 2em;
  line-height: 1em;
`;
export const ItemSubtextSpan = styled.span`
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.onSurface};
  max-height: 1em;
  line-height: 1em;
`;

export const ItemSubtextSmallSpan = styled.span`
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.onBackground};
  line-height: 1;
  font-size: ${typographyMobile.size.small}; 
`;

export const ItemStatusDiv = styled.div`
  display: grid;
  grid-template-columns: 25px 10px 125px;
  grid-row: 1 / 2;
  align-items: center;
  gap: ${() => paddings.mediumPadding};
  justify-content: end;
`;

export const ListItem = styled.article<ListItemOptions>`
  grid-template-areas:
    'description status';
  grid-template-columns: 1fr 1fr;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, $active }) => ($active ? theme.colors.secondary : 'inherit')};
  padding: ${paddings.mediumPadding};
  border-top: 1px solid ${({ theme }) => (theme === darkTheme ? '#3D3D3D' : '#F0F0F0')};
  border-left: 1px solid ${({ theme }) => theme.colors.surface};
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    cursor: pointer;
  }
  // this is better but giving issues on unit test for now
  //@container (min-width: 490px) {
  @media ${() => device.xl} {
    grid-template-areas: 'image description status';
    grid-template-columns: 70px 1fr minmax(min-content, 230px);
    padding: ${paddings.smallPadding};

    ${ItemStatusDiv} {
      padding: ${() => paddings.defaultPadding};
    }
    ${ItemBodyDiv} {
      display: grid;
      gap: ${() => paddings.defaultPadding};
      grid-template-columns: 1fr 1fr;
    }
  }
`;

export const ItemStatusSpan = styled.span`
  padding: ${() => paddings.tinyPadding};
  justify-self: start;
`;
export const StatusCircle = styled.span<ListItemStatusOptions>`
  display: block;
  border-radius: 50%;
  border: 2px solid;
  background-size: cover;
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  grid-column: 2/3;
  border-color: ${({ theme, $status }) => getFunctionalColor(theme, $status)};
  background-color: ${({ theme, $status }) => getFunctionalLightColor(theme, $status)};
`;
