import { FC, ReactNode } from 'react';
import {
  ThemeWrapper as HudskitThemeWrapper,
} from 'hudskit-framework-react';
import { usePrefersColorScheme } from 'core/hooks/use-prefers-color-scheme';
import { UptimeCenterGlobalStyle } from './GlobalStyles';

type Props = {
  children: ReactNode;
};

export const ThemeWrapper: FC<Props> = ({ children }) => {
  const theme = usePrefersColorScheme();
  return (
    <HudskitThemeWrapper theme={theme}>
      <UptimeCenterGlobalStyle />
      {children}
    </HudskitThemeWrapper>
  );
};

export default ThemeWrapper;
