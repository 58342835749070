import { useEffect, useRef } from 'react';
import { CSSProp } from 'styled-components';
import * as S from './styled';

type Props = {
  mapOptions: google.maps.MapOptions;
  containerStyle?: CSSProp;
  onMapRender?: (map:google.maps.Map)=>void
};
const GoogleMap = ({
  mapOptions,
  containerStyle,
  onMapRender,
}: Props) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      const map = new window.google.maps.Map(ref.current, mapOptions);
      onMapRender?.(map);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.MapContainerDiv ref={ref} data-testid="map" id="map" $css={containerStyle} />
  );
};
GoogleMap.defaultProps = {
  containerStyle: undefined,
  onMapRender: undefined,
};
export default GoogleMap;
