import PageLoader from 'core/components/PageLoader';
import RootLayout from 'core/layout/RootLayout';
import { RoutePaths } from 'core/models';
import NotFound from 'core/pages/NotFound';
import AssetDetails from 'feature/assets/components/AssetDetails';
import Assets from 'feature/assets/pages/Assets';
import AssetsMap from 'feature/assets/pages/AssetsMap';
import Callback from 'feature/auth/pages/Callback';
import Login from 'feature/auth/pages/Login';
import TokenInfo from 'feature/auth/pages/TokenInfo';
import CompaniesDetails from 'feature/companies/components/CompaniesDetails';
import Companies from 'feature/companies/pages/Companies';
import Dashboard from 'feature/dashboard/pages/Dashboard';
import { ComponentType, ReactNode, Suspense } from 'react';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import AssetDetailsProductInfo from 'feature/assets/components/AssetDetailsProductInfo';
import AssetDetailsContact from 'feature/assets/components/AssetDetailsContact';
import ErrorBoundary from './components/ErrorBoundary';

export enum UserRoutes {
  UserLoginCallback = 'callback',
}

const componentWithSuspenseAndErrorBoundary = (Component: ComponentType, fallback: ReactNode = <>...</>) => (
  <Suspense fallback={fallback}>
    <ErrorBoundary>
      <Component />
    </ErrorBoundary>
  </Suspense>
);

const routes: RouteObject[] = [
  {
    path: '/',
    element: <RootLayout />,
    id: 'root',
    children: [
      {
        path: RoutePaths.Assets,
        element: componentWithSuspenseAndErrorBoundary(Assets),
      },
      {
        path: RoutePaths.Dashboard,
        element: componentWithSuspenseAndErrorBoundary(Dashboard),
      },
      {
        path: RoutePaths.AssetsMap,
        element: componentWithSuspenseAndErrorBoundary(AssetsMap),
      },
      {
        path: RoutePaths.Companies,
        element: componentWithSuspenseAndErrorBoundary(Companies),
      },
      {
        path: RoutePaths.UserInfo,
        element: componentWithSuspenseAndErrorBoundary(TokenInfo),
      },
    ],
  },
  {
    path: RoutePaths.UserLogin,
    element: componentWithSuspenseAndErrorBoundary(Login, <PageLoader />),
    children: [
      {
        path: UserRoutes.UserLoginCallback,
        element: <Callback />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

const routesNew: RouteObject[] = [
  {
    path: '/',
    element: <RootLayout />,
    id: 'root',
    children: [
      {
        path: RoutePaths.Dashboard,
        element: componentWithSuspenseAndErrorBoundary(Dashboard),
      },
      {
        path: RoutePaths.Assets,
        element: componentWithSuspenseAndErrorBoundary(Assets),
        children: [
          {
            path: RoutePaths.Details,
            element: componentWithSuspenseAndErrorBoundary(AssetDetails),
            children: [
              {
                path: RoutePaths.AssetDetailProductInfomation,
                element: componentWithSuspenseAndErrorBoundary(AssetDetailsProductInfo),
              },
              {
                path: RoutePaths.AssetDetailContacts,
                element: componentWithSuspenseAndErrorBoundary(AssetDetailsContact),
              }
            ]
          },
        ]
      },
      {
        path: RoutePaths.Companies,
        element: componentWithSuspenseAndErrorBoundary(Companies),
        children: [
          {
            path: RoutePaths.Details,
            element: componentWithSuspenseAndErrorBoundary(CompaniesDetails),
          }
        ]
      },
      {
        path: RoutePaths.AssetsMap,
        element: componentWithSuspenseAndErrorBoundary(AssetsMap),
      },
      {
        path: RoutePaths.UserInfo,
        element: componentWithSuspenseAndErrorBoundary(TokenInfo),
      },
    ],
  },
  {
    path: RoutePaths.UserLogin,
    element: componentWithSuspenseAndErrorBoundary(Login, <PageLoader />),
    children: [
      {
        path: UserRoutes.UserLoginCallback,
        element: <Callback />,
      },
    ],
  },
  {
    path: RoutePaths.NotFound,
    element: <NotFound />,
  },
];
const browserRouter = createBrowserRouter(routes);
const newBrowserRouter = createBrowserRouter(routesNew);

export { browserRouter, routes, newBrowserRouter, routesNew };
