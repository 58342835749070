import { paddings } from 'core/styles';
import { device } from 'core/styles/variables';
import styled from 'styled-components';

export const infoDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${() => paddings.defaultPadding};
`;

export const CompanyTitleH2 = styled.h2`
  padding: ${() => paddings.hugePadding} 0;
`;

export const CompanyContentDiv = styled.div`
  display: grid;
  gap: ${() => paddings.smallPadding};
  grid-template-columns: 1fr;
  @media ${() => device.lg}{
    grid-template-columns: repeat(2, 1fr);
  }
`;
