import { device, paddings, typographyMobile } from 'core/styles/variables';
import { zFloatingPanels } from 'core/styles/z-index';
import styled from 'styled-components';

type Props = {
  $isMobileView?: boolean;
  $isCopied?: boolean;
  $hasAddress?: boolean;
};

export const AssetDetailsAddressContainerDiv = styled.div<Props>``;

export const Tooltip = styled.div`
  .tooltip {
    z-index: ${zFloatingPanels};
    font-size: ${typographyMobile.size.tiny};
    padding: ${paddings.smallPadding};
  }
`;

export const AssetAddressSpan = styled.span`
  padding: 0 ${paddings.smallPadding};
  color: ${({ theme }) => theme.colors.gray400};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MapIconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3px;
`;

export const MapsAnchor = styled.a`
  display: flex;
  align-items: start;
  gap: ${paddings.smallPadding};
  font-size: ${typographyMobile.size.small};
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.brand110};
`;

export const TextWrapperDiv = styled.div`
  text-transform: uppercase;
  font-size: ${typographyMobile.size.small};
  white-space: nowrap;
`;

export const InnerLeftContentDiv = styled.div<Props>`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.gray400};
  max-width: fit-content;
  cursor: ${({ $isCopied, $hasAddress }) => !$isCopied && $hasAddress && 'pointer'};
  font-size: ${typographyMobile.size.small};
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: ${paddings.mediumPadding};
`;

export const InnerRightContentDiv = styled.div`
  display: flex;
  align-content: center;
  gap: ${paddings.smallPadding};
  color: ${({ theme }) => theme.colors.brand110};
`;

export const AddressLineGridWrapperDiv = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: ${zFloatingPanels};
  align-items: end;

  @media ${device.sm} {
    display: grid;
    grid-template-columns: 1fr auto;
  }
`;
