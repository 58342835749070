import { paddings } from 'core/styles';
import { styled } from 'styled-components';

export const SortMenuDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100px;
  padding-left: ${() => paddings.smallPadding};
`;

export const SortMenuButtonDiv = styled.div`
  transform: rotate(90deg);
`;
