/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type LayoutProps = {
  isOpen: boolean
};

export const defaultState: LayoutProps = {
  isOpen: false
};

const slice = createSlice({
  name: 'layout',
  initialState: defaultState,
  reducers: {
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  },
});

export const {
  setIsOpen
} = slice.actions;
export default slice.reducer;
