import i18next from 'i18next';

const convertLanguageCodeToLanguage = (languageCode: string): string | undefined => {
  if (!languageCode) {
    return undefined;
  }
  const language = new Intl.DisplayNames([i18next.language ?? 'en'], { type: 'language' });
  return language.of(languageCode);
};

export { convertLanguageCodeToLanguage };
