import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { RoutePaths, TranslationKeys } from 'core/models';
import { Link } from 'react-router-dom';

interface NavMenuItemProps {
  id: string,
  path: RoutePaths;
  labelKey:TranslationKeys
}
const NavMenuItem = ({ id, path, labelKey }:NavMenuItemProps) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Core);
  return (
    <Link id={id} style={{ all: 'unset' }} to={path}>
      { t(labelKey) }
    </Link>
  );
};
export default NavMenuItem;
