import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useExchangeTokenMutation } from 'feature/auth/api';
import { useAuthNonce, useAuthState } from 'feature/auth/hooks';
import { login, setLoggedOut } from 'feature/auth/store';
import { Loader } from 'hudskit-framework-react';
import { useEffect } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { RoutePaths } from 'core/models';
import { configAuth } from 'core/config/environment-variables';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { isApiResponse } from 'core/api';
import * as S from './styled';

const Callback = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Auth);
  const [exchangeToken, { isLoading, isSuccess, data, isError, error }] = useExchangeTokenMutation();
  const state = searchParams.get('state');
  const code = searchParams.get('code');
  const loggedOut = searchParams.get('logout');

  const errorIsam = searchParams.get('error');
  const { nonceCryptographicHash } = useAuthNonce();
  const { stateCryptographicHash } = useAuthState();
  const isOidcCallbackValid = state && code && nonceCryptographicHash && state === stateCryptographicHash;
  useEffect(() => {
    if (isOidcCallbackValid) {
      exchangeToken({
        code: code ?? '',
        nonce: nonceCryptographicHash ?? 'nonceCryptographicHash',
        redirectUri: configAuth.oidcRedirectUrl,
      });
    }
  }, [code, exchangeToken, isOidcCallbackValid, nonceCryptographicHash, searchParams, stateCryptographicHash]);

  useEffect(() => {
    if (loggedOut) {
      dispatch(setLoggedOut());
    }
  }, [dispatch, loggedOut]);

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(login(data));
      navigate(RoutePaths.Dashboard);
    }
  }, [data, dispatch, isSuccess, navigate]);

  return (
    <S.CallbackWrapper>
      {isLoading && (
        <S.LoaderWrapper data-testid="loading-auth">
          <Loader size="medium" />
        </S.LoaderWrapper>
      )}
      {isError && isApiResponse(error) && error.status === 403 && <S.ErrorSpan>{t(error.data.detail)}</S.ErrorSpan>}
      {errorIsam && <S.ErrorSpan>{t('AUTH:api.error.generic')}</S.ErrorSpan>}
      {
        // place holder to be changed to a alert dialog later on (right now will not be visible due to the redirect)
        isError && isApiResponse(error) && error.status === 401 && (
          <S.ErrorSpan>{t('AUTH:api.error.generic')}</S.ErrorSpan>
        )
      }
      {isError && isApiResponse(error) && error.status !== 403 && <Navigate to="/auth" replace />}
    </S.CallbackWrapper>
  );
};
export default Callback;
