import PillTag from 'core/components/PillTag';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { AssetListItem as Asset } from '@uptime-bff/api-contract';
import { getStatusColor } from 'feature/assets/utils';
import { useMemo } from 'react';
import i18next from 'i18next';
import { Icon } from 'hudskit-framework-react';
import * as S from './styled';
import AssetThumbnail from '../AssetThumbnail';
import AssetErrorCode from '../AssetErrorCode';

type Props = {
  asset: Asset;
  active: boolean;
  onClick?: (asset: Asset) => void;
};
const AssetListItem = ({ asset, active, onClick }: Props) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const status = asset.mowerStatus ? t(`ASSETS:mowerStatus.${asset.mowerStatus}`) : '';
  const country = useMemo(
    () =>
      (asset.country?.code
        ? new Intl.DisplayNames([i18next.language ?? 'en'], { type: 'region' }).of(asset.country?.code)
        : ''),
    [asset.country?.code]
  );

  return (
    <S.ListItem id="assetDetailsList" $active={active} key={asset.id} onClick={() => onClick?.(asset)}>
      <S.ThumbnailDiv>
        <AssetThumbnail
          imageSrc={asset.thumbnail}
          color={asset.mowerStatusSeverity}
          key={asset.id}
          imageAlt={asset.model}
        />
      </S.ThumbnailDiv>
      <S.ItemBodyDiv>
        <S.MowerDetailsDiv>
          <S.ItemTitleContainer>
            <S.ItemTitleSpan id="assetName">{asset.name}</S.ItemTitleSpan>
          </S.ItemTitleContainer>
          <S.ItemSubtextSpan id="assetModel">{asset.model}</S.ItemSubtextSpan>
          <S.ItemSubtextSmallSpan id="serialNumber">
            {asset.serialNumber && `${t('ASSETS:fieldLabels.serialNumber')}: ${asset.serialNumber}`}
          </S.ItemSubtextSmallSpan>
        </S.MowerDetailsDiv>
        <S.CompanyDetailsDiv>
          <S.ItemSubtextSpan id="companyName">{asset.company}</S.ItemSubtextSpan>
          {asset.country && (
            <S.ItemSubtextSmallSpan id="country">
              {country}
            </S.ItemSubtextSmallSpan>
          )}
        </S.CompanyDetailsDiv>
      </S.ItemBodyDiv>
      <S.ItemStatusDiv id="disconnected">
        {!asset.online && <Icon type="product.cloud_disabled" size="medium" />}
        {asset.mowerStatusSeverity ? (
          <PillTag color={asset.mowerStatusSeverity}>{status}</PillTag>
        ) : (
          asset.mowerStatus
          && (
            <>
              <S.StatusCircle $status={getStatusColor(asset.mowerStatus)} />
              <S.ItemStatusSpan id="assetStatus">{status}</S.ItemStatusSpan>
            </>
          )
        )}
        {asset.mowerStatus === 'error'
          && <AssetErrorCode error={asset.error} errorDate={asset.errorDate} />}
      </S.ItemStatusDiv>
    </S.ListItem>
  );
};

AssetListItem.defaultProps = {
  onClick: undefined,
};

export default AssetListItem;
