import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'core/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import * as Sentry from '@sentry/react';
import { configApp } from 'core/config';
import { registerSW } from 'virtual:pwa-register';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'core/config/internationalization';

Sentry.init({
  dsn: 'https://b010eaca2133ebc7b76e178e7e3f6af6@o33481.ingest.sentry.io/4506075995570176',
  environment: configApp.env,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        'https://web.local-uptime.com',
        'https://api.dev-uptime.dss.husqvarnagroup.com',
        'https://api.qa-uptime.dss.husqvarnagroup.com',
        'https://api-uptimecenter.husqvarna.com',
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: configApp.env === 'dev' ? 1.0 : 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: configApp.env !== 'dev-local',
});
const container = document.getElementById('root')!;

registerSW({ immediate: true });
const root = createRoot(container);
const persistor = persistStore(store);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

reportWebVitals();
