import { useRef } from 'react';
import Draggable from 'react-draggable';
import { Icon } from 'hudskit-framework-react';
import * as S from './styled';

interface Props {
  onClick: () => void;
}

const FeedbackButton = ({ onClick }: Props) => {
  const draggableRef = useRef(null);
  return (
    <Draggable nodeRef={draggableRef}>
      <S.Button id="email_btn" ref={draggableRef} onClick={onClick}>
        <Icon type="action.email_outlined" />
      </S.Button>
    </Draggable>
  );
};
export default FeedbackButton;
