import { paddings } from 'core/styles';
import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';

export const ErrorCodesContainerDiv = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 4;
  display: flex;
  gap: ${() => paddings.smallPadding}; 
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray300 : theme.colors.gray500)};
`;

export const ErrorCodeSpan = styled.span`
  font-weight: bold;
`;
