import { BaseQueryApi, FetchArgs, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { configBff } from 'core/config/environment-variables';
import { store } from 'core/store';

import { logout, setLoggedOut } from 'feature/auth/store';

const mutex = new Mutex();
const baseQueryAuth = () => {
  const baseQuery = fetchBaseQuery({
    baseUrl: configBff.apiUrl,
    credentials: 'include',
  });
  return async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const refreshResult = await baseQuery({ method: 'PATCH', url: 'token' }, api, extraOptions);
          if (refreshResult.meta?.response?.status === 200) {
            return await baseQuery(args, api, extraOptions);
          }
          store.dispatch(logout());
          store.dispatch(setLoggedOut());
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }
    return result;
  };
};

export enum TagTypes {
  Companies = 'Companies',
  CustomerWidgets = 'CustomerWidgets',
}
export const uptimeApi = createApi({
  reducerPath: 'uptimeApi',
  baseQuery: baseQueryAuth(),
  // To be extended on separate files https://redux-toolkit.js.org/rtk-query/usage/code-splitting
  endpoints: () => ({}),
  tagTypes: [TagTypes.Companies, TagTypes.CustomerWidgets],
});
