import { Address } from '@uptime-bff/api-contract';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { convertCountryCodeToCountryName } from 'feature/companies/components/CompaniesDetails/CompaniesDetails';

export const useAddressLine = (address: Address | undefined) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const addressKeys = ['street', 'streetNumber', 'postalTown', 'postalCode', 'countryCode'];

  function hasKeys(obj: Address, targets: string[]) {
    return targets.every((target) => Object.prototype.hasOwnProperty.call(obj, target));
  }

  if (address && hasKeys(address, addressKeys)) {
    const countryName = convertCountryCodeToCountryName(address?.countryCode);
    return `${address?.street} ${address?.streetNumber}, ${address?.postalCode} ${address?.postalTown}, ${countryName}`;
  }
  return t('ASSETS:addressline.missingAddress');
};
