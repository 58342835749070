import { Navigate, Outlet } from 'react-router-dom';
import { Button, Card } from 'hudskit-framework-react';
import Logo from 'core/components/Logo/Logo';
import husqvarnaLogo from 'images/logos/husqvarna-logo-text.svg';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { useLoginUrl } from 'feature/auth/hooks';
import { RoutePaths } from 'core/models';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import * as S from './styled';

const Login = () => {
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Core, InternationalizationNameSpace.Auth]);
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const { loginUrl } = useLoginUrl();
  const handleSignInOnClick = () => {
    window.location.assign(loginUrl);
  };

  if (isAuthenticated) {
    return <Navigate to={RoutePaths.Dashboard} replace />;
  }
  return (
    <S.Main>
      <S.Aside>
        <S.Header>
          <S.HeadingContainer>
            <Logo size={200} />
          </S.HeadingContainer>
        </S.Header>
        <S.MiddleSection>
          <Card>
            <S.CardContent>
              <S.LoginHeading id="welcome_header">{t('AUTH:login.welcome')}</S.LoginHeading>
              <Button
                type="button"
                size="large"
                variant="primary"
                onClick={handleSignInOnClick}
              >
                <S.SignInButtonContent id="signin_btn">{t('AUTH:login.signIn')}</S.SignInButtonContent>
              </Button>
              <span id="login_info">{t('AUTH:login.redirectMessage')}</span>
            </S.CardContent>
          </Card>
          <Outlet />
        </S.MiddleSection>
        <S.Footer>
          <span id="copyright_info">{t('CORE:footer.copyright')}</span>
        </S.Footer>
      </S.Aside>
      <S.BackgroundSection>
        <S.BlueSquare>
          <img id="husqvarna_logo" src={husqvarnaLogo} alt={t('CORE:logo.logoDesc')} />
        </S.BlueSquare>
      </S.BackgroundSection>
    </S.Main>
  );
};
export default Login;
