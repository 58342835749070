import styled from 'styled-components';
import { breakpoints, ThemeColor } from 'hudskit-framework-react';
import { paddings } from 'core/styles/GlobalStyles';

export const ErrorWrapperDiv = styled.div`
  margin: ${paddings.smallPadding} 0;
`;

export const IconWrapperDiv = styled.span`
  width: 24px;
  margin-bottom: -4px;
  margin-left: ${paddings.smallPadding};
  margin-right: ${paddings.defaultPadding};
  @media (min-width: ${breakpoints.l}px) {
    margin-right: ${paddings.defaultPadding};
  }
`;

export const BannerDiv = styled.div<{ borderColor: ThemeColor }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${paddings.tinyPadding} ${paddings.smallPadding};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.surface};
  border: 1px solid ${({ theme, borderColor }) => theme.colors[borderColor]};
  border-radius: 12px;
  min-height: 45px;
`;

export const MessageWrapperDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  :last-child {
    margin-right: 8px;
  }
`;

export const DefaultMessageDiv = styled.div`
  color: ${({ theme }) => theme.colors.onSurface};
`;

export const ActionButtonDiv = styled.div`
  display: flex;
  align-items: center;
  & button {
    color: ${({ theme }) => theme.colors.brand110};
  }
  > :not(:last-child) {
    margin-right: ${paddings.defaultPadding};
  }
  margin-left: ${paddings.defaultPadding};
`;
