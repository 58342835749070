import { useEffect, useMemo, useState } from 'react';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import ErrorCard from 'core/components/ErrorCard';
import GoogleMap from 'core/components/GoogleMap';
import { apiKeys } from 'core/config';
import { Loader } from 'hudskit-framework-react';
import { MowerStatus, MowerStatusSeverity } from '@uptime-bff/api-contract';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { mapContainerStyle } from './styled';
import AssetListMarker from '../AssetListMarker';
import * as S from './styled';

export const MapFallbackComponent = (status: Status) => {
  if (status === Status.FAILURE) {
    return <ErrorCard />;
  }
  return <Loader size="small" />;
};

type Props = {
  location?: {
    lat: number;
    lng: number;
  };
  assetName: string;
  assetThumbnail: string;
  assetStatusSeverity?: MowerStatusSeverity;
  assetStatus: MowerStatus;
};
const SingleAssetMap = ({ location, assetName, assetThumbnail, assetStatusSeverity, assetStatus }: Props) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const [map, setMap] = useState<google.maps.Map | undefined>();
  const validLocation = useMemo(
    () => location?.lat && location.lng && !(Math.abs(location.lat) < 1 && Math.abs(location.lng) < 1),
    [location?.lat, location?.lng],
  );
  const onMapRenderHandler = (renderedMap: google.maps.Map) => {
    setMap(renderedMap);
  };
  useEffect(() => {
    if (location && map) {
      map.setCenter(location);
      map.setTilt(45);
    }
  }, [location, map]);
  return (
    <Wrapper apiKey={apiKeys.maps} render={MapFallbackComponent} libraries={['marker']}>
      <GoogleMap
        mapOptions={{
          center: location,
          zoom: validLocation ? 18 : 9,
          mapId: '3594ab97a2f4e43a',
          mapTypeId: 'satellite',
        }}
        containerStyle={mapContainerStyle}
        onMapRender={onMapRenderHandler}
      />
      {map && (
        <>
          {!location && <S.NoPositionSpan>{t('ASSETS:map.noPosition')}</S.NoPositionSpan>}
          <AssetListMarker
            map={map}
            location={location}
            name={assetName}
            thumbnail={assetThumbnail}
            assetStatusSeverity={assetStatusSeverity}
            assetStatus={assetStatus}
          />
        </>
      )}
    </Wrapper>
  );
};

SingleAssetMap.defaultProps = {
  assetStatusSeverity: undefined,
  location: undefined
};

export default SingleAssetMap;
