import { paddings } from 'core/styles';
import { device } from 'core/styles/variables';
import styled from 'styled-components';

export const DashboardContentDiv = styled.div`
  margin: ${() => paddings.defaultPadding};
`;

export const GridDiv = styled.div`
  padding: 0 ${paddings.smallPadding};
  background-color: ${({ theme }) => theme.colors.background};
  gap: 0  ${paddings.defaultPadding};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;

export const HeaderWidgetDiv = styled.div`
  grid-column: 1 / 13;
  grid-row: 1;
`;

export const StatusWidgetContainerDiv = styled.div`
  grid-column: 1 / 13;
  grid-row: 2;

  @media ${device.md} {
    grid-column: 1 / 7;
    grid-row: 2;
  };

  @media ${device.lg} {
    grid-column: 1 / 5;
  };
`;

export const CeoraStatusWidgetContainerDiv = styled.div`
  grid-column: 1 / 13;
  grid-row: 3;

  @media ${device.md} {
    grid-column: 7 / 13;
    grid-row: 2;
  }

  @media ${device.lg} {
    grid-column: 5 / 9;
  }
`;
