import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import auth from 'feature/auth/locales/en.json';
import core from 'core/locales/en.json';
import maintenance from 'feature/maintenance/locales/en.json';
import ICU from 'i18next-icu';
import assets from 'feature/assets/locales/en.json';
import companies from 'feature/companies/locales/en.json';
import feedback from 'feature/feedback/locales/en.json';
import dashboard from 'feature/dashboard/locales/en.json';
import roboticmower from 'feature/roboticmower/locale/en.json';
import { InternationalizationNameSpace } from '../models/internationalization-namespace';

export const defaultNS = InternationalizationNameSpace.Core;

export const resources = {
  en: {
    [InternationalizationNameSpace.Auth]: auth,
    [InternationalizationNameSpace.Core]: core,
    [InternationalizationNameSpace.Maintenance]: maintenance,
    [InternationalizationNameSpace.Assets]: assets,
    [InternationalizationNameSpace.Companies]: companies,
    [InternationalizationNameSpace.Feedback]: feedback,
    [InternationalizationNameSpace.Dashboard]: dashboard,
    [InternationalizationNameSpace.RoboticMowerCodes]: roboticmower,
  },
} as const;

i18next.use(initReactI18next).use(ICU).init({
  lng: 'en',
  ns: [
    InternationalizationNameSpace.Auth,
    InternationalizationNameSpace.Core,
    InternationalizationNameSpace.Maintenance,
    InternationalizationNameSpace.Assets,
    InternationalizationNameSpace.Companies,
    InternationalizationNameSpace.Dashboard,
    InternationalizationNameSpace.RoboticMowerCodes,
  ],
  defaultNS,
  resources,
});
