import { useAppSelector } from 'core/hooks/use-app-selector';
import {
  clearFuzzySearchOnActiveSearch,
  setFilterByIdOnActiveSearch,
  setFuzzySearchField,
  setFuzzySearchQuery,
} from 'feature/assets/store';
import { SearchBar } from 'hudskit-framework-react';
import { useCallback, ChangeEvent, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AssetsSearchProps } from '@uptime-bff/api-contract';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import Checkbox from 'core/components/Checkbox';
import * as S from './styled';
import AssetSortMenu from '../AssetSortMenu';

const AssetSearch = () => {
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Assets]);
  const dispatch = useDispatch();
  const onSearchClearHandler = useCallback(() => {
    dispatch(clearFuzzySearchOnActiveSearch());
  }, [dispatch]);
  const searchQuery = useAppSelector((state) => state.assets.assetSearch?.query);
  const searchFields = useAppSelector((state) => state.assets.assetSearch?.fields);
  const onSearchHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // We check if we should filter by IPRID or by fuzzy text search
      const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      if (regex.test(event.currentTarget.value)) {
        dispatch(setFilterByIdOnActiveSearch(event.currentTarget.value));
        return;
      }
      dispatch(setFuzzySearchQuery(event.currentTarget.value.trim()));
    },
    [dispatch]
  );

  const onSearchFieldSelectedHandler = useCallback(
    (field: AssetsSearchProps) => (selected: boolean) => {
      dispatch(setFuzzySearchField({ field, selected }));
    },
    [dispatch]
  );

  const assetNameChecked = useMemo(() => searchFields?.includes(AssetsSearchProps.AssetName), [searchFields]);
  const companyChecked = useMemo(() => searchFields?.includes(AssetsSearchProps.Company), [searchFields]);
  const modelChecked = useMemo(() => searchFields?.includes(AssetsSearchProps.Model), [searchFields]);
  const serialNumberChecked = useMemo(() => searchFields?.includes(AssetsSearchProps.SerialNumber), [searchFields]);

  return (
    <S.SearchDiv>
      <div id="searchBar">
        <SearchBar
          onChange={onSearchHandler}
          onClear={onSearchClearHandler}
          defaultValue={searchQuery}
          placeholder={t('ASSETS:searched.placeholder')}
        />
      </div>

      <S.ListOptionsDiv>
        <S.SearchOptionSpan>{t('ASSETS:labels.searchBy')}</S.SearchOptionSpan>
        <S.SearchOptionsDiv>
          <Checkbox
            label={t('ASSETS:fieldLabels.assetName')}
            onChange={onSearchFieldSelectedHandler(AssetsSearchProps.AssetName)}
            checked={assetNameChecked}
            id="assetsName"
          />
          <Checkbox
            label={t('ASSETS:fieldLabels.companyName')}
            onChange={onSearchFieldSelectedHandler(AssetsSearchProps.Company)}
            checked={companyChecked}
            id="companyName"
          />
          <Checkbox
            label={t('ASSETS:fieldLabels.modelName')}
            onChange={onSearchFieldSelectedHandler(AssetsSearchProps.Model)}
            checked={modelChecked}
            id="modelName"
          />
          <Checkbox
            label={t('ASSETS:fieldLabels.serialNumber')}
            onChange={onSearchFieldSelectedHandler(AssetsSearchProps.SerialNumber)}
            checked={serialNumberChecked}
            id="serialNumber"
          />
        </S.SearchOptionsDiv>
        <S.SortOptionsDiv>
          <AssetSortMenu />
        </S.SortOptionsDiv>
      </S.ListOptionsDiv>
    </S.SearchDiv>
  );
};

export default AssetSearch;
