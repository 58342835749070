import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { Icon } from 'hudskit-framework-react';
import { Tooltip } from 'react-tooltip';
import * as S from './styled';

const CompaniesWidgetInformation = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Companies);
  return (
    <>
      <div
        data-tooltip-id="addCompanyWidgetInformation"
        data-tooltip-place="bottom"
      >
        <Icon type="notification.info_outlined" />
      </div>
      <Tooltip id="addCompanyWidgetInformation" data-testid="tooltip">
        <S.TooltipDiv>
          <span>{t('COMPANIES:tooltips.companiesWidgetInfomationPartOne')}</span>
        </S.TooltipDiv>
      </Tooltip>
    </>

  );
};

export default CompaniesWidgetInformation;
