import { AssetDetailsResponse } from '@uptime-bff/api-contract';
import useMediaQuery from 'core/hooks/use-media-query';
import { device } from 'core/styles/variables';
import AssetThumbnail from '../AssetThumbnail';
import * as S from './styled';

type Props = {
  asset: AssetDetailsResponse;
};

const AssetThumbnailInfo = ({ asset }: Props) => {
  const isLargeScreen = useMediaQuery(device.md);
  return (
    <S.AssetThumbnailInfoContainerSection>
      <AssetThumbnail
        imageAlt={asset.model}
        imageSrc={asset.thumbnail}
        size={isLargeScreen ? 'lg' : 'md'}
      />

      <S.ThumbnaildDetailsDiv>
        <S.AssetNameSpan>{asset.assetName}</S.AssetNameSpan>
        <S.AssetModelSpan>{asset.model}</S.AssetModelSpan>
        <S.AssetCompanyNameSpan>{asset.companyName}</S.AssetCompanyNameSpan>
      </S.ThumbnaildDetailsDiv>
    </S.AssetThumbnailInfoContainerSection>
  );
};

export default AssetThumbnailInfo;
