import React from 'react';
import { Icon, Button, IconTypeKey, ThemeColor } from 'hudskit-framework-react';
import * as S from './styled';

export interface Props {
  messageText: string;
  dismissText: string;
  iconType: IconTypeKey;
  iconColor: ThemeColor;
  borderColor: ThemeColor;
  dismissClickHandler?(): void;
}

const ErrorBanner = ({ borderColor, dismissClickHandler, messageText, iconType, iconColor, dismissText }: Props) => (
  <S.ErrorWrapperDiv>
    <S.BannerDiv borderColor={borderColor}>
      <S.MessageWrapperDiv>
        <S.IconWrapperDiv>
          <Icon type={iconType} color={iconColor} />
        </S.IconWrapperDiv>
        <S.DefaultMessageDiv>{messageText}</S.DefaultMessageDiv>
      </S.MessageWrapperDiv>
      {dismissClickHandler && (
        <S.ActionButtonDiv>
          <Button variant="surface" size="small" onClick={dismissClickHandler}>
            {dismissText}
          </Button>
        </S.ActionButtonDiv>
      )}
    </S.BannerDiv>
  </S.ErrorWrapperDiv>
);

ErrorBanner.defaultProps = {
  dismissClickHandler: undefined,
};

export default ErrorBanner;
