import { paddings, typographyMobile } from 'core/styles/variables';
import { zFloatingButton } from 'core/styles/z-index';
import { darkTheme } from 'hudskit-framework-react';
import styled, { CSSProp } from 'styled-components';

type ContainerProps = {
  $css?: CSSProp;
};
export const MapContainerDiv = styled.div<ContainerProps>`
  ${({ $css }) => $css}
`;

export const NoPositionSpan = styled.span`
  font-size: ${() => typographyMobile.size.default};
  z-index: ${() => zFloatingButton};
  border: 1px solid black;
  position: relative;
  display: flex;
  padding: ${() => paddings.largePadding} ${() => paddings.hugePadding};
  background-color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray600 : theme.colors.gray200)};
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray200 : theme.colors.gray600)};
`;
