import { device } from 'core/styles/variables';
import styled from 'styled-components';

export { LoaderDiv } from '../CustomerWidgetCard/styled';

export const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (${() => device.xxl}) {
    max-width: 24vw;
  }
`;

export const FooterContentDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
