import { paddings } from 'core/styles';
import { boxShadows } from 'hudskit-framework-react';
import styled, { css } from 'styled-components';

type Props = {
  $icon?: boolean;
  $copyIcon?: boolean;
};

const InfoCellCss = css<Props>`
  display: flex;
  align-items: center;
  justify-content: ${({ $icon }) => ($icon ? 'flex-start' : 'space-between')};
  gap: ${paddings.mediumPadding};
  border-radius: 12px;
`;

export const InfoCellDiv = styled.div<Props>`
  ${InfoCellCss}
  padding: ${paddings.smallPadding} 0;
  
`;
export const InfoCellAnchor = styled.a<Props>`
  ${InfoCellCss}
  text-decoration: none;
  color: ${({ theme }) => theme.colors.onSurface};
  &:hover {
    box-shadow: ${() => boxShadows.normal};
  }
`;

// OLD STYLES
const InfoCellCssV1 = css`
  display: grid;
  grid-template-areas:
    'icon label label copy'
    'icon value value copy';
  grid-template-columns: 40px 1fr 1fr;
  border: 1px solid #eee;
  gap: ${() => paddings.smallPadding};
  padding: ${() => paddings.defaultPadding};
  border-radius: 12px;
`;

export const InfoCellDivV1 = styled.div`
  ${InfoCellCssV1}
`;
export const InfoCellAnchorV1 = styled.a`
  ${InfoCellCssV1}
  text-decoration: none;
  color: ${({ theme }) => theme.colors.onSurface};
  &:hover {
    box-shadow: ${() => boxShadows.normal};
  }
`;
