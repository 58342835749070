import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  id:string
  children:ReactNode
};

export default function Portal({ id, children }:Props) {
  let modalRoot = document.getElementById(id);

  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', id);
    document.body.prepend(modalRoot);
  }
  return createPortal(children, modalRoot);
}
