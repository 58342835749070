const digestMessage = async (message: string): Promise<number[]> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray;
};
const dec2hex = (dec: number) => dec.toString(16).padStart(2, '0');

const generateRandomString = () => {
  const array = new Uint32Array(56 / 2);
  const randomArray = window.crypto.getRandomValues(array);

  return Array.from(randomArray, dec2hex).join('');
};

export { digestMessage, dec2hex, generateRandomString };
