import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import { uptimeApi } from 'core/api';
import authReducer from 'feature/auth/store';
import assetsReducer from 'feature/assets/store';
import companiesReducer from 'feature/companies/store';
import dashboardReducer from 'feature/dashboard/store';
import layoutReducer from 'core/layout/reducer';

const reducers = combineReducers({
  [uptimeApi.reducerPath]: uptimeApi.reducer,
  auth: authReducer,
  assets: assetsReducer,
  companies: companiesReducer,
  dashboard: dashboardReducer,
  layout: layoutReducer
});

const rootReducer = (state: any, action: AnyAction) => {
  let stateRef = state;
  if (action.type === 'auth/logout') {
    stateRef = undefined;
  }
  return reducers(stateRef, action);
};
export default rootReducer;
