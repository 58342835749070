import { paddings } from 'core/styles';
import { device } from 'core/styles/variables';
import { darkTheme } from 'hudskit-framework-react';
import styled, { css } from 'styled-components';

interface ListItemOptions {
  $active?: boolean;
}

export const ListItemsDiv = styled.div`
  display: grid;
  position: relative;
`;

export const CompanyListItemDiv = styled.div<ListItemOptions>`
  border: none;
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray100 : theme.colors.gray800)};
  border-bottom: 1px solid ${({ theme }) => (theme === darkTheme ? theme.colors.gray600 : theme.colors.gray200)};
  display: grid;
  background-color: ${({ theme, $active }) => ($active ? theme.colors.secondary : 'inherit')};
  grid-template-columns: 1fr 1fr;
  cursor: pointer;

  &:first-child {
    border-top: 1px solid ${({ theme }) => (theme === darkTheme ? theme.colors.gray600 : theme.colors.gray200)};
  }
  &:hover {
    background-color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray600 : theme.colors.gray200)};
  }

  @media ${() => device.sm} {
    grid-template-columns:  repeat(3, 1fr);
  }
  @media ${() => device.lg} {
    grid-template-columns: repeat(7, 1fr);
  }
`;

const ListItemColumnCSS = css`
  padding: ${() => paddings.defaultPadding};

  @media ${() => device.lg} {
    display: block;
    padding: ${() => paddings.defaultPadding} ${() => paddings.defaultPadding};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
  }
`;

export const ListItemDiv = styled.div`
  display: none;
  ${ListItemColumnCSS};
`;

export const ListItemNameColumn = styled.div`
  display: block;
  ${ListItemColumnCSS};
`;

export const ListItemCountryColumn = styled.div`
  ${ListItemColumnCSS};
  display: none;
  @media ${() => device.sm} {
    display: block;
  }
`;
export const ListItemNumericColumn = styled.div`
  ${ListItemColumnCSS};
  display: none;
  @media ${() => device.lg} {
    display: block;
    justify-self: end;
  }
`;
