import ErrorCard from 'core/components/ErrorCard';
import { useGetAssetByIdQuery } from 'feature/assets/api';
import { Loader, NavMenu as TabMenu } from 'hudskit-framework-react';
import { Outlet, useParams } from 'react-router-dom';
import { useUpdateAssetListItem } from 'feature/assets/hooks/use-update-asset-list-item';
import { navigationWithChildren } from 'core/config';
import { useMemo } from 'react';
import { getAssetTabMenu } from 'core/layout/NavMenu/menu';
import { useUptimeNavigation } from 'core/hooks/use-uptime-navigation';
import * as S from './styled';
import SingleAssetMap from '../SingleAssetMap';
import AssetThumbnailInfo from '../AssetThumbnailInfo';
import AssetDetailsStatusBar from '../AssetDetailsStatusBar';
import AssetDetailsAddressline from '../AssetDetailsAddressline';
import AssetDetailsProductInfo from '../AssetDetailsProductInfo';
import AssetDetailsContact from '../AssetDetailsContact';

type Props = {
  selectedAsset?: string;
};

const AssetDetails = ({ selectedAsset }: Props) => {
  const { id } = useParams();
  const assetId = navigationWithChildren ? id : selectedAsset;

  const { isError, isFetching, isSuccess, data: asset } = useGetAssetByIdQuery(assetId ?? '');
  useUpdateAssetListItem(asset);
  const tabMenu = useMemo(() => getAssetTabMenu(), []);
  const { activeTabId, onClickHandler } = useUptimeNavigation(tabMenu.menu);
  return (
    <>
      {isFetching && (
      <S.LoaderDiv>
        <Loader center size="large" />
      </S.LoaderDiv>
      )}
      {isError && <ErrorCard />}
      {!isFetching && isSuccess && !isError && asset && (
        <>
          {asset.mowerStatus && asset.thumbnail && (
            <SingleAssetMap
              location={asset.assetPosition?.location}
              assetName={asset.assetName}
              assetStatusSeverity={asset.mowerStatusSeverity}
              assetThumbnail={asset.thumbnail}
              assetStatus={asset.mowerStatus}
            />
          )}
          <S.InfoDiv>
            <AssetDetailsAddressline asset={asset} />
            <AssetThumbnailInfo asset={asset} />
            <AssetDetailsStatusBar asset={asset} />
            {navigationWithChildren ? (
              <>
                <S.TabMenuDiv>
                  <TabMenu menu={tabMenu.menu} onClick={onClickHandler} activeMenuItemId={activeTabId} />
                </S.TabMenuDiv>
                <S.InfoCardsDiv>
                  <Outlet context={asset} />
                </S.InfoCardsDiv>
              </>
            ) : (
              <S.InfoCardsDivV1>
                <AssetDetailsProductInfo assetDetails={asset} />
                <AssetDetailsContact assetDetails={asset} />
              </S.InfoCardsDivV1>

            )}
          </S.InfoDiv>
        </>
      )}
    </>
  );
};

export default AssetDetails;

AssetDetails.defaultProps = {
  selectedAsset: undefined,
};
