import { paddings } from 'core/styles';
import { device } from 'core/styles/variables';
import styled from 'styled-components';

export const AccessTitleDiv = styled.div`
  display: flex;
  align-items: baseline;
  gap: ${() => paddings.smallPadding};
`;

export const NoAccessParagraph = styled.div`
  padding: 0;
  line-height: 1.5;


  @media ${device.md}{
    width: 517px;
  }
`;

export const NoAccessButtonDiv = styled.div`
  display: flex;
  justify-content: end;
`;
