import { hideFeedbackButton } from 'core/config';
import { useState } from 'react';
import FeedbackButton from '../FeedbackButton';
import FeedbackModal from '../FeedbackModal';

const Feedback = () => {
  const [feedback, setFeedback] = useState<boolean>(false);
  const onFeedbackButtonClick = () => {
    setFeedback(!feedback);
  };

  return (
    <>
      {!hideFeedbackButton && <FeedbackButton onClick={onFeedbackButtonClick} />}
      {feedback && <FeedbackModal isOpen={feedback} close={onFeedbackButtonClick} />}
    </>
  );
};

export default Feedback;
