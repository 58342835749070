import * as S from './styled';
import CompaniesWidgetInformation from '../CompaniesWidgetInformation';

const CompanyListHeader = () => {
  enum ListTitles {
    COMPANY = 'COMPANY',
    COUNTRY = 'COUNTRY',
    CONTACT = 'CONTACT',
    CUSTOMER_NUMBER = 'CUSTOMER NUMBER',
    MACHINES = 'MACHINES',
    ROBOTIC_MOWERS = 'ROBOTIC MOWERS',
    SHOW_ON_DASHBOARD = 'SHOW ON DASHBOARD',
  }

  const listTitles = Object.values(ListTitles) as string[];

  return (
    <S.CompanyListDiv data-testid="titles">
      {listTitles.map((title) => (
        <S.TitleContainer key={title} $title={title === ListTitles.SHOW_ON_DASHBOARD}>
          <S.CompanyDiv $title={title === ListTitles.SHOW_ON_DASHBOARD}>{title}</S.CompanyDiv>
          {title === ListTitles.SHOW_ON_DASHBOARD && (
            <S.CompaniesWidgetToolTipInformationDiv>
              <CompaniesWidgetInformation />
            </S.CompaniesWidgetToolTipInformationDiv>
          )}
        </S.TitleContainer>
      ))}
    </S.CompanyListDiv>
  );
};

export default CompanyListHeader;
