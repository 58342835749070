import { paddings } from 'core/styles';
import { device, typographyDesktop, typographyMobile } from 'core/styles/variables';
import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${() => paddings.defaultPadding};
`;

export const ContentDiv = styled.div`
  padding-top: ${() => paddings.hugePadding};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  max-width: 1200px;
`;

export const AlignRightDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const WrapDiv = styled.div`
  flex-wrap: wrap;
  display: flex;
  gap: ${() => paddings.defaultPadding};
`;

export const LogoutContainerDiv = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
  gap: ${() => paddings.smallPadding};

`;

export const LogoutText = styled.span`
  font-size: ${() => typographyMobile.size.tiny};

  @media ${device.sm} {
    font-size: ${() => typographyDesktop.size.tiny};
  }
`;

export const LogoutInfoDiv = styled.div`
  display: flex;
  align-items: center;
  gap: ${() => paddings.smallPadding};
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray400 : theme.colors.gray800)};
`;
