import { device, paddings } from 'core/styles/variables';
import { styled } from 'styled-components';

export const SearchDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${() => paddings.smallPadding};
`;

export const SearchListOptionsDiv = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 40px;
  padding: ${() => paddings.mediumPadding}  ${() => paddings.smallPadding};


  @media ${() => device.lg}{
    grid-template-columns: 1fr 140px;
  }
  @media ${() => device.xl}{
    grid-template-columns: 70px 1fr 200px;
  }
`;
export const CompanyContainerDiv = styled.div`
  display: flex;
`;

export const SearchOptionSpan = styled.span`
  display: none;
  @media ${() => device.xl}{
    display: block;
  }
`;

export const SearchOptionsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${() => paddings.largePadding};
  label {
    margin-left: -12px; //removing margin from hudskit component while a more permanent fix is on at the library level
  }
`;

export const SortMenuDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CompanySortingDiv = styled.div`
  justify-content: center;
  align-items: center;
`;
