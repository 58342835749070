import { NavMenu as HudskitNavMenu } from 'hudskit-framework-react';
import { useUptimeNavigation } from 'core/hooks/use-uptime-navigation';
import { useRemoteConfig } from 'core/hooks/use-remote-config';
import { useMemo } from 'react';
import { getUptimeMenu } from './menu';

const NavMenu = () => {
  const isRemoteConfigReady = useRemoteConfig();
  const navMenu = useMemo(() => getUptimeMenu(), []);

  const { activeMenuId, onClickHandler } = useUptimeNavigation(navMenu.menu);
  return isRemoteConfigReady && <HudskitNavMenu menu={navMenu.menu} onClick={onClickHandler} activeMenuItemId={activeMenuId} />;
};
export default NavMenu;
