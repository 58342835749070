import { borderRadius } from 'core/styles';
import styled from 'styled-components';

export const MenuUl = styled.ul`
  all: unset;
  display: flex;
  flex-direction: column;
  border-top-right-radius: ${() => borderRadius.default};
  background-color: ${({ theme }) => theme.colors.background};
`;

export const CorniceSvg = styled.svg`
  & path {
    fill: ${({ theme }) => theme.colors.background};
  }
`;
