import { lightTheme, boxShadows, darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';
import { fullScreen } from 'core/styles';
import { zModal } from 'core/styles/z-index';

export const ModalWrapper = styled.div`
  ${() => fullScreen}
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${() => zModal};
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.48);
  transition: background-color 0.3s linear;
`;

export const Modal = styled.main`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.colors.surface};
  box-shadow: ${({ theme }) => (theme === darkTheme ? boxShadows.dark : boxShadows.normal)};
  outline: none;
  min-width: 400px;
  border-radius: 16px;
`;

export const ModalHeader = styled.header`
  padding: 20px 24px 12px;
  display: flex;
  flex-direction: row;
  font-weight: 700;
  font-size: x-large;
  line-height: 24px;
  border-bottom: 1px solid ${({ theme }) => (theme === lightTheme ? theme.colors.gray300 : theme.colors.gray600)};
  color: ${({ theme }) => theme.colors.onSurface};
  justify-content: space-between;
`;

export const ModalMain = styled.main`
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`;

export const Icon = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
