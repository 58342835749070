import { ReactNode, useEffect } from 'react';
import { Icon } from 'hudskit-framework-react';
import * as S from './styled';
import Portal from '../Portal/Portal';

type Props = {
  /**
   * The content that will be passed into the modal.
   */
  children: ReactNode;
  /*
   * The title of the modal.
   * */
  title: string | ReactNode;
  /**
   * If the modal is open or not.
   */
  isOpen?: boolean;
  /**
   *  This function is need for closing the modal after its been opened.
   */
  close?: () => void;
};

const Modal = ({ children, title, isOpen, close }: Props) => {
  useEffect(() => {
    const onEscapeKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        close?.();
      }
    };
    document.addEventListener('keydown', onEscapeKeydown);
    return () => document.removeEventListener('keydown', onEscapeKeydown);
  }, [isOpen, close]);

  return (
    <Portal id="modal-root">
      {isOpen && (
      <S.ModalWrapper>
        <S.Overlay data-testid="overlay" onClick={close} />
        <S.Modal>
          <S.ModalHeader>
            <span>{title}</span>
            <S.Icon type="submit" onClick={close}>
              <Icon type="action.close" />
            </S.Icon>
          </S.ModalHeader>
          <S.ModalMain>{children}</S.ModalMain>
        </S.Modal>
      </S.ModalWrapper>
      )}
    </Portal>
  );
};

Modal.defaultProps = {
  isOpen: false,
  close: undefined,
};

export default Modal;
