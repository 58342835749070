import { useEffect, useState } from 'react';

import { configAuth } from 'core/config/environment-variables';
import { useAuthNonce } from './use-auth-nonce';
import { useAuthState } from './use-auth-state';

const useLoginUrl = () => {
  const { nonceCryptographicHash } = useAuthNonce();
  const { stateCryptographicHash } = useAuthState();
  const [loginUrl, setLoginUrl] = useState<string>('');
  useEffect(() => {
    if (nonceCryptographicHash && stateCryptographicHash) {
      const url = configAuth.oidcUrl
        .concat('/mga/sps/oauth/oauth20/authorize?client_id=')
        .concat(configAuth.oidcClientId)
        .concat('&response_type=code')
        .concat('&scope=openid salescompanycodeandcustomernumber groups salescompanycode profile email employeetype')
        .concat('&redirect_uri=')
        .concat(encodeURIComponent(configAuth.oidcRedirectUrl))
        .concat('&state=')
        .concat(stateCryptographicHash)
        .concat('&nonce=')
        .concat(nonceCryptographicHash);
      setLoginUrl(url);
    }
  }, [nonceCryptographicHash, stateCryptographicHash]);
  return { loginUrl, nonceCryptographicHash, stateCryptographicHash };
};

export { useLoginUrl };
