import PillTag from 'core/components/PillTag';
import { AssetDetailsResponse, MowerStatus } from '@uptime-bff/api-contract';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { getStatusColor } from 'feature/assets/utils';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { refinedErrorCodeInteger } from 'core/config';
import * as S from './styled';
import AssetErrorCode from '../AssetErrorCode';

type Props = {
  asset: AssetDetailsResponse
};

const AssetDetailsStatusBar = ({ asset }: Props) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const status = asset.mowerStatus ? t(`ASSETS:mowerStatus.${asset.mowerStatus}`) : '';

  return (
    <S.PillTagContainerDiv
      $status={asset.mowerStatus === MowerStatus.Error ? getStatusColor(asset.mowerStatus) : 'none'}
      data-testid={`mower-${asset.mowerStatus}`}
    >
      {asset.mowerStatus && asset && (
        <>
          <PillTag color={getStatusColor(asset.mowerStatus)}>{status}</PillTag>
          {asset.mowerStatus === 'error'
            && <AssetErrorCode error={refinedErrorCodeInteger ? asset.refinedStatusError : asset.error} errorDate={asset.errorDate} />}
        </>
      )}
    </S.PillTagContainerDiv>

  );
};

export default AssetDetailsStatusBar;
