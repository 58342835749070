import { Button, IconLarge } from 'hudskit-framework-react';
import { useCallback, useEffect } from 'react';
import { AssetListItem as Asset } from '@uptime-bff/api-contract';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import FiltersDrawer from 'feature/assets/components/FiltersDrawer/FiltersDrawer';
import AssetList from 'feature/assets/components/AssetList';
import AssetSearch from 'feature/assets/components/AssetSearch';
import AssetsFilterTags from 'feature/assets/components/AssetsFilterTags';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { setSelectedAsset } from 'feature/assets/store';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { navigationWithChildren } from 'core/config';
import AssetDetails from 'feature/assets/components/AssetDetails';
import * as S from './styled';

const Assets = () => {
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Assets, InternationalizationNameSpace.Core]);
  const selectedAsset = useAppSelector((state) => state.assets.selectedAsset);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Old, remove when feature flag is remvoed: navigationWithChildren
  const onAssetItemClickHandlerWithoutNavigation = useCallback((asset: Asset) => {
    dispatch(setSelectedAsset(asset));
  }, [dispatch]);

  const onDetailsCloseHandlerWithoutNavigation = useCallback(() => {
    dispatch(setSelectedAsset(undefined));
  }, [dispatch]);

  // New, keep when feature flag is remvoed: navigationWithChildren
  const onAssetItemClickHandlerWithNavigation = useCallback((asset: Asset) => {
    dispatch(setSelectedAsset(asset));
    navigate(`/assets/${asset.id}/product-information`);
  }, [dispatch, navigate]);

  const onDetailsCloseHandlerWithNavigation = useCallback(() => {
    navigate('/assets');
    dispatch(setSelectedAsset(undefined));
  }, [dispatch, navigate]);

  // Remove when feature flag is remvoed: navigationWithChildren
  const onAssetItemClickHandler = useCallback(
    (asset: Asset) => {
      if (navigationWithChildren) {
        onAssetItemClickHandlerWithNavigation(asset);
        return;
      }
      onAssetItemClickHandlerWithoutNavigation(asset);
    },
    [onAssetItemClickHandlerWithNavigation, onAssetItemClickHandlerWithoutNavigation]
  );

  const onDetailsCloseHandler = useCallback(() => {
    if (navigationWithChildren) {
      onDetailsCloseHandlerWithNavigation();
      return;
    }
    onDetailsCloseHandlerWithoutNavigation();
  }, [onDetailsCloseHandlerWithNavigation, onDetailsCloseHandlerWithoutNavigation]);

  // Keep when feature flag is remvoed: navigationWithChildren
  useEffect(() => {
    if (navigationWithChildren) {
      navigate('/assets');
      dispatch(setSelectedAsset(undefined));
    }
  }, [dispatch, navigate]);

  return (
    <S.GridDiv $active={!!selectedAsset} $location={navigationWithChildren && (location.pathname === '/assets')}>
      <S.FiltersDiv>
        <FiltersDrawer />
      </S.FiltersDiv>
      <S.ListDiv>
        <S.ListHeaderDiv>
          <AssetSearch />
          <AssetsFilterTags />
        </S.ListHeaderDiv>
        <AssetList onAssetItemClickHandler={onAssetItemClickHandler} selectedAssetId={selectedAsset?.id} />
      </S.ListDiv>
      <S.DetailsArticle>
        <S.DetailsHeaderDiv>
          <Button
            type="button"
            size="small"
            variant="transparent"
            icon={{ type: 'action.close', position: 'left' }}
            onClick={onDetailsCloseHandler}
          >
            {t('CORE:buttons.close')}
          </Button>
        </S.DetailsHeaderDiv>
        {selectedAsset?.id && navigationWithChildren ? (
          <Outlet />)
          : (
            selectedAsset && <AssetDetails selectedAsset={selectedAsset.id} />)}
        {!selectedAsset && (
          <>
            <IconLarge type="productCategory.robotics" size="extraLarge" />
            <div>Select one item from the list</div>
          </>
        )}
      </S.DetailsArticle>
    </S.GridDiv>
  );
};

export default Assets;
