import { AssetDetailsResponse, MowerStatus } from '@uptime-bff/api-contract';
import { FunctionalColor } from 'core/styles/variables';

export const getStatusColor = (status: MowerStatus): FunctionalColor => {
  switch (status) {
    case MowerStatus.Mowing:
      return 'success';
    case MowerStatus.Error:
      return 'error';
    case MowerStatus.Paused:
    case MowerStatus.Stopped:
      return 'caution';
    case MowerStatus.MissingArea:
    case MowerStatus.MissingInstallation:
    case MowerStatus.Unknown:
      return 'other';
    default:
      return 'progress';
  }
};

export const getPurchaseDate = (asset?: AssetDetailsResponse) => (
  asset?.purchaseDate ? new Date(asset?.purchaseDate).toISOString().split('T')[0] : ''
);
