import { darkTheme, lightTheme } from 'hudskit-framework-react';
import { useState } from 'react';
import { DefaultTheme } from 'styled-components';

export const usePrefersColorScheme = () => {
  let isOnLightMode = false;
  if (window.matchMedia) {
    if (window.matchMedia('(prefers-color-scheme: light)').matches) {
      isOnLightMode = true;
    }
  }
  const [theme, setTheme] = useState<DefaultTheme>(isOnLightMode ? lightTheme : darkTheme);
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
    setTheme(event.matches ? darkTheme : lightTheme);
  });
  return theme;
};
