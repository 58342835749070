import { paddings } from 'core/styles';
import styled from 'styled-components';

type Props = {
  $active: boolean;
};

export const FilterDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${paddings.tinyPadding};
`;

export const SelectFieldDiv = styled.div<Props>`
  margin-bottom: ${({ $active }) => ($active ? paddings.mediumPadding : 0)};
`;
