import { useGetSearchAvailableFiltersQuery } from 'feature/assets/api';
import { useAppSelector } from 'core/hooks/use-app-selector';
import {
  clearFilters,
  setFilterByCompanyCountryOnActiveSearch,
  setFilterByModelsOnActiveSearch,
} from 'feature/assets/store';
import { Button, Loader } from 'hudskit-framework-react';
import { useCallback } from 'react';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import StatusFilter from '../StatusFilter';
import FilterCard from '../FilterCard';
import FilterSearch from '../FilterSearch';
import * as S from './styled';
import ConnectionStatusFilter from '../ConnectionStatusFilter';

const Filters = () => {
  const filters = useAppSelector((state) => state.assets.activeListFilters);
  const { data, isLoading } = useGetSearchAvailableFiltersQuery({ ...filters });
  const selectedModels = useAppSelector((state) => state.assets.activeListFilters?.models);
  const selectedCountries = useAppSelector((state) => state.assets.activeListFilters?.companyCountries);
  const dispatch = useAppDispatch();
  const onClearFiltersClick = useCallback(() => {
    dispatch(clearFilters());
  }, [dispatch]);
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);

  const selectedOption = (selected?: string[]) => selected?.map((optionValue) => ({
    label: optionValue,
    value: optionValue,
  })) ?? [];

  return (
    <form>
      <S.ClearButtonWrapperDiv id="clearFilter">
        <Button onClick={onClearFiltersClick} type="button" variant="secondary" disabled={!filters}>
          {t('ASSETS:filters.buttons.clearFilters')}
        </Button>
      </S.ClearButtonWrapperDiv>
      <StatusFilter />
      <ConnectionStatusFilter />
      {isLoading && (
      <FilterCard title="">
        <Loader size="small" center />
      </FilterCard>
      )}

      {data?.models && (
      <FilterSearch
        title={t('ASSETS:labels.models')}
        id="companies-models"
        selectedFilters={selectedModels}
        filterAction={setFilterByModelsOnActiveSearch}
        options={selectedOption(data?.models)}
      />
      )}

      {data?.countries && data.countries.length > 1 && (
      <FilterSearch
        title={t('ASSETS:labels.countries')}
        id="companies-countries"
        selectedFilters={selectedCountries}
        filterAction={setFilterByCompanyCountryOnActiveSearch}
        options={selectedOption(data?.countries)}
      />
      )}
    </form>
  );
};

export default Filters;
