import styled from 'styled-components';

interface CheckValue {
  $number?: boolean;
}

export const CompanyInfoCellDiv = styled.div`
  display: flex;
  align-items: center;  
`;

export const InfoDiv = styled.div`  
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 18px;
`;

export const ValueLabel = styled.label`
  font-weight: bold;
  font-size: 16px;
`;

export const ValueSpan = styled.span<CheckValue>`
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${(props) => (props.$number ? '28px' : '14px')};
  font-weight: ${(props) => (props.$number ? 'bold' : 'normal')};
`;

export const IconDiv = styled.div<CheckValue>`
  display: ${({ $number }) => ($number ? 'none' : 'block')};

`;
