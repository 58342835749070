import { centeredFlex, paddings } from 'core/styles';
import { device, typographyMobile } from 'core/styles/variables';
import styled from 'styled-components';

export const LoaderDiv = styled.div`
  ${centeredFlex}
  height: 100%;
`;

export const InfoDiv = styled.div`
  padding: ${paddings.defaultPadding};
  width: 100%;
  display: flex;
  overflow-x: auto;
  flex-direction: column;
  position: relative;
  
`;

export const InfoCardsDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${paddings.smallPadding};
  padding-top: ${paddings.largePadding};
`;

export const TabMenu = styled.div`
  padding: 0 ${paddings.defaultPadding} ${paddings.defaultPadding} 0;
`;

export const TabMenuDiv = styled.div`
  div {
    font-size: ${typographyMobile.size.default};

    @media ${device.md} {
      font-size: ${typographyMobile.size.medium};
      white-space: nowrap;
    }
  };

  div > div {
    margin-top: ${paddings.mediumPadding};
  }
`;

// Old Style
export const InfoCardsDivV1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${() => device.md} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  gap: ${() => paddings.smallPadding};
`;
