import { useAppSelector } from 'core/hooks/use-app-selector';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useDebounce from 'core/hooks/use-debounce';
import { appendCompaniesToList, setCompanies } from '../store';
import { useLazySearchCompaniesQuery } from '../api';

type Props = {
  take: number;
};

const useSearchableCompanies = ({ take }: Props) => {
  const [searchCompanies, { isError, isSuccess, isFetching }] = useLazySearchCompaniesQuery();
  const [skip, setSkip] = useState<number>(0);
  const [hasMoreCompanies, setHasMoreCompanies] = useState<boolean>(isSuccess);
  const [dataUpdatedOn, setDataUpdatedOn] = useState<string | undefined>();
  const activeSearch = useAppSelector((state) => state.companies.activeCompanyFilters);
  const activeSorting = useAppSelector((state) => state.companies.companiesSorting);
  const uniqueCompanies = useAppSelector((state) => state.companies.companiesList);
  const companies = useMemo(() => (uniqueCompanies ? Object.values(uniqueCompanies) : []), [uniqueCompanies]);
  const dispatch = useAppDispatch();
  const debouncedActiveSearch = useDebounce(activeSearch, 500);
  const debouncedActiveSorting = useDebounce(activeSorting, 500);
  const fetchCompanies = useCallback((pageToQuery: number) => searchCompanies({
    filters: debouncedActiveSearch,
    sorting: debouncedActiveSorting,
    skip: pageToQuery,
    take,
  }).unwrap(), [debouncedActiveSearch, debouncedActiveSorting, take, searchCompanies]);
  const loadCompanies = useCallback(async () => {
    try {
      const result = await fetchCompanies(skip);
      setHasMoreCompanies(result.hasMorePages);
      if (result.dataUpdatedOn) {
        setDataUpdatedOn(result.dataUpdatedOn);
      }
      if (skip === 0) {
        dispatch(setCompanies(result.elements));
        return;
      }
      dispatch(appendCompaniesToList(result.elements));
    } catch (e) {
      // Handled using RTK hook result
    }
  }, [dispatch, fetchCompanies, skip]);

  useEffect(() => {
    setSkip(0);
  }, [activeSearch, activeSorting]);

  useEffect(() => {
    (async () => {
      await loadCompanies();
    })();
  }, [loadCompanies, skip]);

  const getNextPage = useCallback(async () => {
    setSkip((currentPage) => currentPage + 20);
  }, []);

  return { companies, isError, hasMoreCompanies, dataUpdatedOn, getNextPage, isFetching, loadCompanies, isSuccess };
};

export { useSearchableCompanies };
