import { AssetDetailsResponse } from '@uptime-bff/api-contract';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useCallback, useEffect } from 'react';
import { updateAssetInList } from '../store';

export const useUpdateAssetListItem = (asset: AssetDetailsResponse | undefined) => {
  const dispatch = useAppDispatch();

  const updateAsset = useCallback((assetDetail: AssetDetailsResponse) => {
    dispatch(updateAssetInList(assetDetail));
  }, [dispatch]);

  useEffect(() => {
    if (asset) {
      updateAsset(asset);
    }
  }, [asset, updateAsset]);

  return { updateAsset };
};
