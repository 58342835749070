import { paddings } from 'core/styles';
import { device, headerHeight } from 'core/styles/variables';
import { zFloatingPanels } from 'core/styles/z-index';
import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';

type ActiveCompanyProps = { $active: boolean };

export const DetailsArticle = styled.article`
  position: sticky;
  z-index: ${() => zFloatingPanels};
  border-radius: 12px;
  min-height: calc(100vh) - ${() => headerHeight} - ${() => paddings.smallPadding};
  top: ${() => headerHeight};
  background: ${({ theme }) => theme.colors.surface};
  color: ${({ theme }) => theme.colors.onSurface};
  border: 1px solid ${({ theme }) => (theme === darkTheme ? '#3D3D3D' : '#F0F0F0')};
  `;

export const DetailsHeaderDiv = styled.div`
  padding: ${() => paddings.tinyPadding};
  display: flex;
  justify-content: end;
  align-items: center;
  position: sticky;
  top: 75px;
  z-index: ${() => zFloatingPanels};
  background-color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray700 : theme.colors.gray200)};
  border-bottom: 1px solid ${({ theme }) => (theme === darkTheme ? '#3D3D3D' : '#F0F0F0')};
`;

export const ListHeaderDiv = styled.div`
  position: sticky;
  z-index: ${() => zFloatingPanels};
  border-radius: 12px;
  top: ${() => headerHeight};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.surface};
  padding: 6px;
`;

export const ListDiv = styled.div`
  height: 100%;
  color: ${({ theme }) => theme.colors.onSurface};
  background: ${({ theme }) => theme.colors.surface};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => (theme === darkTheme ? '#3D3D3D' : '#F0F0F0')};
  color: ${({ theme }) => theme.colors.onBackground};
  display: flex;
  flex-direction: column;
`;

export const GridDiv = styled.div<ActiveCompanyProps>`
  display: grid;
  padding: 0 ${() => paddings.smallPadding};
  gap: 6px;
  background-color: ${({ theme }) => theme.colors.background};
  grid-template-areas: list;
  grid-template-columns: 1fr;
  color: ${({ theme }) => theme.colors.onBackground};
  ${DetailsArticle} {
    display: ${({ $active }) => ($active ? 'block' : 'none')};
    ${DetailsHeaderDiv} {
      display: flex;
    }
  }

  ${ListDiv} {
    display: ${({ $active }) => ($active ? 'none' : 'flex')};
  }

  @media ${() => device.md} {
    min-width: 0;
    min-height: 0;
    grid-template-areas: ${({ $active }) => ($active ? "'list details'" : "'list'")};
    grid-template-columns: ${({ $active }) => ($active ? '2fr 1fr' : '1fr')};

    ${ListDiv} {
      grid-area: list;
      display: flex;
    }
    ${DetailsArticle} {
      grid-area: details;
      display: ${({ $active }) => ($active ? 'flex' : 'none')};
      flex-direction: column;
      align-items: center;
      height: calc(100vh - ${() => headerHeight} - ${() => paddings.smallPadding});
      ${DetailsHeaderDiv} {
        display: flex;
        align-self: end;
        border: none;
      }
    }
  }
`;

export const SearchResultDiv = styled.div`
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray100 : theme.colors.gray800)};
  padding: ${() => paddings.mediumPadding};
`;

export const ListMainDiv = styled.div`
  display: flex;
`;
