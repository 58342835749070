import { useDetectOutsideClick } from 'hudskit-framework-react';
import React, { ReactElement, useCallback, useRef } from 'react';
import * as S from './styled';

interface ClickableComponentProps {
  onClick: () => void;
  isOpen?: boolean;
}

type Props = {
  menu: ReactElement<ClickableComponentProps>;
  children: React.ReactNode;
};

const DropdownDrawer = ({ children, menu }: Props) => {
  const dropdownRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useDetectOutsideClick([dropdownRef], false);

  const handleMenuClick = useCallback(() => {
    setIsMenuOpen(true);
  }, [setIsMenuOpen]);
  const handleDropdownMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, [setIsMenuOpen]);
  return (
    <>
      {React.cloneElement(menu, { onClick: handleMenuClick, isOpen: isMenuOpen })}
      <S.DrawerDiv>
        {isMenuOpen && (
        <S.DropdownDiv ref={dropdownRef} onClick={handleDropdownMenu}>
          {children}
        </S.DropdownDiv>
        )}
      </S.DrawerDiv>
    </>
  );
};
export default DropdownDrawer;
