import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { uptimeApi } from 'core/api';
import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [uptimeApi.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
