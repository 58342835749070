import { borderRadius, paddings } from 'core/styles';
import { styled } from 'styled-components';

type Props = {
  $isFetching?: boolean;
};

export const AddToDashboardDiv = styled.div<Props>`
  position: absolute;
  top: 0;
  right: 0;
  align-self: center;
  padding: ${() => paddings.smallPadding} ${() => paddings.defaultPadding};
  .toolTip {
    border-radius: ${() => borderRadius.default};
    padding: ${() => paddings.defaultPadding};
    gap: 10px;
  }

  opacity: ${({ $isFetching }) => ($isFetching ? 0 : 1)};
`;

export const LoaderDiv = styled.div`
  position: absolute;
  top: 15px;
  right: 25px;
`;
