import { paddings } from 'core/styles';
import { zDrawers } from 'core/styles/z-index';
import styled from 'styled-components';

export const FilterContainerDiv = styled.div`
  padding: ${() => paddings.smallPadding};
  z-index: ${() => zDrawers};
`;

export const FiltersForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${() => paddings.smallPadding};
  position: fixed;
  width: 170px;
`;
