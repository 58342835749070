import { headerHeight, paddings } from 'core/styles/variables';
import styled from 'styled-components';

export const ListItemsDiv = styled.div`
  flex-grow: 1;
  min-height: calc(100vh - ${() => headerHeight} - ${() => paddings.smallPadding});
`;

export const ListCenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${() => paddings.defaultPadding};
`;
