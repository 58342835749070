import { useAppSelector } from 'core/hooks/use-app-selector';
import { Card, Icon, darkTheme } from 'hudskit-framework-react';
import Logout from 'feature/auth/components/Logout';
import { useTheme } from 'styled-components';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import * as S from './styled';

const TokenInfo = () => {
  const user = useAppSelector((state) => state.auth.user);
  const theme = useTheme();
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Auth]);

  return (
    <S.Main>
      <S.ContentDiv>
        <h2>{t('AUTH:info.userSettingsTitle')}</h2>
        <Card>
          <h3>{t('AUTH:info.userInfoSubtitle')}</h3>
          <div>
            <b>{t('AUTH:info.id')}</b>
            {' '}
            {user?.id}
            <br />
            <b>{t('AUTH:info.name')}</b>
            {' '}
            {user?.givenName}
            <br />
            <b>{t('AUTH:info.fullName')}</b>
            {' '}
            {user?.name}
          </div>
          <br />
          {user?.salesCompanyCodeAndCustomerNumber && (
            <>
              <h3>{t('AUTH:info.dealerInfoSubtitle')}</h3>
              <span>{user?.salesCompanyCodeAndCustomerNumber}</span>
            </>
          )}
          <br />
          {user?.salescompanycode && (
            <>
              <h3>{t('AUTH:info.salesCompaniesInfoSubtitle')}</h3>
              <S.WrapDiv>
                {user.salescompanycode.map((code) => (
                  <span key={code}>{code}</span>
                ))}
              </S.WrapDiv>
            </>
          )}
        </Card>
        <S.AlignRightDiv>
          <S.LogoutContainerDiv>
            <Logout />
            <S.LogoutInfoDiv>
              <Icon type="notification.info_outlined" size="small" color={theme === darkTheme ? 'gray400' : 'gray800'} />
              <S.LogoutText>{t('AUTH:logout.logoutExplenatory')}</S.LogoutText>
            </S.LogoutInfoDiv>
          </S.LogoutContainerDiv>
        </S.AlignRightDiv>
      </S.ContentDiv>
    </S.Main>
  );
};
export default TokenInfo;
