import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';
import ceoraBackground from 'images/backgrounds/ceora.jpg';
import { centeredFullScreen, paddings } from 'core/styles';

export const Main = styled.main`
  ${() => centeredFullScreen};
  color: ${({ theme }) => theme.colors.onSurface};
  align-items: center;
  justify-content: center;
  background: url(${ceoraBackground}) no-repeat center center fixed;
  background-size: cover;
`;

export const TintedBackground = styled.main`
  ${() => centeredFullScreen};
  flex-direction: column;
  background-color: ${({ theme }) => (theme === darkTheme ? 'rgba(0,0,0,0.4)' : 'rgba(255,255,255, 0.5)')};
  background-blend-mode: multiply;
`;

export const Heading = styled.h1`
  font-style: italic;
  font-size: 2rem;
`;
export const SubHeading = styled.h2`
  font-style: italic;
  font-size: 3rem;
  text-align: center;
  line-height: 3.5rem;
`;
export const LogoContainer = styled.main`
  align-items: start;
  top: ${() => paddings.hugePadding};
  left: ${() => paddings.hugePadding};
  position: fixed;
`;
