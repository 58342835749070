import { useGetAssetByIdQuery } from 'feature/assets/api';
import { Button, Loader } from 'hudskit-framework-react';
import ErrorCard from 'core/components/ErrorCard';
import PillTag from 'core/components/PillTag';
import { MowerStatus } from '@uptime-bff/api-contract';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { useCallback } from 'react';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { setAssetMapActiveAssetId } from 'feature/assets/store';
import { displayGoToFleetButton } from 'core/config';
import AssetThumbnail from '../AssetThumbnail';
import * as S from './styled';

type Props = {
  id:string
};
const AssetMapInfoWindow = ({ id }:Props) => {
  const { isError, isFetching, isSuccess, data: asset } = useGetAssetByIdQuery(id);
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const status = asset?.mowerStatus && asset.mowerStatus !== MowerStatus.Unknown ? t(`ASSETS:mowerStatus.${asset.mowerStatus}`) : '';
  const dispatch = useAppDispatch();
  const onCloseInfoWindowHandler = useCallback(() => {
    dispatch(setAssetMapActiveAssetId(undefined));
  }, [dispatch]);

  const openInFleetClickHandler = useCallback(() => {
    if (asset) {
      window.open(asset.link);
    }
  }, [asset]);
  return (
    <S.ActiveAssetInfoDiv>
      {isFetching && <Loader size="small" />}
      {!isFetching && isSuccess && asset && (
      <>
        <S.TitleSection>
          <S.TitleDiv>
            {asset?.thumbnail && <AssetThumbnail imageSrc={asset?.thumbnail} imageAlt={asset.model} />}
            {asset.mowerStatusSeverity && <PillTag color={asset.mowerStatusSeverity}>{status}</PillTag>}
          </S.TitleDiv>
          <Button icon={{ position: 'right', type: 'action.close' }} variant="transparent" onClick={onCloseInfoWindowHandler} />
        </S.TitleSection>
        <S.ContentDiv>
          <S.SubContentDiv>
            <b>
              {asset?.companyName}
            </b>
            <span>
              { asset.assetName }
            </span>
            <span>
              { asset.model }
            </span>
          </S.SubContentDiv>
          { displayGoToFleetButton && (
            <S.SubContentButtonDiv>
              <Button
                icon={{ position: 'left', type: 'action.external_link' }}
                variant="secondary"
                onClick={openInFleetClickHandler}
              >
                Open in fleet
              </Button>
            </S.SubContentButtonDiv>
          )}
        </S.ContentDiv>
      </>
      )}
      {isError && <ErrorCard />}

    </S.ActiveAssetInfoDiv>
  );
};

export default AssetMapInfoWindow;
