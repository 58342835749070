import { accessModalEnabled } from 'core/config';
import { useCompaniesLength } from 'feature/companies/hook/use-companies-length';
import NoAccessModal from 'feature/dashboard/components/NoAccessModal/NoAccessModal';

const AccessModal = () => {
  const companiesAvailableLength = useCompaniesLength();

  return accessModalEnabled && companiesAvailableLength === 0 ? <NoAccessModal /> : null;
};

export default AccessModal;
