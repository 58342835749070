import { device, typographyMobile } from 'core/styles/variables';
import styled from 'styled-components';

export const ButtonWrapperDiv = styled.div`
  button {
    transform: rotate(90deg);
  }
`;
export const LabelSpan = styled.span`
  display: none;
  font-size: ${typographyMobile.size.small};

  @media ${() => device.lg}{
    display: flex;
  }
`;

export const SortingDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;
