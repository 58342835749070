import { ThemeWrapper } from 'core/styles';
import { zFloatingPanels } from 'core/styles/z-index';
import { ReactNode, useEffect, useRef } from 'react';
import { Root, createRoot } from 'react-dom/client';

type Props = {
  map: google.maps.Map,
  children:ReactNode,
  position: google.maps.LatLngLiteral
  onClick?: ()=>void
};
const MapsMarker = ({ map, children, position, onClick }:Props) => {
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement>();
  const rootRef = useRef<Root>();

  useEffect(() => {
    if (!rootRef.current) {
      const container = document.createElement('div');
      rootRef.current = createRoot(container);
      markerRef.current = new google.maps.marker.AdvancedMarkerElement({
        content: container,
        position,
        zIndex: zFloatingPanels,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    rootRef.current?.render(<ThemeWrapper>{children}</ThemeWrapper>);
    markerRef.current!.position = position;
    markerRef.current!.map = map;
    let listener: google.maps.MapsEventListener | undefined;
    if (onClick) {
      listener = markerRef.current?.addListener('click', onClick);
    }
    return () => listener?.remove();
  }, [map, position, children, onClick]);

};
export default MapsMarker;
