import React from 'react';
import { Tag } from 'hudskit-framework-react';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { clearCompanyNameFilters } from 'feature/assets/store';

export interface Props {
  valueText: string;
  dismissClickHandler(): void;
}

const ValuePill = () => {
  const dispatch = useAppDispatch();
  const selectedCompanyName = useAppSelector((state) => state.assets.activeListFilters?.companies);
  return selectedCompanyName?.map((value) => (
    <div key={value}>
      <Tag outlined active icon="action.cancel" onIconClick={() => dispatch(clearCompanyNameFilters())}>
        {value}
      </Tag>
    </div>
  ));
};

export default ValuePill;
