import { Card, Icon } from 'hudskit-framework-react';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import PillTag from 'core/components/PillTag';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { RoutePaths } from 'core/models';
import {
  clearFilters,
  setFilterByCompaniesOnActiveSearch,
  setFilterByStatusOnActiveSearch,
} from 'feature/assets/store';
import { useNavigate } from 'react-router-dom';
import { Card as WidgetsCard } from '@uptime-bff/api-contract';
import { useDeleteCustomerWidgetMutation } from 'feature/dashboard/api';
import ConfirmationModal from '../ConfirmationModal';
import * as S from './styled';

export interface Props {
  doneEditing: boolean;
  showErrorBanner: (isAPIError: boolean) => void;
  cardData: WidgetsCard;
  isWidgetsListUpdating: boolean;
}

const CustomerWidgetCard = ({ doneEditing, showErrorBanner, cardData, isWidgetsListUpdating }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [deleteCustomerWidget, { isError, isLoading, isSuccess, reset }] = useDeleteCustomerWidgetMutation();
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Dashboard]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteWidgetMap, setDeleteWidgetMap] = useState<{ [key: string]: boolean }>({});

  const goToAssets = useCallback(() => {
    if (isWidgetsListUpdating) return;
    dispatch(clearFilters());
    dispatch(
      setFilterByCompaniesOnActiveSearch({ diamGroupId: cardData.companyId, companyName: cardData.companyName })
    );
    dispatch(setFilterByStatusOnActiveSearch(['error']));
    navigate(RoutePaths.Assets);
  }, [cardData.companyId, cardData.companyName, dispatch, isWidgetsListUpdating, navigate]);

  const goToAssetsWithoutErrors = useCallback(() => {
    if (isWidgetsListUpdating) return;
    dispatch(clearFilters());
    dispatch(
      setFilterByCompaniesOnActiveSearch({ diamGroupId: cardData.companyId, companyName: cardData.companyName })
    );
    navigate(RoutePaths.Assets);
  }, [cardData.companyId, cardData.companyName, dispatch, isWidgetsListUpdating, navigate]);

  const deleteCustomerWidgetFn = useCallback(() => {
    if (isWidgetsListUpdating) return;
    showErrorBanner(false);
    setDeleteWidgetMap({ [cardData.companyId]: true });
    deleteCustomerWidget({ companyId: cardData.companyId });
  }, [cardData.companyId, deleteCustomerWidget, isWidgetsListUpdating, showErrorBanner]);

  useEffect(() => {
    if (isError && deleteWidgetMap[cardData.companyId]) {
      setShowDeleteConfirmation(false);
      showErrorBanner(true);
      reset();
    }
  }, [showErrorBanner, isError, reset, cardData.companyId, deleteWidgetMap, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      setDeleteWidgetMap((currDeletedWidgetsMap) => ({ ...currDeletedWidgetsMap, [cardData.companyId]: false }));
      setShowDeleteConfirmation(false);
    }
  }, [cardData.companyId, dispatch, isSuccess]);

  const handleDelClickFn = useCallback(() => {
    if (!isWidgetsListUpdating) {
      setShowDeleteConfirmation(!showDeleteConfirmation);
    }
  }, [isWidgetsListUpdating, showDeleteConfirmation]);

  return (
    <Card disabled={isWidgetsListUpdating}>
      <S.WidgetContentDiv>
        <S.HeaderDiv>
          <S.HeadingDiv $isFetching={isWidgetsListUpdating} data-testid="companyName" onClick={goToAssetsWithoutErrors}>
            <S.HeadingText $isFetching={isWidgetsListUpdating}>{cardData.companyName}</S.HeadingText>
          </S.HeadingDiv>
          {doneEditing && !isLoading && (
            <S.DelIconWrapper $isFetching={isWidgetsListUpdating} onClick={handleDelClickFn}>
              <Icon type="action.delete_outlined" />
            </S.DelIconWrapper>
          )}
        </S.HeaderDiv>
        <>
          {cardData.isCompanyActive && (
            <S.MachinesCountHeaderDiv>
              <S.HeadingDiv $isFetching={isWidgetsListUpdating} onClick={goToAssetsWithoutErrors}>
                <Icon type="product.automower_top" />
                <S.HeadingText $isFetching={isWidgetsListUpdating}>{cardData.assets.totalAssets}</S.HeadingText>
              </S.HeadingDiv>
            </S.MachinesCountHeaderDiv>
          )}

          {cardData.isCompanyActive && cardData.assets.error !== 0 && (
            <>
              <S.ErrorNumWrapperDiv data-testid="errorsCount" onClick={goToAssets}>
                <S.AssetErrorCountHeader $isFetching={isWidgetsListUpdating}>
                  {cardData.assets.error}
                </S.AssetErrorCountHeader>
              </S.ErrorNumWrapperDiv>
              <S.ErrorStatusWrapperDiv onClick={goToAssets}>
                <S.PillWrapperDiv $isFetching={isWidgetsListUpdating}>
                  <PillTag isLoading={isWidgetsListUpdating} color="error">
                    {t('DASHBOARD:dashboard.customerWidgets.errorPillText')}
                  </PillTag>
                </S.PillWrapperDiv>
              </S.ErrorStatusWrapperDiv>
            </>
          )}
          {!cardData.isCompanyActive && (
            <S.CompanyDeletedSpan>
              {t('DASHBOARD:dashboard.customerWidgets.errors.companyDeleted')}
            </S.CompanyDeletedSpan>
          )}
          {cardData.isCompanyActive && cardData.assets.error === 0 && (
            <S.NoErrorsTextDiv>{t('DASHBOARD:dashboard.customerWidgets.errors.noErrorsText')}</S.NoErrorsTextDiv>
          )}
        </>
      </S.WidgetContentDiv>
      <ConfirmationModal
        isOpen={showDeleteConfirmation}
        close={() => setShowDeleteConfirmation(false)}
        deleteCompany={deleteCustomerWidgetFn}
        isDeleteInProgress={isLoading}
      />
    </Card>
  );
};

export default CustomerWidgetCard;
