import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useEffect, useState } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useCryptographicallyRandom } from './use-cryptographically-random';
import { digestMessage } from '../utils/crypto';

const useAuthHash = (
  dispatchAction:
  | ActionCreatorWithPayload<string, 'auth/setOidcNonce'>
  | ActionCreatorWithPayload<string, 'auth/setOidcState'>,
  stringToHash?: string,
) => {
  const { randomString } = useCryptographicallyRandom();
  const [cryptographicHash, setCryptographicHash] = useState<string>();
  const dispatch = useAppDispatch();
  if (!stringToHash) {
    dispatch(dispatchAction(randomString));
  }
  useEffect(() => {
    const getCryptographicHash = async () => {
      if (stringToHash) {
        const hashArray = await digestMessage(stringToHash);
        const hashHex = hashArray
          .map((b: number) => b.toString(16).padStart(2, '0'))
          .join(''); // convert bytes to hex string
        setCryptographicHash(hashHex);
      }
    };
    getCryptographicHash();
  }, [stringToHash]);

  return { cryptographicHash };
};

export { useAuthHash };
