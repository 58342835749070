import {
  AssetDetailsResponse,
  AssetFiltersRequest,
  AssetFiltersResponse,
  AssetListRequest,
  AssetListResponse,
  AssetMapResponse,
} from '@uptime-bff/api-contract';
import { uptimeApi } from 'core/api';

const assetsApi = uptimeApi.injectEndpoints({
  endpoints: (build) => ({
    searchAssets: build.query<AssetListResponse, AssetListRequest>({
      query: (body) => ({
        url: 'assets/list',
        method: 'POST',
        body,
      }),
    }),
    getSearchAvailableFilters: build.query<AssetFiltersResponse, AssetFiltersRequest>({
      query: (body) => ({
        url: 'assets/list/filters',
        method: 'POST',
        body,
      }),
    }),
    getAssetById: build.query<AssetDetailsResponse, string>({
      query: (assetId) => ({
        url: `assets/list/${assetId}`,
        method: 'GET',
      }),
    }),
    getAssetMarkers: build.query<AssetMapResponse, void>({
      query: () => ({
        url: 'assets/map',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazySearchAssetsQuery,
  useGetAssetByIdQuery,
  useGetSearchAvailableFiltersQuery,
  useGetAssetMarkersQuery,
} = assetsApi;
