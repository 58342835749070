import Logo from 'core/components/Logo/Logo';
import { ReactNode } from 'react';
import * as S from './styled';

type Props = {
  children:ReactNode
};
const FullScreenPage = ({ children }:Props) => (
  <S.Main>
    <S.TintedBackground>
      <S.LogoContainer>
        <Logo size={200} />
      </S.LogoContainer>
      {children}
    </S.TintedBackground>
  </S.Main>
);
export default FullScreenPage;
