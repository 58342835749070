import styled from 'styled-components';
import ceoraImg from 'images/backgrounds/ceora.jpg';
import { breakpoints } from 'hudskit-framework-react';
import { colors, paddings } from 'core/styles/variables';

export const Main = styled.main`
  background-color: ${({ theme }) => theme.colors.background};
  display: grid;
  height: 100vh;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  @media (max-width: ${breakpoints.s}px) {
    grid-template-columns: 1fr;
  }
`;

export const Aside = styled.aside`
  grid-column: 1 / 5;
  display: grid;
  grid-template-rows: 100px 1fr 100px;
  grid-template-columns: 100%;
  color: ${({ theme }) => theme.colors.onSurface};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${() => paddings.defaultPadding};
  @media (min-width: ${breakpoints.l}px) {
    padding: ${() => paddings.defaultPadding} ${() => paddings.hugePadding};
  }
  @media (min-width: ${breakpoints.xxl}px) {
    padding: ${() => paddings.hugePadding} 100px;
  }
`;

export const Header = styled.header`
  display: flex;
`;

export const HeadingContainer = styled.div`
  padding: ${() => paddings.hugePadding} 0;
`;

export const MiddleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${() => paddings.defaultPadding};
`;

export const SignInButtonContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.footer`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: flex-end;
`;

export const LoginHeading = styled.h1`
  font-size: 1.75rem;
  font-weight: normal;
`;

export const BackgroundSection = styled.section`
  @media (max-width: ${breakpoints.s}px) {
    display: none;
  }
  grid-column: 5 / 13;
  display: grid;
  background-image: url(${ceoraImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  grid-template-columns: repeat(12, 1fr);
`;

export const BlueSquare = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 125px;
  grid-column: 10 / span 2;
  background-color: ${() => colors.husqvarnaBlue};
  @media (min-width: ${breakpoints.xl}px) {
    grid-column: 11 / span 2;
  }
`;
