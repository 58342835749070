import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';

export const TimeStampParagraph = styled.p`
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray400 : theme.colors.gray600)};
  cursor: help;
  margin: 0;

`;

export const TimeStampDiv = styled.div`
  align-self: end;
`;
