/* eslint-disable max-len */
import { Button } from 'hudskit-framework-react';
import { useCallback, useEffect, useState } from 'react';
import { CompanyTableRow as Company } from '@uptime-bff/api-contract';
import CompanyList from 'feature/companies/components/CompanyList';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import CompaniesSearch from 'feature/companies/components/CompaniesSearch';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { setSelectedCompany } from 'feature/companies/store';
import { Outlet, useNavigate } from 'react-router-dom';
import { navigationWithChildren } from 'core/config';
import CompaniesDetails from 'feature/companies/components/CompaniesDetails';
import * as S from './styled';

const Companies = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Core);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectedCompany = useAppSelector((state) => state.companies.selectedCompany);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Old, remove when feature flag is remvoed: navigationWithChildren
  const onCompanyItemClickHandlerWithoutNavigation = useCallback(
    (company: Company) => {
      dispatch(setSelectedCompany(company));
    },
    [dispatch]
  );
  const onDetailsCloseHandlerWithoutNavigation = useCallback(() => {
    dispatch(setSelectedCompany(undefined));
  }, [dispatch]);

  // New, keep when feature flag is remvoed: navigationWithChildren
  const onCompanyItemClickHandlerWithNavigation = useCallback(
    (company: Company) => {
      dispatch(setSelectedCompany(company));
      navigate(`/companies/${company.iamId}`);
    },
    [dispatch, navigate]
  );

  const onDetailsCloseHandlerWithNavigation = useCallback(() => {
    dispatch(setSelectedCompany(undefined));
    navigate('/companies');
  }, [dispatch, navigate]);

  // Remove when feature flag is remvoed: navigationWithChildren
  const onCompanyItemClickHandler = useCallback(
    (company: Company) => {
      if (navigationWithChildren) {
        onCompanyItemClickHandlerWithNavigation(company);
        return;
      }
      onCompanyItemClickHandlerWithoutNavigation(company);
    },
    [onCompanyItemClickHandlerWithNavigation, onCompanyItemClickHandlerWithoutNavigation]
  );

  const onDetailsCloseHandler = useCallback(() => {
    if (navigationWithChildren) {
      onDetailsCloseHandlerWithNavigation();
      return;
    }
    onDetailsCloseHandlerWithoutNavigation();
  }, [onDetailsCloseHandlerWithNavigation, onDetailsCloseHandlerWithoutNavigation]);

  // Keep when feature flag is remvoed: navigationWithChildren
  useEffect(() => {
    if (navigationWithChildren) {
      dispatch(setSelectedCompany(undefined));
      navigate('/companies');
    }
  }, [dispatch, navigate]);

  return (
    <S.GridDiv $active={!!selectedCompany}>
      <S.ListDiv>
        <S.ListHeaderDiv>
          <CompaniesSearch isOpen={isOpen} setIsOpen={setIsOpen} />
        </S.ListHeaderDiv>
        <CompanyList
          isOpen={isOpen}
          onCompanyItemClickHandler={onCompanyItemClickHandler}
          selectedCompanyId={selectedCompany?.id}
        />
      </S.ListDiv>
      <S.DetailsArticle id="sectionCompanyDetails">
        <S.DetailsHeaderDiv>
          <Button
            data-test="closeBtn"
            type="button"
            size="small"
            variant="transparent"
            icon={{ type: 'action.close', position: 'left' }}
            onClick={onDetailsCloseHandler}
          >
            {t('CORE:buttons.close')}
          </Button>
        </S.DetailsHeaderDiv>
        {selectedCompany && navigationWithChildren ? (
          <Outlet />
        ) : (
          <CompaniesDetails selectedCompany={selectedCompany} />
        )}
      </S.DetailsArticle>
    </S.GridDiv>
  );
};

export default Companies;
