import { paddings } from 'core/styles';
import styled from 'styled-components';

export type MenuProps = {
  $selected: boolean;
};
export const Button = styled.button`
  all: unset;
  padding: ${() => paddings.defaultPadding};
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

export const ShieldAside = styled.aside`
  width: 5px;
  display: flex;
  height: 100%;
  flex-direction: column;
  place-content: flex-start center;
  align-items: flex-start;
`;

export const ShieldCenterDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.brand100};
  width: 100%;
`;

export const ShieldEdgeSvg = styled.svg`
  height: 6px;
  width: 5px;
  & path {
    stroke: red;
    stroke-width: 0;
  }
`;

export const MenuLi = styled.li<MenuProps>`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: start;
  height: 4rem;
  ${ShieldCenterDiv} {
    height: ${({ $selected }) => ($selected ? '100%' : '0')};
  }
  ${ShieldEdgeSvg} {
    & path {
      fill: ${({ theme, $selected }) => ($selected ? theme.colors.brand100 : 'transparent')};
    }
  }
  &:hover ${ShieldCenterDiv}, &:hover ${ShieldEdgeSvg} {
    & path {
      fill: ${({ theme }) => theme.colors.brand100};
    }
  }
  &:hover {
    color: ${({ theme }) => theme.colors.onSurface};
  }
`;
