import { borderRadius, paddings } from 'core/styles';
import { zFloatingPanels } from 'core/styles/z-index';
import styled from 'styled-components';

export const ActiveAssetInfoDiv = styled.div`
  position: fixed;
  z-index: ${() => zFloatingPanels};
  right: calc(50px + ${() => paddings.defaultPadding});
  bottom: ${() => paddings.defaultPadding};
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: ${() => borderRadius.default};
  padding: ${() => paddings.defaultPadding};
  font-weight: bold;
`;
