/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TokenResponse } from '@uptime-bff/api-contract';
import storage from 'redux-persist/lib/storage';

export const defaultState: AuthState = {
  isAuthenticated: false,
  isLoggingOut: false,
};

interface AuthState {
  isAuthenticated: boolean;
  isLoggingOut: boolean;
  oidcNonce?: string;
  oidcState?: string;
  user?: TokenResponse;
}

const slice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    login: (state, action: PayloadAction<TokenResponse>) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logout: (state) => {
      storage.removeItem('persist:root');
      state.isLoggingOut = true;
      state.isAuthenticated = false;
      state.user = undefined;
      state.oidcNonce = undefined;
      state.oidcState = undefined;
    },
    setLoggedOut: (state) => {
      state.isLoggingOut = false;
    },
    setOidcNonce: (state, action: PayloadAction<string>) => {
      state.oidcNonce = action.payload;
    },
    setOidcState: (state, action: PayloadAction<string>) => {
      state.oidcState = action.payload;
    },
  },
});

export const { login, setOidcNonce, setOidcState, logout, setLoggedOut } = slice.actions;
export default slice.reducer;
