import { device, paddings } from 'core/styles/variables';
import styled from 'styled-components';

export { ErrorSpan } from 'feature/auth/pages/Callback/styled';

type Props = {
  $isFetching: boolean
};

export const WidgetContentDiv = styled.div`
  height: 170px;
`;

export const LoaderDiv = styled(WidgetContentDiv)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PieChartWithLegendDiv = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 100%;
  margin-top: 10px;

  @media (${device.xs}) {
    flex-direction: column;
  }
`;

export const PieChartWithLegendColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  gap: 8px;

  .hoverable-label {
    fill: ${({ theme }) => theme.colors.onSurface};
    cursor: pointer;
    dominant-baseline: middle;
    text-anchor: middle;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (${device.xs}) {
    width: 100%;
  }
`;

export const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${paddings.mediumPadding} 0 0 0;
`;

export const MachinesCountHeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${paddings.mediumPadding} 0 0 0;
  margin-left: 4px;
`;

export const HeadingDiv = styled.div<Props>`
  display: flex;
  align-items: center;
  cursor: ${({ $isFetching }) => (!$isFetching && 'pointer')};
`;

export const HeadingText = styled.h6<Props>`
  margin: 0 0 0 8px;
  max-width: 34vw;
  cursor: ${({ $isFetching }) => (!$isFetching && 'pointer')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PillWrapperDiv = styled.div<Props>`
  width: 100px;
  cursor: ${({ $isFetching }) => (!$isFetching && 'pointer')};
`;

export const ErrorStatusWrapperDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const ErrorNumWrapperDiv = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
`;
export const AssetErrorCountHeader = styled.h1<Props>`
  cursor: ${({ $isFetching }) => (!$isFetching && 'pointer')};
`;

export const DelIconWrapper = styled.div<Props>`
  cursor: ${({ $isFetching }) => (!$isFetching && 'pointer')};
`;

export const NoErrorsTextDiv = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  line-height: 24px;
  text-align: left;
  color: ${({ theme }) => theme.colors.onSurface};
`;

export const LoaderWrapperDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0;
`;

export const CompanyDeletedSpan = styled.span`
  display: flex;
  justify-content: center;
  position: relative;
  top: 33%;
  left: 10px;
`;
